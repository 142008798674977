import { store } from './configureStore';
import apiRequest from './apiRequest';

export const action = (type, payload = {}, callback) => {
  store.dispatch({ type, payload });
  if (typeof callback === 'function') {
    callback();
  }
};

export const get = (url, config = {}) => apiRequest('GET', url, config);
export const post = (url, config = {}) => apiRequest('POST', url, config);
export const put = (url, config = {}) => apiRequest('PUT', url, config);
export const del = (url, config = {}) => apiRequest('DELETE', url, config);

export default { get, post, put, del, action };
