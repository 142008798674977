import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import AppRouter from 'app/AppRouter';
import TooltipHandler from './shared/components/TooltipHandler';
import './styles/main.scss';
import { ConfigProvider } from 'antd';
import LogRocket from 'logrocket';
import { useImportPromotekit } from './shared/lib/loadScripts';

function App() {
  useImportPromotekit();

  LogRocket.init('32xjnu/site-qwality', {
    network: {
      requestSanitizer: (request) => {
        // scrub header value from request
        if (request.headers['authorization']) {
          request.headers['authorization'] = '';
        }
      }
    }
  });
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#000C22',
          colorBgContainer: '#000C22',
          colorBgBase: '#000C22',
          colorTextBase: '#ffffff',
          borderRadius: 2
        }
      }}
    >
      <div className="app">
        <BrowserRouter>
          <AppRouter />
          <TooltipHandler />
        </BrowserRouter>
      </div>
    </ConfigProvider>

  );
}

export default App;
