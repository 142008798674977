import { persistReducer } from 'redux-persist';
import { createReducer } from './createReducer';
import buildPersistConfig from './buildPersistConfig';

const createPersistedReducer = (initialState, reducerMap, persistConfig) => {
  const reducer = createReducer(initialState, reducerMap);
  return persistReducer(buildPersistConfig(persistConfig.key, persistConfig), reducer);
};

export default createPersistedReducer;
