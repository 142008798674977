export const PHONECODES_EN = {
  1: {
    label: 'United States',
    value: '1',
    flag: '🇺🇸',
  },
  7: {
    label: 'Russia',
    value: '7',
    flag: '🇷🇺',
  },
  20: {
    label: 'Egypt',
    value: '20',
    flag: '🇪🇬',
  },
  27: {
    label: 'South Africa',
    value: '27',
    flag: '🇿🇦',
  },
  30: {
    label: 'Greece',
    value: '30',
    flag: '🇬🇷',
  },
  31: {
    label: 'Netherlands',
    value: '31',
    flag: '🇳🇱',
  },
  32: {
    label: 'Belgium',
    value: '32',
    flag: '🇧🇪',
  },
  33: {
    label: 'France',
    value: '33',
    flag: '🇫🇷',
  },
  34: {
    label: 'Spain',
    value: '34',
    flag: '🇪🇸',
  },
  36: {
    label: 'Hungary',
    value: '36',
    flag: '🇭🇺',
  },
  39: {
    label: 'Italy',
    value: '39',
    flag: '🇮🇹',
  },
  40: {
    label: 'Romania',
    value: '40',
    flag: '🇷🇴',
  },
  41: {
    label: 'Switzerland',
    value: '41',
    flag: '🇨🇭',
  },
  43: {
    label: 'Austria',
    value: '43',
    flag: '🇦🇹',
  },
  44: {
    label: 'United Kingdom',
    value: '44',
    flag: '🇬🇧',
  },
  45: {
    label: 'Denmark',
    value: '45',
    flag: '🇩🇰',
  },
  46: {
    label: 'Sweden',
    value: '46',
    flag: '🇸🇪',
  },
  47: {
    label: 'Svalbard and Jan Mayen',
    value: '47',
    flag: '🇸🇯',
  },
  48: {
    label: 'Poland',
    value: '48',
    flag: '🇵🇱',
  },
  49: {
    label: 'Germany',
    value: '49',
    flag: '🇩🇪',
  },
  51: {
    label: 'Peru',
    value: '51',
    flag: '🇵🇪',
  },
  52: {
    label: 'Mexico',
    value: '52',
    flag: '🇲🇽',
  },
  53: {
    label: 'Cuba',
    value: '53',
    flag: '🇨🇺',
  },
  54: {
    label: 'Argentina',
    value: '54',
    flag: '🇦🇷'
  },
  55: {
    label: 'Brazil',
    value: '55',
    flag: '🇧🇷',
  },
  56: {
    label: 'Chile',
    value: '56',
    flag: '🇨🇱',
  },
  57: {
    label: 'Colombia',
    value: '57',
    flag: '🇨🇴',
  },
  58: {
    label: 'Venezuela, Bolivarian Republic of Venezuela',
    value: '58',
    flag: '🇻🇪',
  },
  60: {
    label: 'Malaysia',
    value: '60',
    flag: '🇲🇾',
  },
  61: {
    label: 'Cocos (Keeling) Islands',
    value: '61',
    flag: '🇨🇨',
  },
  62: {
    label: 'Indonesia',
    value: '62',
    flag: '🇮🇩',
  },
  63: {
    label: 'Philippines',
    value: '63',
    flag: '🇵🇭',
  },
  64: {
    label: 'New Zealand',
    value: '64',
    flag: '🇳🇿',
  },
  65: {
    label: 'Singapore',
    value: '65',
    flag: '🇸🇬',
  },
  66: {
    label: 'Thailand',
    value: '66',
    flag: '🇹🇭',
  },
  77: {
    label: 'Kazakhstan',
    value: '77',
    flag: '🇰🇿'
  },
  81: {
    label: 'Japan',
    value: '81',
    flag: '🇯🇵',
  },
  82: {
    label: 'Korea, Republic of South Korea',
    value: '82',
    flag: '🇰🇷',
  },
  84: {
    label: 'Vietnam',
    value: '84',
    flag: '🇻🇳',
  },
  86: {
    label: 'China',
    value: '86',
    flag: '🇨🇳',
  },
  90: {
    label: 'Turkey',
    value: '90',
    flag: '🇹🇷',
  },
  91: {
    label: 'India',
    value: '91',
    flag: '🇮🇳',
  },
  92: {
    label: 'Pakistan',
    value: '92',
    flag: '🇵🇰',
  },
  93: {
    label: 'Afghanistan',
    value: '93',
    flag: '🇦🇫',
  },
  94: {
    label: 'Sri Lanka',
    value: '94',
    flag: '🇱🇰',
  },
  95: {
    label: 'Myanmar',
    value: '95',
    flag: '🇲🇲',
  },
  98: {
    label: 'Iran, Islamic Republic of Persian Gulf',
    value: '98',
    flag: '🇮🇷',
  },
  211: {
    label: 'South Sudan',
    value: '211',
    flag: '🇸🇸',
  },
  212: {
    label: 'Morocco',
    value: '212',
    flag: '🇲🇦',
  },
  213: {
    label: 'Algeria',
    value: '213',
    flag: '🇩🇿',
  },
  216: {
    label: 'Tunisia',
    value: '216',
    flag: '🇹🇳',
  },
  218: {
    label: 'Libyan Arab Jamahiriya',
    value: '218',
    flag: '🇱🇾',
  },
  220: {
    label: 'Gambia',
    value: '220',
    flag: '🇬🇲',
  },
  221: {
    label: 'Senegal',
    value: '221',
    flag: '🇸🇳',
  },
  222: {
    label: 'Mauritania',
    value: '222',
    flag: '🇲🇷',
  },
  223: {
    label: 'Mali',
    value: '223',
    flag: '🇲🇱',
  },
  224: {
    label: 'Guinea',
    value: '224',
    flag: '🇬🇳',
  },
  225: {
    label: "Cote d'Ivoire",
    value: '225',
    flag: '🇨🇮',
  },
  226: {
    label: 'Burkina Faso',
    value: '226',
    flag: '🇧🇫',
  },
  227: {
    label: 'Niger',
    value: '227',
    flag: '🇳🇪',
  },
  228: {
    label: 'Togo',
    value: '228',
    flag: '🇹🇬',
  },
  229: {
    label: 'Benin',
    value: '229',
    flag: '🇧🇯',
  },
  230: {
    label: 'Mauritius',
    value: '230',
    flag: '🇲🇺',
  },
  231: {
    label: 'Liberia',
    value: '231',
    flag: '🇱🇷',
  },
  232: {
    label: 'Sierra Leone',
    value: '232',
    flag: '🇸🇱',
  },
  233: {
    label: 'Ghana',
    value: '233',
    flag: '🇬🇭',
  },
  234: {
    label: 'Nigeria',
    value: '234',
    flag: '🇳🇬',
  },
  235: {
    label: 'Chad',
    value: '235',
    flag: '🇹🇩',
  },
  236: {
    label: 'Central African Republic',
    value: '236',
    flag: '🇨🇫',
  },
  237: {
    label: 'Cameroon',
    value: '237',
    flag: '🇨🇲',
  },
  238: {
    label: 'Cape Verde',
    value: '238',
    flag: '🇨🇻',
  },
  239: {
    label: 'Sao Tome and Principe',
    value: '239',
    flag: '🇸🇹',
  },
  240: {
    label: 'Equatorial Guinea',
    value: '240',
    flag: '🇬🇶',
  },
  241: {
    label: 'Gabon',
    value: '241',
    flag: '🇬🇦',
  },
  242: {
    label: 'Congo',
    value: '242',
    flag: '🇨🇬',
  },
  243: {
    label: 'Congo, The Democratic Republic of the Congo',
    value: '243',
    flag: '🇨🇩',
  },
  244: {
    label: 'Angola',
    value: '244',
    flag: '🇦🇴',
  },
  245: {
    label: 'Guinea-Bissau',
    value: '245',
    flag: '🇬🇼',
  },
  246: {
    label: 'British Indian Ocean Territory',
    value: '246',
    flag: '🇮🇴'
  },
  248: {
    label: 'Seychelles',
    value: '248',
    flag: '🇸🇨',
  },
  249: {
    label: 'Sudan',
    value: '249',
    flag: '🇸🇩',
  },
  250: {
    label: 'Rwanda',
    value: '250',
    flag: '🇷🇼',
  },
  251: {
    label: 'Ethiopia',
    value: '251',
    flag: '🇪🇹',
  },
  252: {
    label: 'Somalia',
    value: '252',
    flag: '🇸🇴',
  },
  253: {
    label: 'Djibouti',
    value: '253',
    flag: '🇩🇯',
  },
  254: {
    label: 'Kenya',
    value: '254',
    flag: '🇰🇪',
  },
  255: {
    label: 'Tanzania, United Republic of Tanzania',
    value: '255',
    flag: '🇹🇿',
  },
  256: {
    label: 'Uganda',
    value: '256',
    flag: '🇺🇬',
  },
  257: {
    label: 'Burundi',
    value: '257',
    flag: '🇧🇮',
  },
  258: {
    label: 'Mozambique',
    value: '258',
    flag: '🇲🇿',
  },
  260: {
    label: 'Zambia',
    value: '260',
    flag: '🇿🇲',
  },
  261: {
    label: 'Madagascar',
    value: '261',
    flag: '🇲🇬',
  },
  262: {
    label: 'Reunion',
    value: '262',
    flag: '🇷🇪',
  },
  263: {
    label: 'Zimbabwe',
    value: '263',
    flag: '🇿🇼',
  },
  264: {
    label: 'Namibia',
    value: '264',
    flag: '🇳🇦',
  },
  265: {
    label: 'Malawi',
    value: '265',
    flag: '🇲🇼',
  },
  266: {
    label: 'Lesotho',
    value: '266',
    flag: '🇱🇸',
  },
  267: {
    label: 'Botswana',
    value: '267',
    flag: '🇧🇼',
  },
  268: {
    label: 'Swaziland',
    value: '268',
    flag: '🇸🇿',
  },
  269: {
    label: 'Comoros',
    value: '269',
    flag: '🇰🇲',
  },
  290: {
    label: 'Saint Helena, Ascension and Tristan Da Cunha',
    value: '290',
    flag: '🇸🇭',
  },
  291: {
    label: 'Eritrea',
    value: '291',
    flag: '🇪🇷',
  },
  297: {
    label: 'Aruba',
    value: '297',
    flag: '🇦🇼',
  },
  298: {
    label: 'Faroe Islands',
    value: '298',
    flag: '🇫🇴',
  },
  299: {
    label: 'Greenland',
    value: '299',
    flag: '🇬🇱',
  },
  345: {
    label: 'Cayman Islands',
    value: '345',
    flag: '🇰🇾'
  },
  350: {
    label: 'Gibraltar',
    value: '350',
    flag: '🇬🇮',
  },
  351: {
    label: 'Portugal',
    value: '351',
    flag: '🇵🇹',
  },
  352: {
    label: 'Luxembourg',
    value: '352',
    flag: '🇱🇺',
  },
  353: {
    label: 'Ireland',
    value: '353',
    flag: '🇮🇪',
  },
  354: {
    label: 'Iceland',
    value: '354',
    flag: '🇮🇸',
  },
  355: {
    label: 'Albania',
    value: '355',
    flag: '🇦🇱',
  },
  356: {
    label: 'Malta',
    value: '356',
    flag: '🇲🇹',
  },
  357: {
    label: 'Cyprus',
    value: '357',
    flag: '🇨🇾',
  },
  358: {
    label: 'Finland',
    value: '358',
    flag: '🇫🇮',
  },
  359: {
    label: 'Bulgaria',
    value: '359',
    flag: '🇧🇬',
  },
  370: {
    label: 'Lithuania',
    value: '370',
    flag: '🇱🇹',
  },
  371: {
    label: 'Latvia',
    value: '371',
    flag: '🇱🇻',
  },
  372: {
    label: 'Estonia',
    value: '372',
    flag: '🇪🇪',
  },
  373: {
    label: 'Moldova',
    value: '373',
    flag: '🇲🇩',
  },
  374: {
    label: 'Armenia',
    value: '374',
    flag: '🇦🇲'
  },
  375: {
    label: 'Belarus',
    value: '375',
    flag: '🇧🇾',
  },
  376: {
    label: 'Andorra',
    value: '376',
    flag: '🇦🇩'
  },
  377: {
    label: 'Monaco',
    value: '377',
    flag: '🇲🇨',
  },
  378: {
    label: 'San Marino',
    value: '378',
    flag: '🇸🇲',
  },
  379: {
    label: 'Holy See (Vatican City State)',
    value: '379',
    flag: '🇻🇦'
  },
  380: {
    label: 'Ukraine',
    value: '380',
    flag: '🇺🇦',
  },
  381: {
    label: 'Serbia',
    value: '381',
    flag: '🇷🇸',
  },
  382: {
    label: 'Montenegro',
    value: '382',
    flag: '🇲🇪',
  },
  385: {
    label: 'Croatia',
    value: '385',
    flag: '🇭🇷',
  },
  386: {
    label: 'Slovenia',
    value: '386',
    flag: '🇸🇮',
  },
  387: {
    label: 'Bosnia and Herzegovina',
    value: '387',
    flag: '🇧🇦',
  },
  389: {
    label: 'Macedonia',
    value: '389',
    flag: '🇲🇰',
  },
  420: {
    label: 'Czech Republic',
    value: '420',
    flag: '🇨🇿',
  },
  421: {
    label: 'Slovakia',
    value: '421',
    flag: '🇸🇰',
  },
  423: {
    label: 'Liechtenstein',
    value: '423',
    flag: '🇱🇮',
  },
  500: {
    label: 'South Georgia and the South Sandwich Islands',
    value: '500',
    flag: '🇫🇰',
  },
  501: {
    label: 'Belize',
    value: '501',
    flag: '🇧🇿',
  },
  502: {
    label: 'Guatemala',
    value: '502',
    flag: '🇬🇹',
  },
  503: {
    label: 'El Salvador',
    value: '503',
    flag: '🇸🇻',
  },
  504: {
    label: 'Honduras',
    value: '504',
    flag: '🇭🇳',
  },
  505: {
    label: 'Nicaragua',
    value: '505',
    flag: '🇳🇮',
  },
  506: {
    label: 'Costa Rica',
    value: '506',
    flag: '🇨🇷',
  },
  507: {
    label: 'Panama',
    value: '507',
    flag: '🇵🇦',
  },
  508: {
    label: 'Saint Pierre and Miquelon',
    value: '508',
    flag: '🇵🇲',
  },
  509: {
    label: 'Haiti',
    value: '509',
    flag: '🇭🇹',
  },
  590: {
    label: 'Saint Martin',
    value: '590',
    flag: '🇲🇫',
  },
  591: {
    label: 'Bolivia, Plurinational State of',
    value: '591',
    flag: '🇧🇴',
  },
  593: {
    label: 'Ecuador',
    value: '593',
    flag: '🇪🇨',
  },
  594: {
    label: 'French Guiana',
    value: '594',
  },
  595: {
    label: 'Paraguay',
    value: '595',
    flag: '🇵🇾',
  },
  596: {
    label: 'Martinique',
    value: '596',
  },
  597: {
    label: 'Surilabel',
    value: '597',
    flag: '🇸🇷',
  },
  598: {
    label: 'Uruguay',
    value: '598',
    flag: '🇺🇾',
  },
  599: {
    label: 'Netherlands Antilles',
    value: '599',
    flag: '🇳🇱',
  },
  670: {
    label: 'Timor-Leste',
    value: '670',
    flag: '🇹🇱',
  },
  672: {
    label: 'Norfolk Island',
    value: '672',
  },
  673: {
    label: 'Brunei Darussalam',
    value: '673',
    flag: '🇧🇳',
  },
  674: {
    label: 'Nauru',
    value: '674',
    flag: '🇳🇷',
  },
  675: {
    label: 'Papua New Guinea',
    value: '675',
    flag: '🇵🇬',
  },
  676: {
    label: 'Tonga',
    value: '676',
    flag: '🇹🇴',
  },
  677: {
    label: 'Solomon Islands',
    value: '677',
    flag: '🇸🇧',
  },
  678: {
    label: 'Vanuatu',
    value: '678',
    flag: '🇻🇺',
  },
  679: {
    label: 'Fiji',
    value: '679',
    flag: '🇫🇯',
  },
  680: {
    label: 'Palau',
    value: '680',
    flag: '🇵🇼',
  },
  681: {
    label: 'Wallis and Futuna',
    value: '681',
    flag: '🇼🇫',
  },
  682: {
    label: 'Cook Islands',
    value: '682',
    flag: '🇨🇰',
  },
  683: {
    label: 'Niue',
    value: '683',
    flag: '🇳🇺',
  },
  685: {
    label: 'Samoa',
    value: '685',
    flag: '🇼🇸',
  },
  686: {
    label: 'Kiribati',
    value: '686',
    flag: '🇰🇮',
  },
  687: {
    label: 'New Caledonia',
    value: '687',
    flag: '🇳🇨',
  },
  688: {
    label: 'Tuvalu',
    value: '688',
    flag: '🇹🇻',
  },
  689: {
    label: 'French Polynesia',
    value: '689',
    flag: '🇵🇫',
  },
  690: {
    label: 'Tokelau',
    value: '690',
    flag: '🇹🇰',
  },
  691: {
    label: 'Micronesia, Federated States of Micronesia',
    value: '691',
    flag: '🇫🇲',
  },
  692: {
    label: 'Marshall Islands',
    value: '692',
    flag: '🇲🇭',
  },
  850: {
    label: "Korea, Democratic People's Republic of Korea",
    value: '850',
    flag: '🇰🇵',
  },
  852: {
    label: 'Hong Kong',
    value: '852',
    flag: '🇭🇰',
  },
  853: {
    label: 'Macao',
    value: '853',
    flag: '🇲🇴',
  },
  855: {
    label: 'Cambodia',
    value: '855',
    flag: '🇰🇭',
  },
  856: {
    label: 'Laos',
    value: '856',
    flag: '🇱🇦',
  },
  872: {
    label: 'Pitcairn',
    value: '872',
    flag: '🇵🇳'
  },
  880: {
    label: 'Bangladesh',
    value: '880',
    flag: '🇧🇩',
  },
  886: {
    label: 'Taiwan',
    value: '886',
    flag: '🇹🇼',
  },
  960: {
    label: 'Maldives',
    value: '960',
    flag: '🇲🇻',
  },
  961: {
    label: 'Lebanon',
    value: '961',
    flag: '🇱🇧',
  },
  962: {
    label: 'Jordan',
    value: '962',
    flag: '🇯🇴',
  },
  963: {
    label: 'Syrian Arab Republic',
    value: '963',
    flag: '🇸🇾',
  },
  964: {
    label: 'Iraq',
    value: '964',
    flag: '🇮🇶',
  },
  965: {
    label: 'Kuwait',
    value: '965',
    flag: '🇰🇼',
  },
  966: {
    label: 'Saudi Arabia',
    value: '966',
    flag: '🇸🇦',
  },
  967: {
    label: 'Yemen',
    value: '967',
    flag: '🇾🇪',
  },
  968: {
    label: 'Oman',
    value: '968',
    flag: '🇴🇲',
  },
  970: {
    label: 'Palestinian Territory, Occupied',
    value: '970',
    flag: '🇵🇸',
  },
  971: {
    label: 'United Arab Emirates',
    value: '971',
    flag: '🇦🇪',
  },
  972: {
    label: 'Israel',
    value: '972',
    flag: '🇮🇱',
  },
  973: {
    label: 'Bahrain',
    value: '973',
    flag: '🇧🇭',
  },
  974: {
    label: 'Qatar',
    value: '974',
    flag: '🇶🇦',
  },
  975: {
    label: 'Bhutan',
    value: '975',
    flag: '🇧🇹',
  },
  976: {
    label: 'Mongolia',
    value: '976',
    flag: '🇲🇳',
  },
  977: {
    label: 'Nepal',
    value: '977',
    flag: '🇳🇵',
  },
  992: {
    label: 'Tajikistan',
    value: '992',
    flag: '🇹🇯',
  },
  993: {
    label: 'Turkmenistan',
    value: '993',
    flag: '🇹🇲',
  },
  994: {
    label: 'Azerbaijan',
    value: '994',
    flag: '🇦🇿',
  },
  995: {
    label: 'Georgia',
    value: '995',
    flag: '🇬🇪',
  },
  996: {
    label: 'Kyrgyzstan',
    value: '996',
    flag: '🇰🇬',
  },
  998: {
    label: 'Uzbekistan',
    value: '998',
    flag: '🇺🇿',
  },
  1242: {
    label: 'Bahamas',
    value: '1242',
    flag: '🇧🇸',
  },
  1246: {
    label: 'Barbados',
    value: '1246',
    flag: '🇧🇧',
  },
  1264: {
    label: 'Anguilla',
    value: '1264',
    flag: '🇦🇮'
  },
  1268: {
    label: 'Antigua and Barbuda',
    value: '1268',
    flag: '🇦🇬'
  },
  1284: {
    label: 'Virgin Islands, British',
    value: '1284',
    flag: '🇻🇬'
  },
  1340: {
    label: 'Virgin Islands, U.S.',
    value: '1340',
    flag: '🇻🇮'
  },
  1441: {
    label: 'Bermuda',
    value: '1441',
    flag: '🇧🇲',
  },
  1473: {
    label: 'Grenada',
    value: '1473',
    flag: '🇬🇩',
  },
  1649: {
    label: 'Turks and Caicos Islands',
    value: '1649',
    flag: '🇹🇨',
  },
  1664: {
    label: 'Montserrat',
    value: '1664',
    flag: '🇲🇸',
  },
  1670: {
    label: 'Northern Mariana Islands',
    value: '1670',
    flag: '🇲🇵',
  },
  1671: {
    label: 'Guam',
    value: '1671',
    flag: '🇬🇺',
  },
  1684: {
    label: 'American Samoa',
    value: '1684',
    flag: '🇦🇸'
  },
  1758: {
    label: 'Saint Lucia',
    value: '1758',
    flag: '🇱🇨',
  },
  1767: {
    label: 'Dominica',
    value: '1767',
    flag: '🇩🇲',
  },
  1784: {
    label: 'Saint Vincent and the Grenadines',
    value: '1784',
    flag: '🇻🇨',
  },
  1849: {
    label: 'Dominican Republic',
    value: '1849',
    flag: '🇩🇴'
  },
  1868: {
    label: 'Trinidad and Tobago',
    value: '1868',
    flag: '🇹🇹',
  },
  1869: {
    label: 'Saint Kitts and Nevis',
    value: '1869',
    flag: '🇰🇳',
  },
  1876: {
    label: 'Jamaica',
    value: '1876',
    flag: '🇯🇲',
  },
  1939: {
    label: 'Puerto Rico',
    value: '1939',
    flag: '🇵🇷'
  },
};
