import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';
import { InfoBlock, InfoBlockValuePositive } from 'shared/components';
import styled from 'styled-components';
import { listMonitors } from '../../redux/actions/monitorsActions';
import { useState } from 'react';
import { LoadingSpinner } from '../../shared/components/LoadingSpinner';

const Footer = styled.div`
  text-align: right;
`;
const SubValue = styled.div`
  font-size: 14px;
  color: #ffffff;
  display: block;
  font-weight: 700;
  margin-top: 4px;
`;

export function BlockTotalSites() {
  const [loading, setLoading] = useState(false);
  const monitorTotals = useSelector(selectors.monitorsTotals);
  return (
    <InfoBlock
      title="Total Sites"
      value={loading ? <LoadingSpinner /> : <InfoBlockValuePositive value={monitorTotals.total} />}
      footer={
        <Footer>
          Paused Sites <SubValue>{monitorTotals.paused}</SubValue>
        </Footer>
      }
      onClick={() => {
        setLoading(true);
        listMonitors({}).then(() => setLoading(false));
      }}
    />
  );
}
