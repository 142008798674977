import React from 'react';
import { MountTransition } from '../transitions/mount-transition';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  background: transparent;
`;

const Head = styled.div`
  display: flex;
  width: 100%;
  padding: 48px 0 24px 0;
  align-items: center;
`;
const Body = styled.div`
  width: 100%;
`;
const Title = styled.div`
  font-size: 28px;
`;
const Actions = styled.div`
  margin-left: auto;
`;
const CommonPage = ({ children, isMounted, title, actions }) => {
  return (
    <MountTransition isMounted={isMounted ?? true}>
      <Container>
        {(title || actions) && (
          <Head>
            {title && <Title>{title}</Title>}
            {actions && <Actions>{actions}</Actions>}
          </Head>
        )}
        <Body>{children}</Body>
      </Container>
    </MountTransition>
  );
};

export default CommonPage;
