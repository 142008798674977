import { createPortal } from 'react-dom';
import usePortal from "../hooks/usePortal";

const Portal = (props) => {
	const id = props.id;
	const children = props.children;
	const allProps = { ...props };
	delete allProps.id;
	delete allProps.children;
	const target = usePortal(id, allProps);
	return createPortal(
		children,
		target,
	);
};

export default Portal;