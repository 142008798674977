import { del, post } from 'redux/lib/api';

export const createUser = ({ email }, callback) => {
  return post(`account/invite_user`, {
    data: {
      email
    },
    action: 'CREATE_USER',
    callback
  });
};

export const updateUser = ({ user_id, name }, callback) => {
  return post(`account/edit_user`, {
    data: {
      user_id,
      name
    },
    action: 'UPDATE_USER',
    callback
  });
};

export const deleteUser = ({ user_id }, callback) => {
  return del(`/account/delete_user/${user_id}`, {
    action: 'DELETE_USER',
    callback
  });
};
