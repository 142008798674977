import { Divider } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getMonitorDataByJobId } from 'redux/actions/jobsActions';
import SiteModal from 'features/add-edit-monitor/site-modal';
import { PageWithLoading } from 'shared/components';
import { SiteBodyWidget } from 'widgets/site/site-body.widget';
import { SiteHeaderWidget } from 'widgets/site/site-header.widget';
import FloatingContactForm from '../../shared/components/FloatingContactForm';
import { getNotificationGroups } from '../../redux/actions/notificationsActions';
import { getIntegrations } from '../../redux/actions/integrationsActions';

export function SiteDetailPage() {
  const { actionId: jobId, arg1, arg2 } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const show = arg1 === 'edit';

  useEffect(() => {
    setIsLoading(true);

    const fetchData = () => {
      Promise.all([getMonitorDataByJobId(jobId), getNotificationGroups(), getIntegrations()]).then(
        () => {
          setIsLoading(false);
        }
      );
    };
    fetchData(); // fetch data immediately

    const interval = setInterval(() => {
      fetchData();
    }, 59000); // fetch data every 59 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <PageWithLoading isLoading={isLoading}>
      <Divider />
      <SiteHeaderWidget />
      <Divider />
      <SiteBodyWidget
        jobId={jobId}
        eventId={arg2}
      />
      <SiteModal
        jobId={jobId}
        show={show}
        adding={false}
      />
      <FloatingContactForm />
    </PageWithLoading>
  );
}
