import { useNavigate, useLocation } from 'react-router';

export function MonitorModalTitle({ adding = true }) {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const buttons = ['site', 'cron'];

  const selected = buttons.find((button) => path.includes(button)) || buttons[0];

  return (
    <div className="site-modal-title">
      {buttons.map((button, index) => {
        let isSelected = button.includes(selected);
        return (
          <button
            key={index}
            className={`site-modal-title-btn ${isSelected ? 'selected' : ''} ${
              index === 0 ? 'left' : index === buttons.length - 1 ? 'right' : 'middle'
            }`}
            onClick={() => {
              if (!isSelected) {
                navigate(`/dashboard/${button}/add`);
              }
            }}
            disabled={!adding}>
            {button}
          </button>
        );
      })}
    </div>
  );
}
