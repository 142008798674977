import styled from 'styled-components';

const Container = styled.div`
  color: #06abc1;
  font-size: 46px;
  padding: 28px 0 38px 0;
`;
export function InfoBlockValueSmall({ value }) {
  return <Container>{value}</Container>;
}
