const snakeToCamel = str =>
	str.toLowerCase().replace(/([-_][a-z])/g, group =>
		group
			.toUpperCase()
			.replace('-', '')
			.replace('_', '')
	);

const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

const pluck = (obj, fields, format) => {
	let set = Object.entries(obj).filter(n => fields.includes(n[0])).map(n => {
		if (format === 'camel' && String(n[0]).includes('_')) {
			n[0] = snakeToCamel(n[0]);
		}
		if (format === 'snake' && !String(n[0]).includes('_')) {
			n[0] = camelToSnakeCase(n[0]);
		}
		return n;
	});
	return Object.fromEntries(set);
}

export default pluck;