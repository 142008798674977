import jwtDecode from 'jwt-decode';
import createPersistedReducer from 'redux/lib/createPersistedReducer';

const initialState = {
  jwt: {},
  companies: null,
};

const persistConfig = {
  key: 'userReducer',
  whitelist: ['companyId', 'companies'],
  transforms: [],
};

export default createPersistedReducer(
  initialState,
  {
    POST_LOGIN_SUCCESS: (state, payload) => ({
      ...state,
      jwt: payload.data.decoded,
    }),

    POST_TOKEN: (state, payload) => {
      const jwt = jwtDecode(payload);
      return {
        ...state,
        jwt,
      };
    },

    LOGOUT: (state, payload) => {
      return {
        ...state,
        jwt: {},
      };
    },

    RESET_PASSWORD: (state, payload) => ({
      ...state,
    }),

    GET_USER_COMPANIES_SUCCESS: (state, payload) => ({
      ...state,
      companyId: state.companyId || (payload.data?.companies[0] || {}).id,
      companies: payload.data.companies,
    }),

    PUT_USER_COMPANY_SUCCESS: (state, payload) => ({
      ...state,
      jwt: payload.data.decoded,
    }),
  },
  persistConfig
);
