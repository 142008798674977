import { UsersTableFeature } from 'features/users/users-table';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import selectors from 'redux/selectors/selectors';
import { PageWithLoading } from 'shared/components';
import { AddUserWidget } from 'widgets/users/add-user.widget';
import FloatingContactForm from '../../shared/components/FloatingContactForm';

export function UsersPage() {
  const { actionId, arg1 } = useParams();
  const isInitializing = useSelector(selectors.isInitializing);
  const edit = arg1 === 'edit';

  return (
    <PageWithLoading
      isLoading={isInitializing}
      actions={
        <AddUserWidget
          id={actionId}
          edit={edit}
        />
      }>
      <UsersTableFeature />
      <FloatingContactForm />
    </PageWithLoading>
  );
}
