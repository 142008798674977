import { createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const buildPersistConfig = (key, { blacklist, whitelist, migrations = {}, transforms = [] }) => ({
  key,
  version: 15,
  storage: storage,
  blacklist: blacklist,
  whitelist: whitelist,
  transforms,
  migrate: createMigrate(migrations, { debug: false }),
});

export default buildPersistConfig;
