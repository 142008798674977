import { notification } from 'antd';
import { useNavigate } from 'react-router';
import { AddUserButtonFeature } from 'features/users/add-user-button';
import { AddUserModalFeature } from 'features/users/add-user-modal';
import { createUser, updateUser } from 'redux/actions/usersActions';
import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';

export function AddUserWidget({ id, edit }) {
  const user = useSelector(selectors.userById(id));

  const navigate = useNavigate();
  const show = id === 'add' || edit;
  const onHide = () => {
    navigate('/users');
  };
  const onSubmit = (data) => {
    const handler = edit ? updateUser : createUser;
    const processedData = edit ? { ...data, user_id: id } : data;
    handler(processedData).then((response) => {
      if (response) {
        notification.success({
          key: 'UserUpdate',
          message: 'Success',
          description: `User ${edit ? 'updated' : 'created'}`
        });
      }
    });
  };

  const getInitialStateEdit = (user) => ({
    name: user.name || '',
    user_id: user.user_id || ''
  });

  const fieldsEdit = [[{ id: 'name', label: 'Name' }]];
  const fieldsAdd = [[{ id: 'email', label: 'Email' }]];

  const fields = edit ? fieldsEdit : fieldsAdd;
  const initialState = edit ? getInitialStateEdit(user) : { email: '' };
  return (
    <>
      <AddUserButtonFeature />
      {show && (
        <AddUserModalFeature
          show={show}
          initialState={initialState}
          onHide={onHide}
          onSubmit={onSubmit}
          fields={fields}
          title={`${edit ? 'Edit' : 'Add'} User`}
        />
      )}
    </>
  );
}
