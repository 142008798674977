import { useState } from 'react';
import { useSelector } from 'react-redux';
import { setPeriodAction } from 'redux/actions/siteActions';
import selectors from 'redux/selectors/selectors';
import { DateRangePicker } from 'shared/components';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  width: 100%;
`;

const Title = styled.div`
  text-align: center;
  font-size: 40px;
  font-weight: 700;
`;

const SubTitle = styled.a`
  color: #06abc1;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
`;

const Range = styled.div`
  text-align: center;
  margin-top: 12px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export function SiteHeaderWidget() {
  const monitor = useSelector(selectors.httpMonitorDetails);
  const period = useSelector(selectors.sitePeriod);
  const [isLoading] = useState(false);

  return (
    <Container style={{ display: 'block' }}>
      <Content>
        <Header>
          <Title>{monitor.friendly_name}</Title>
          <SubTitle
            target="_blank"
            href={monitor.uri}>
            {monitor.uri}
          </SubTitle>
          <Range>
            <DateRangePicker
              loading={isLoading}
              defaultValue={period}
              onChange={(p) => {
                setPeriodAction(p);
              }}
            />
          </Range>
        </Header>
      </Content>
    </Container>
  );
}
