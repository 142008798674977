import { Button } from 'antd';
import Modal from 'shared/components/Modal';
import ModalBody from 'shared/components/ModalBody';
import ModalFooter from 'shared/components/ModalFooter';
import BoundFormGroup from 'shared/components/boundForm/BoundFormGroup';
import useBoundForm from 'shared/hooks/useBoundForm';

export function AddUserModalFeature({ show, onHide, onSubmit, title, fields, initialState }) {
  const form = useBoundForm({initialState});

  return (
    <Modal
      show={show}
      onHide={onHide}
      title={title}>
      <ModalBody>
        <BoundFormGroup
          formId={form.id}
          fields={fields}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          className="primary"
          onClick={() => {
            onSubmit(form.state);
            onHide();
          }}>
          Save
        </Button>
        <Button
          className="cancel"
          onClick={onHide}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
