const mapObjectToQueryParams = (queryMap = {}) => {
	return Object.entries(queryMap)
		.filter(([key, value]) => value !== undefined && value !== '')
		.map(([key, value]) => {
			if (Array.isArray(value) && value.length > 0) {
				return value.map(n => `&${key}[]=${n}`).join('');
			}
			return `&${key}=${value}`;
		})
		.join('');
}

export default mapObjectToQueryParams;

