import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from './shared/lib/reportWebVitals';
import LogRocket from 'logrocket';

import { persistor, store } from './redux/lib/configureStore';
import jwtDecode from 'jwt-decode';
import { logoutAndRedirect, postToken } from './redux/actions/userActions';

let token = localStorage.getItem('token');
if (token !== null && token !== 'undefined' && token !== 'null') {
  let jwt;
  try {
    jwt = jwtDecode(token);
  } catch (e) {
    localStorage.removeItem('token');
  }
  if (!jwt || jwt.exp < Date.now() / 1000) {
    localStorage.removeItem('token');
    logoutAndRedirect({ saveRedirectPath: true });
  } else {
    LogRocket.identify(jwt.user_id, {
      email: jwt.email,
      account_id: jwt.account_id
    });
    postToken(token);
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
