import { Divider } from 'antd';
import { SiteListFilterActiveFeature, SiteListSearchFeature, SiteTableFeature } from 'features';
import styled from 'styled-components';

const Container = styled.div`
    border: solid 2px #06abc1;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    padding: 24px;
`;

const Search = styled.div`
    flex: 1;
`;

const FilterActive = styled.div`
    flex: 1;
    display: flex;
`;

export function DashboardBodyWidget() {
  return (
    <Container>
      <Divider style={{ margin: '12px 0' }} />
      <div className={'dashboard-body-header'}>
        <Search>
          <SiteListSearchFeature />
        </Search>
        <Divider type="vertical" />
        <FilterActive>
          <SiteListFilterActiveFeature />
        </FilterActive>
      </div>
      <Divider style={{ margin: '12px 0' }} />
      <SiteTableFeature />
    </Container>
  );
}
