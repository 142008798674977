import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';

const tooltipDelay = 120;
const tooltipPadding = 12;

const getTitledElement = (startElement) => {
  const iterate = (element) => {
    if (element.hasAttribute('data-title')) {
      return element;
    }
    if (element.parentElement && !['BODY', 'HTML'].includes(element.nodeName)) {
      return iterate(element.parentElement);
    }
    return undefined;
  };
  return iterate(startElement);
};

const TooltipHandler = () => {
  const state = useRef({});
  const isMobile = useSelector(selectors.mobile);

  const tooltipIntervalHandler = () => {
    if (state.current.element && !state.current.tooltip) {
      const hovered = state.current.element;
      const newTooltipDelay = hovered.getAttribute('data-title-delay') || tooltipDelay;
      const delay = new Date() - state.current.duration;
      if (delay < newTooltipDelay) {
        return;
      }

      const tooltip = document.createElement('div');
      tooltip.classList.add('tooltip-component');
      const tooltipText = document.createElement('div');
      tooltipText.innerHTML = hovered.getAttribute('data-title');

      const offsetY = hovered.getAttribute('data-title-offset-y');
      tooltip.appendChild(tooltipText);
      document.body.appendChild(tooltip);

      const viewportOffset = hovered.getBoundingClientRect();
      const elementWidth = hovered.offsetWidth;
      const elementHeight = hovered.clientHeight;
      const tooltipWidth = tooltip.clientWidth;
      const tooltipHeight = tooltip.offsetHeight;
      const top = viewportOffset.top;
      const left = viewportOffset.left;

      const style = hovered.currentStyle || window.getComputedStyle(hovered);
      const elementSpacingBottom = parseFloat(style.paddingBottom || 0) + parseFloat(style.marginBottom || 0);

      const regularTop = top - tooltipHeight - tooltipPadding;
      const invertedTop = top + elementHeight + tooltipPadding - elementSpacingBottom;

      const regularLeft = left + elementWidth / 2 - tooltipWidth / 2;
      const leftAlignLeft = left + elementWidth / 2 - 12;
      const rightAlignLeft = left + elementWidth - tooltipWidth;

      const invertY = regularTop <= 0;
      if (invertY) {
        tooltip.classList.add('inverted-y');
      }

      const leftAlign = regularLeft <= 0;
      if (leftAlign) {
        tooltip.classList.add('left-align');
      }

      const rightAlign = regularLeft + tooltipWidth >= document.body.clientWidth;
      if (rightAlign) {
        tooltip.classList.add('right-align');
      }

      let finalTop = invertY ? invertedTop : regularTop;
      const finalLeft = leftAlign ? leftAlignLeft : rightAlign ? rightAlignLeft : regularLeft;

      if (offsetY) {
        finalTop = finalTop - offsetY;
      }

      tooltip.style.top = `${finalTop}px`;
      tooltip.style.left = `${finalLeft}px`;

      state.current.tooltip = tooltip;

      setTimeout(() => {
        tooltip.classList.add('active');
      });
    }
  };

  useEffect(() => {
    if (isMobile) {
      return;
    }

    window.onmouseover = (e) => {
      const titledElement = getTitledElement(e.target);
      if (titledElement) {
        state.current.element = titledElement;
        state.current.duration = new Date();
      }
    };

    window.onmouseout = (e) => {
      state.current.element = undefined;
      state.current.duration = undefined;
      if (state.current.tooltip) {
        const currentTitledElement = getTitledElement(e.target);
        if (currentTitledElement) {
          return;
        }

        const tooltip = state.current.tooltip;
        state.current.tooltip = undefined;
        if (window.debugTooltip !== true) {
          tooltip.classList.remove('active');
        }
        setTimeout(() => {
          if (tooltip) {
            if (window.debugTooltip !== true) {
              document.body.removeChild(tooltip);
            }
          }
        }, 200);
      }
    };

    const interval = setInterval(tooltipIntervalHandler, tooltipDelay / 2);
    return () => clearInterval(interval);
  }, []);

  return null;
};

export default TooltipHandler;
