import * as R from 'ramda';
import { sortByProps } from 'shared/lib/sortByProp';

export const monitors = (state) => state.jobs?.monitors;

export const monitorById = (id) => (state) => state.jobs?.monitors?.find((n) => n?.id === id);
export const monitorChecksByJobId = (id) => (state) =>
  state.jobs?.monitorChecks?.[id]?.sort(sortByProps(['created_at'], ['asc']));
export const monitorChecksByJobIdDesc = (id) => (state) =>
  state.jobs?.monitorChecks?.[id]?.sort(sortByProps(['created_at'], ['desc']));
export const monitorEventsByJobId = (id) => (state) =>
  state.jobs?.monitorEvents?.[id]?.sort(sortByProps(['created_at'], ['asc']));
export const monitorEventsByJobIdDesc = (id) => (state) =>
  state.jobs?.monitorEvents?.[id]?.sort(sortByProps(['created_at'], ['desc']));
export const monitorEventDetails = (state) => state?.jobs?.monitorEventDetails;

export const statusFilter = (state) => state?.jobs?.filterStatus;
export const searchQuery = (state) => state?.jobs?.searchQuery;

export const monitorsFiltered = (state) => {
  const status = statusFilter(state);
  const query = searchQuery(state).toLowerCase();

  const filterByStatus = (monitor) => {
    if (!status.length) return true;
    return status.includes(monitor.current_status);
  };

  const filterByQuery = (monitor) => {
    if (query === '') return true;
    return R.anyPass([
      () => R.includes(query, R.propOr('', 'friendly_name', monitor).toLowerCase()),
      () => R.includes(query, R.propOr('', 'uri', monitor)),
    ])();
  };

  return R.pipe(R.filter(filterByStatus), R.filter(filterByQuery))(monitors(state));
};

export const monitorsSortedDefault = (state) =>
  monitorsFiltered(state).sort((site) => (site.current_status === 'failed' ? -1 : 1));

export const tlsDetails = (state) => state?.jobs?.tlsDetails;
export const dnsDetails = (state) => state?.jobs?.dnsDetails;

export const siteUptimePercentage = (state) => state?.jobs?.siteUptimePercentage;

export const performanceData = (state) => state?.jobs?.performanceData || [];
