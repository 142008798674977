import React from 'react';
import BoundFormGroup from 'shared/components/boundForm/BoundFormGroup';

export function ResetPasswordFormFeature({ form, onKeyPress }) {
  const fields = [
    [
      {
        id: 'password',
        placeholder: 'New Password',
        format: 'password',
        type: 'password',
        required: true,
        onKeyPress: onKeyPress,
      },
    ], //
    [
      {
        id: 'passwordConfirm',
        placeholder: 'Confirm Password',
        format: 'password',
        type: 'password',
        required: true,
        onKeyPress: onKeyPress,
      },
    ], //
  ];
  return (
    <BoundFormGroup
      formId={form.id}
      fields={fields}
      onKey
    />
  );
}
