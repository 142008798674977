import React, { useEffect, useRef, useState } from 'react';
import { action } from '../../../redux/lib/api';
import { useSelector } from 'react-redux';
import validateEmail from '../../lib/validateEmail';
import { Slider } from 'antd';
import { HelpTooltip } from '../help/help-tooltip';

const stableObject = {};
const stableArray = [];

const requiredMessages = {
  email: 'Please enter an email address.',
};

const validationMessages = {
  email: 'Please enter another email address.',
};

const BoundSlider = React.memo(
  ({
    formId,
    id,
    className,
    placeholder,
    label,
    format,
    value,
    required,
    disabled,
    onChange,
    marks,
    step,
    min,
    max,
    tooltip,
    helpText,
  }) => {
    const reduxValue = useSelector(
      (state) => ((state.ui.boundForms[formId] || stableObject).state || stableObject)[id]
    );
    const validating = useSelector(
      (state) => (state.ui.boundForms[formId] || stableObject).validating
    );
    const reset = useSelector((state) => (state.ui.boundForms[formId] || stableObject).reset);
    const invalid = useSelector((state) =>
      ((state.ui.boundForms[formId] || stableObject).invalids || stableArray).find(
        (n) => n.id === id
      )
    );
    const empty = useSelector((state) =>
      ((state.ui.boundForms[formId] || stableObject).empties || stableArray).find((n) => n === id)
    );
    const inputRef = useRef(null);
    const formGroupRef = useRef(null);
    const [showEmptyErrors, setShowEmptyErrors] = useState(false);

    const valid = invalid === undefined;
    let displayValue = formId !== undefined ? reduxValue : value;
    const requiredMessage =
      requiredMessages[format] || `Please enter a ${label || placeholder || 'value'}.`;
    const invalidMessage =
      (invalid || {}).message || validationMessages[format] || 'Please enter another value.';

    useEffect(() => {
      if (invalid && formGroupRef.current) {
        formGroupRef.current.classList.add('shake-horizontal');
        setTimeout(() => {
          formGroupRef.current.classList.remove('shake-horizontal');
        }, 1200);
      }
    }, [invalid]);

    const setValid = (valid) => {
      action(!valid ? 'uiSetFormInvalidField' : 'uiRemoveFormInvalidField', {
        formId,
        fieldId: id,
      });
    };

    const setEmpty = (empty) => {
      action(empty ? 'uiSetFormEmptyField' : 'uiRemoveFormEmptyField', {
        formId,
        fieldId: id,
      });
    };

    const validate = (opts = {}) => {
      let valid = true;
      const empty = !reduxValue && required;
      setEmpty(empty);
      if (empty) {
        if (opts.soft !== true) {
          setShowEmptyErrors(true);
        }
        return;
      }
      if (format === 'email' && reduxValue) {
        valid = validateEmail(reduxValue);
      }
      setValid(valid);
    };

    const handleChange = (value) => {
      if (formId && id) {
        action('uiPatchFormState', {
          formId: formId,
          state: { [id]: value },
        });
        const empty = !value && required;
        setEmpty(empty);
      }
      if (typeof onChange === 'function') {
        onChange(value);
      }
      setValid(true);
      setShowEmptyErrors(false);
    };

    useEffect(() => {
      if (validating === true) {
        validate();
      }
    }, [validating]);

    useEffect(() => {
      validate({ soft: true });
    }, [required]);

    useEffect(() => {
      validate({ soft: true });
    }, [reset]);

    const showErrors = !valid || (showEmptyErrors && empty);

    return (
      <div
        className={`bound-input ${className || ''}`}
        ref={formGroupRef}>
        <div className="control-label">
          {!!label && <div className={'label'}>{label}</div>}
          {!!helpText && (
            <div className="control-help">
              <HelpTooltip text={helpText} />
            </div>
          )}
        </div>
        <Slider
          ref={inputRef}
          name={id}
          disabled={disabled}
          marks={marks}
          onChangeComplete={handleChange}
          onChange={handleChange}
          defaultValue={displayValue}
          value={displayValue}
          step={step}
          min={min}
          max={max}
          tooltip={tooltip}
        />

        {showErrors && (
          <div className="invalid-feedback">{!displayValue ? requiredMessage : invalidMessage}</div>
        )}
      </div>
    );
  }
);

export default BoundSlider;
