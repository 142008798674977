import { Button } from 'antd';
import React from 'react';
import { postSignUp } from 'redux/actions/userActions';

export function SignUpButtonFeature({ form, onError, onSuccess, disabled }) {
  const [submitting, setSubmitting] = React.useState(false);

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      handleFormSubmit(e);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    form.validate();
    if (!form.isValid) {
      return;
    }
    setSubmitting(true);
    let promotekitReferral = window.promotekit_referral;
    postSignUp({ ...form.state, promotekitReferral }, (data, err) => {
      setSubmitting(false);
      if (err) {
        onError(err.message);
        return;
      }
      if (data?.token) {
        onSuccess();
      }
    });
  };
  return (
    <Button
      block
      loading={submitting}
      className={'primary'}
      onClick={handleFormSubmit}
      onKeyUp={onEnter}
      disabled={submitting || disabled}>
      Sign Up
    </Button>
  );
}
