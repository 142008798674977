import styled from 'styled-components';
import { useSelector } from 'react-redux';
import selectors from '../../redux/selectors/selectors';
import { Divider } from 'antd';
import { CronActionButtonFeature } from '../../features/cron-view/cron-action-button';
import { InfoBlock, InfoBlockFooterBase, InfoBlockValueSmall } from '../../shared/components';
import React, { useEffect } from 'react';
import { getUiDateShort } from '../../shared/lib/dates';
import { CronSidebarFeature } from '../../features/cron-view/cron-detail-sidebar';
import { CronIncidentsTableFeature } from '../../features/cron-view/cron-incidents-table';
import { CronRecentsTableFeature } from '../../features/cron-view/cron-recents-table';
import { getCronJobUptimePercentage } from '../../redux/actions/cronJobsActions';
import moment from 'moment';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export function CronBodyWidget() {
  const monitor = useSelector(selectors.cronMonitorDetails);
  const range = useSelector(selectors.sitePeriod);
  const rangeFormatted = useSelector(selectors.sitePeriodFormatted);
  const cronUptime = useSelector(selectors.cronMonitorUptimePercentage);
  const incidentEvents = useSelector(selectors.cronMonitorEvents);

  useEffect(() => {
    getCronJobUptimePercentage({
      jobId: monitor.id,
      startDate: moment(range.start).toISOString(),
      endDate: moment(range.end).toISOString(),
    });
  }, [range]);

  return (
    <>
      <div className={'sites-container-upper'}>
        <Content>
          <div className={'site-blocks'}>
            <InfoBlock
              thin
              autoHeight
              title="Uptime"
              value={<InfoBlockValueSmall value={`${cronUptime.uptime_percentage}%`} />}
              footer={
                <InfoBlockFooterBase>
                  Range selected {rangeFormatted?.start} - {rangeFormatted?.end}
                </InfoBlockFooterBase>
              }
            />
            <Divider type="vertical" />
            <InfoBlock
              thin
              autoHeight
              title="Incidents"
              value={<InfoBlockValueSmall value={cronUptime.incidents} />}
              footer={
                <InfoBlockFooterBase>
                  Last Update{' '}
                  {incidentEvents?.length ? getUiDateShort(incidentEvents[0].started_at) : 'Never'}
                </InfoBlockFooterBase>
              }
            />
          </div>
          <Divider />
          <CronRecentsTableFeature jobId={monitor.id} />
        </Content>
        <div className={'side-bar'}>
          <CronActionButtonFeature />
          <Divider />
          <CronSidebarFeature />
        </div>
      </div>
      <Divider />
      <div style={{ display: 'flex' }}>
        <CronIncidentsTableFeature jobId={monitor.id} />
      </div>
    </>
  );
}
