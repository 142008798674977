import React, { useEffect, useRef } from 'react';
import { action } from '../../../redux/lib/api';
import { useSelector } from 'react-redux';
import { Checkbox } from 'antd';
import { HelpTooltip } from '../help/help-tooltip';

const stableObject = {};
const stableArray = [];

const BoundCheckbox = React.memo(
  ({
    formId,
    id,
    className,
    placeholder,
    label,
    format,
    value,
    required,
    disabled,
    onChange,
    marks,
    step,
    min,
    max,
    tooltip,
    helpText,
  }) => {
    const reduxValue = useSelector(
      (state) => ((state.ui.boundForms[formId] || stableObject).state || stableObject)[id]
    );
    const invalid = useSelector((state) =>
      ((state.ui.boundForms[formId] || stableObject).invalids || stableArray).find(
        (n) => n.id === id
      )
    );
    const inputRef = useRef(null);
    const formGroupRef = useRef(null);

    let displayValue = formId !== undefined ? reduxValue : value;

    useEffect(() => {
      if (invalid && formGroupRef.current) {
        formGroupRef.current.classList.add('shake-horizontal');
        setTimeout(() => {
          formGroupRef.current.classList.remove('shake-horizontal');
        }, 1200);
      }
    }, [invalid]);

    const setValid = (valid) => {
      action(!valid ? 'uiSetFormInvalidField' : 'uiRemoveFormInvalidField', {
        formId,
        fieldId: id,
      });
    };

    const setEmpty = (empty) => {
      action(empty ? 'uiSetFormEmptyField' : 'uiRemoveFormEmptyField', {
        formId,
        fieldId: id,
      });
    };

    const handleChange = (event) => {
      const value = event.target.checked;
      if (formId && id) {
        action('uiPatchFormState', {
          formId: formId,
          state: { [id]: value },
        });
        const empty = !value && required;
        setEmpty(empty);
      }
      if (typeof onChange === 'function') {
        onChange(value);
      }
      setValid(true);
    };

    return (
      <div
        className={`bound-input ${className || ''} bound-checkbox`}
        ref={formGroupRef}>
        <Checkbox
          ref={inputRef}
          name={id}
          disabled={disabled}
          onChange={handleChange}
          defaultValue={displayValue}
          checked={displayValue}
        />
        <div className="bound-checkbox-label">
          {!!label && <div className={'label'}>{label}</div>}
        </div>
        {!!helpText && (
          <div className="control-help">
            <HelpTooltip text={helpText} />
          </div>
        )}
      </div>
    );
  }
);

export default BoundCheckbox;
