import { Divider } from 'antd';
import { BlockBillingDetails } from 'features/account/block-billing-details';
import { BlockCurrentPlan } from 'features/account/block-current-plan';
import { BlockSmsCredits } from 'features/account/block-sms-credits';
import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';
import { PageWithLoading } from 'shared/components';
import styled from 'styled-components';
import FloatingContactForm from '../../shared/components/FloatingContactForm';
import { BlockApiKeys } from '../../features/account/block-api-keys';

const Block = styled.div`
  position: relative;
  border: solid 2px #06abc1;
  width: 100%;
  padding: 24px;
`;

export function AccountPage() {
  const isInitializing = useSelector(selectors.isInitializing);
  return (
    <PageWithLoading isLoading={isInitializing}>
      <Divider />
      <div className={'account-container'}>
        <Block>
          <BlockCurrentPlan />
        </Block>
        <Block>
          <BlockBillingDetails />
        </Block>
        <Block>
          <BlockSmsCredits />
        </Block>
        <Block>
          <BlockApiKeys />
        </Block>
      </div>
      <FloatingContactForm />
    </PageWithLoading>
  );
}
