import { Button, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Modal from 'shared/components/Modal';
import ModalBody from 'shared/components/ModalBody';
import ModalFooter from 'shared/components/ModalFooter';
import BoundFormGroup from 'shared/components/boundForm/BoundFormGroup';
import { PhoneCodeSelector } from 'shared/components/phone-code-selector/phone-code-selector';
import useBoundForm from 'shared/hooks/useBoundForm';
import { useSelector } from 'react-redux';
import selectors from '../../redux/selectors/selectors';
import { Icon } from '../../shared/components';

const initialState = {};

export function AddNotificationModalFeature({ show, onSubmit, formData, title, addText }) {
  const integrations = useSelector(selectors.integrations);
  const form = useBoundForm({ initialState: formData ?? initialState });
  const navigate = useNavigate();
  const [type, setType] = useState('email');

  const fieldsEmail = [[{ id: 'email_address', label: 'Email', format: 'email' }]];
  const fieldsSms = [
    [
      { id: 'country_code', label: 'Code', format: 'number', hidden: true },
      {
        id: 'phone_number',
        label: 'Number',
        type: 'number',
        format: 'string',
      },
    ],
  ];
  const fieldsSlack = [
    [
      {
        id: 'channel',
        label: 'Channel Name or ID',
        placeholder: '#site-qwality-alerts',
        helpText: 'Enter the channel name or ID where you want to receive notifications.',
      },
    ],
  ];

  useEffect(() => {
    switch (formData?.type) {
      case 'email':
        setType('email');
        form.setState({ email_address: formData.details.email_address });
        break;
      case 'sms':
        setType('sms');
        form.setState({
          country_code: formData.details.country_code,
          phone_number: formData.details.phone_number,
        });
        break;
      case 'slack':
        setType('slack');
        form.setState({
          channel: formData.details.channel,
          integration_id: formData.details.integration_id,
        });
        break;
      default:
        break;
    }
  }, [formData, integrations]);

  const onHide = () => {
    form.reset();
    navigate('/notifications');
  };

  const emailBoundForm = () => {
    return (
      <BoundFormGroup
        formId={form.id}
        fields={fieldsEmail}
      />
    );
  };

  const smsBoundForm = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div
          className="bound-input"
          style={{ marginRight: 12 }}>
          <div
            className="label"
            style={{ paddingTop: '5px', paddingBottom: '4px' }}>
            Code
          </div>
          <PhoneCodeSelector
            defaultValue={formData?.details?.country_code}
            setValue={(value) => {
              form.set('country_code', parseFloat(value));
            }}
          />
        </div>
        <BoundFormGroup
          formId={form.id}
          fields={fieldsSms}
        />
      </div>
    );
  };

  const slackBoundForm = () => {
    return (
      <div style={{ display: 'grid' }}>
        <label style={{ fontWeight: 'bold', margin: '4px 0' }}>Integration</label>
        <Select
          style={{ width: '100%', marginBottom: 12 }}
          onChange={(value) => {
            form.set('integration_id', value);
          }}
          placeholder={'Select Integration'}
          options={integrations
            .filter((item) => item.type === 'slack')
            .map((item) => {
              return {
                value: item.id,
                label: item.friendly_name,
              };
            })}
          optionRender={(option) => {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name={'slack'} />
                <span style={{ paddingLeft: 8 }}>{option.label}</span>
              </div>
            );
          }}
        />
        <BoundFormGroup
          formId={form.id}
          fields={fieldsSlack}
        />
      </div>
    );
  };

  const webhookBoundForm = () => {
    return (
      <div style={{ display: 'grid' }}>
        <label style={{ fontWeight: 'bold', margin: '4px 0' }}>Integration</label>
        <Select
          style={{ width: '100%', marginBottom: 12 }}
          onChange={(value) => {
            form.set('integration_id', value);
          }}
          placeholder={'Select Integration'}
          options={integrations
            .filter((item) => item.type === 'webhook')
            .map((item) => {
              return {
                value: item.id,
                label: item.friendly_name,
              };
            })}
          optionRender={(option) => {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name={'webhook'} />
                <span style={{ paddingLeft: 8 }}>{option.label}</span>
              </div>
            );
          }}
        />
      </div>
    );
  };

  const getBoundForm = () => {
    switch (type) {
      case 'email':
        return emailBoundForm();
      case 'sms':
        return smsBoundForm();
      case 'slack':
        return slackBoundForm();
      case 'webhook':
        return webhookBoundForm();
      default:
        return null;
    }
  };

  const getNotificationTypeSelectIcon = (type) => {
    let icon = <></>;
    let iconStyle = { marginBottom: '-2px' };
    switch (type) {
      case 'email':
        icon = (
          <Icon
            name={'envelope'}
            style={iconStyle}
          />
        );
        break;
      case 'sms':
        icon = (
          <Icon
            name={'message'}
            style={iconStyle}
          />
        );
        break;
      case 'slack':
        icon = (
          <Icon
            name={'slack'}
            style={iconStyle}
          />
        );
        break;
      case 'webhook':
        icon = (
          <Icon
            name={'webhook'}
            style={iconStyle}
          />
        );
        break;
      default:
        break;
    }

    return <span style={{ paddingRight: '8px' }}>{icon}</span>;
  };

  const getNotificationOptionLabel = (type, displayName) => {
    return (
      <>
        {getNotificationTypeSelectIcon(type)}
        <span>{displayName ?? type}</span>
      </>
    );
  };

  const notificationOptions = [
    { value: 'email', label: getNotificationOptionLabel('email', 'Email') },
    { value: 'sms', label: getNotificationOptionLabel('sms', 'SMS') },
    { value: 'slack', label: getNotificationOptionLabel('slack', 'Slack') },
    { value: 'webhook', label: getNotificationOptionLabel('webhook', 'Webhook') },
  ];

  return (
    <Modal
      show={show}
      onHide={onHide}
      title={title}>
      <ModalBody>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
            marginBottom: 8,
          }}>
          Notification Type
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 12 }}>
          <Select
            style={{ width: '100%' }}
            value={type}
            onChange={(value) => setType(value)}
            options={notificationOptions}
          />
        </div>
        {getBoundForm()}
      </ModalBody>
      <ModalFooter>
        <Button
          className="primary"
          onClick={() => {
            onSubmit({
              details: {
                ...form.state,
              },
              type,
            });
            onHide();
          }}>
          {addText}
        </Button>
        <Button
          className="cancel"
          onClick={onHide}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
