import { Button } from 'antd';
import { useNavigate } from 'react-router';
import { Icon } from 'shared/components';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export function AddUserButtonFeature() {
  const navigate = useNavigate();

  const [allowAddUser, setAllowAddUser] = useState(false);

  const openModal = () => {
    navigate('add');
  };

  const accountInfo = useSelector((state) => state?.eCommerce?.accountInfo);

  useEffect(() => {
    if (accountInfo?.usage?.users_usage < accountInfo?.usage?.users_limit) {
      setAllowAddUser(true);
    }
  }, [
    accountInfo
  ]);

  return (
    <Button
      disabled={!allowAddUser}
      onClick={openModal}
      className="primary_big"
      icon={
        <Icon
          name="plus"
          size={12}
        />
      }>
      Add User
    </Button>
  );
}
