import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { LayoutLogin } from 'shared/layout';
import { SignUpFromInviteWidget } from '../../widgets/sign-up/sign-up-from-invite.widget';

export const SignUpFromInvitePage = () => {
  const [searchParams] = useSearchParams();

  return (
    <LayoutLogin>
      <SignUpFromInviteWidget
        invite_code={searchParams.get('invite_code')}
        account_id={searchParams.get('account_id')}
        email={searchParams.get('email')}
      />
    </LayoutLogin>
  );
};
