import { del, put, get, post } from 'redux/lib/api';

export const getMonitors = () => {
  return get('http/jobs', {
    action: 'GET_MONITORS',
    query: {
      limit: 10,
      offset: 0
    }
  });
};

export const getMoreMonitors = ({ limit, offset }) => {
  return get('http/jobs', {
    action: 'GET_MORE_MONITORS',
    query: {
      limit,
      offset
    }
  });
};

export const searchMonitors = ({ text }) => {
  return get('http/jobs', {
    action: 'SEARCH_MONITORS',
    query: {
      text
    }
  });
};

export const getRecentMonitorChecksByJobId = ({ jobId }) => {
  return get(`http/job/${jobId}/recent`, {
    action: 'GET_MONITOR_CHECKS',
    meta: {
      jobId
    }
  });
};

export const getMonitorDataByJobId = (jobId) => {
  return get(`http/job/${jobId}`, {
    action: 'GET_HTTP_MONITOR_DATA',
    meta: {
      jobId
    }
  });
};

export const postMonitor = (data, callback) => {
  return post(`http/job`, {
    data: {
      friendly_name: data.friendly_name,
      method: data.method || 'GET',
      uri: data.uri,
      timeout_ms: parseFloat(data?.timeout_ms || 15) * 1000,
      run_interval_seconds: data?.run_interval_seconds * 60,
      group_notification_ids: data?.group_notification_ids || [],
      state: data?.state,
      keyword_search: data?.keyword_search,
      monitor_tls: data?.monitor_tls,
      monitor_dns: data?.monitor_dns
    },
    action: 'CREATE_HTTP_MONITOR',
    callback
  });
};

export const deleteMonitor = (id, callback) => {
  return del(`http/job/${id}`, {
    action: 'DELETE_HTTP_MONITOR',
    onResponse: () => {
      return {
        status: 200,
        data: {
          job_id: id
        }
      };
    },
    callback
  });
};

export const putMonitor = (id, data, callback) => {
  return put(`http/job/${id}`, {
    data: {
      friendly_name: data.friendly_name,
      method: data.method || 'GET',
      uri: data.uri,
      timeout_ms: parseFloat(data?.timeout_ms || 15) * 1000,
      run_interval_seconds: data?.run_interval_seconds * 60,
      group_notification_ids: data?.group_notification_ids,
      state: data?.state,
      keyword_search: data?.keyword_search,
      monitor_tls: data?.monitor_tls,
      monitor_dns: data?.monitor_dns
    },
    action: 'UPSERT_HTTP_MONITOR',
    callback
  });
};

export const getMonitorEvents = ({ jobId }) => {
  return get(`http/job/${jobId}/events`, {
    action: 'GET_MONITOR_EVENTS',
    meta: {
      jobId
    }
  });
};

export const getMonitorEventDetails = ({ eventId }) => {
  return get(`http/job/result/${eventId}/details`, {
    action: 'GET_MONITOR_EVENT_DETAILS',
    meta: {
      eventId
    }
  });
};

export const getTlsState = (tls_job_id) => {
  return get(`tls/job/${tls_job_id}`, {
    action: 'GET_TLS_STATE'
  });
};

export const getDnsState = (tls_job_id) => {
  return get(`dns/job/${tls_job_id}`, {
    action: 'GET_DNS_STATE'
  });
};

export const getSiteUptime = ({ job_id, startDate, endDate }) => {
  return get(`http/job/${job_id}/uptime_percentage`, {
    action: 'GET_SITE_UPTIME',
    query: {
      start_date_time: startDate,
      end_date_time: endDate
    }
  });
};

export const getPerformanceData = ({ job_id, timeFrame }) => {
  return get(`http/job/${job_id}/performance`, {
    action: 'GET_PERFORMANCE_DATA',
    query: {
      time_frame: timeFrame
    }
  });
};
