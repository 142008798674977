import formatPhoneNumber from './formPhoneNumber';

const getNotificationTooltip = (notification, integrations) => {
  let notification_type = notification?.type;
  let notification_value;
  switch (notification_type) {
    case 'email':
      notification_type = 'Email';
      notification_value = notification?.details?.email_address;
      break;
    case 'sms':
      notification_type = 'SMS';
      notification_value = formatPhoneNumber(
        notification?.details?.country_code,
        notification?.details?.phone_number
      );
      break;
    case 'slack':
      notification_type = 'Slack';
      notification_value = notification?.details?.channel;
      break;
    case 'webhook':
      const mappedIntegration = integrations.filter((integration) => {
        return integration.id === notification?.details?.integration_id;
      });
      notification_type = 'Webhook';
      notification_value = mappedIntegration[0]?.details?.webhook_url ?? 'No webhook URL';
      break;
    default:
      notification_value = 'Unknown';
  }
  return (
    <span>
      {notification_type}: {notification_value}
      <br />
    </span>
  );
};

export default getNotificationTooltip;
