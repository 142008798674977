import React from 'react';
import { BlockTotalSites } from 'features';
import { BlockOnlineSites } from 'features/block-online-sites/block-online-sites';
import { BlockDownSites } from 'features/block-down-sites/block-down-sites';

export function DashboardHeaderWidget() {
  return (
    <div className={'dashboard-blocks'}>
      <div className={'dashboard-block'}>
        <BlockTotalSites />
      </div>
      <div className={'dashboard-block'}>
        <BlockOnlineSites />
      </div>
      <div className={'dashboard-block'}>
        <BlockDownSites />
      </div>
    </div>
  );
}
