import formatPhoneNumber from '../../shared/lib/formPhoneNumber';

export const mapNotificationsData = (data, integrations = []) => {
  let value = '';
  switch (data.type) {
    case 'email':
      value = data.details.email_address;
      break;
    case 'sms':
      value = formatPhoneNumber(data.details.country_code, data.details.phone_number);
      break;
    case 'slack':
      value = data.details.channel;
      break;
    case 'webhook':
      const mappedIntegration = integrations.filter((integration) => {
        return integration.id === data.details.integration_id;
      });
      value = mappedIntegration[0]?.details.webhook_url ?? 'No webhook URL';
      break;
    default:
      break;
  }

  return {
    id: data.id,
    type: data.type,
    value: value,
  };
};
