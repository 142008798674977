import React from 'react';
import { useDelayUnmount } from '../../hooks/useDelayUnmount';
import styled, { css, keyframes } from 'styled-components';

const hideTransition = keyframes`
 0% { opacity: 1 }
 30% { opacity: .5 }
 40% { opacity: .3; }
 100% { opacity: 0; }
`;

const showTransition = keyframes`
 0% { opacity: 0 }
 30% { opacity: .3 }
 40% { opacity: .5; }
 100% { opacity: 1; }
`;

const animationShow = css(['', ' 0.3s linear;'], showTransition);

const animationHide = css(['', ' 0.3s linear;'], hideTransition);

const Container = styled.div`
  width: 100%;
  height: 100%;
  animation: ${({ $isMounted }) => ($isMounted ? animationShow : animationHide)};
`;

export function MountTransition({ children, isMounted }) {
  const shouldMount = useDelayUnmount(isMounted, 500);
  return shouldMount ? <Container $isMounted={isMounted}>{children}</Container> : null;
}
