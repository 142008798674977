import { Head, Title } from 'shared/styled/styled';
import { Footer, Item, Key, Value } from './styled';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';

export function BlockSmsCredits() {
  const usage = useSelector(selectors.usage);
  const smsLeft = usage.sms_limit - usage.sms_usage;
  return (
    <div>
      <Head>
        <Title>SMS Credits Remaining</Title>
      </Head>
      <Item>
        <Key>SMS</Key>
        <Value>{smsLeft}</Value>
      </Item>
      <Footer>
        <Button className="primary">Refill</Button>
      </Footer>
    </div>
  );
}
