import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import ui from './uiReducer';
import user from './userReducer';
import monitors from './monitorsReducer';
import jobs from './jobsReducer';
import eCommerce from './eCommerceReducer';
import site from './siteReducer';
import notifications from './notificationsReducer';
import integrations from './integrationsReducer';
import users from './usersReducer';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    ui,
    user,
    monitors,
    jobs,
    eCommerce,
    site,
    notifications,
    integrations,
    users,
  });

export default rootReducer;
