import React from 'react';

import { ReactComponent as Slack } from './Slack.svg';
import { ReactComponent as Walmart } from './Walmart.svg';
import { ReactComponent as Github } from './Github.svg';

import { ReactComponent as Play } from './Play.svg';

import { ReactComponent as Notes } from './Notes.svg';
import { ReactComponent as Trash } from './Trash.svg';
import { ReactComponent as Home } from './Home.svg';
import { ReactComponent as Settings } from './Settings.svg';
import { ReactComponent as Logout } from './Logout.svg';
import { ReactComponent as Delete } from './Delete.svg';
import { ReactComponent as Cross } from './Cross.svg';

export const iconMap = {
  Github: Github,
  Slack: Slack,
  Walmart: Walmart,
  Play: Play,
  Notes: Notes,
  Trash: Trash,
  Home: Home,
  Settings: Settings,
  Logout: Logout,
  Delete: Delete,
  Cross: Cross,
};

const SVG = React.memo(({ icon, basic = false, type = 'svg', invert = false, className, title, titleDelay, onClick = () => {} }) => {
  const Icon = iconMap[icon];
  if (!Icon) {
    return (
      <div
        className={'svg-wrapper'}
        data-title={title}
        data-title-delay={titleDelay}>
        <img
          className={className}
          src={icon}
          alt={'SVG'}
        />
      </div>
    );
  }

  return (
    <div
      className={`svg-wrapper ${className || ''} ${basic ? 'basic' : ''} ${invert ? 'invert' : ''}`}
      onClick={onClick}
      data-title={title}
      data-title-delay={titleDelay}>
      <Icon />
    </div>
  );
});

export default SVG;
