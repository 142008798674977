import React, { useRef, useState } from 'react';
import useBoundForm from 'shared/hooks/useBoundForm';
import { useNavigate } from 'react-router-dom';
import Modal from 'shared/components/Modal';
import { ResetPasswordFormFeature } from 'features/reset-password-form/reset-password-form';
import { ResetPasswordButtonFeature } from 'features/reset-password-button/reset-password-button';

const getInitialState = () => ({
  password: '',
  passwordConfirm: '',
});

export function ResetPasswordWidget({ code, email }) {
  const navigate = useNavigate();
  const form = useBoundForm({ initialState: getInitialState() });
  const wrapperRef = useRef(null);
  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const [submitting] = React.useState(undefined);
  const [success, setSuccess] = useState(false);

  const goToLogin = () => {
    navigate(`/auth/login`);
  };

  return (
    <>
      <Modal show={success} title="Reset password">
        <h2 style={{textAlign: 'center'}}>Password reset was successful.</h2>
        <div className={'footer'}>
          <button
            className={'primary'}
            style={{ width: '100%' }}
            onClick={goToLogin}>
            Ok
          </button>
        </div>
      </Modal>

      <div
        className={`log-in-wrapper`}
        ref={wrapperRef}>
        <div className={'heading'}>Reset Password</div>
        <div className={`subheading ${errorMessage 
            ? 'error' 
            : ''} ${submitting 
            ? 'submitting' 
            : ''}`}>
                {submitting 
                ? 'Resetting...' 
                : errorMessage || `Enter your new password and confirm it`}</div>
        <ResetPasswordFormFeature form={form} />
        <ResetPasswordButtonFeature
          form={form}
          code={code}
          email={email}
          onSuccess={() => {
            setSuccess(true);
          }}
          onReset={() => {
            form.reset(getInitialState());
          }}
          onError={(error) => {
            setErrorMessage(error);
          }}
        />
      </div>
    </>
  );
}
