import { Icon } from '../../shared/components';
import { Button } from 'antd';
import { useNavigate } from 'react-router';

export function BlockIntegration({ icon, title, action }) {
  const navigate = useNavigate();
  return (
    <Button
      style={{
        height: '88px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '24px',
        fontWeight: 700
      }}
      onClick={() => {
        navigate(`/integrations/${action}`);
      }}
    >
      <Icon name={icon} size={64} />
      <span style={{ paddingLeft: '12px' }}>{title}</span>
    </Button>
  );
}
