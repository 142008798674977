import styled from 'styled-components';
import Modal from './Modal';

const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 24px 0 24px;
  width: 100%;
`;

const ConfirmModal = ({ show, title, text, onOk, okText = 'Ok', onCancel, cancelText = 'Cancel' }) => {
  return (
    <Modal title={title} show={show} onHide={() => {
      onCancel();
    }}>
      <Content>
        <h3>{text}</h3>
      </Content>

      <div className="footer">
        <button className="cancel" onClick={onOk}>
          {okText}
        </button>
        <button className="primary" onClick={onCancel} style={{ marginLeft: '6px' }}>
          {cancelText}
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
