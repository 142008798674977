const mergeByProp = (prop, a = [], b = [], rulesFn) => {
  if (typeof rulesFn !== 'function') {
    return [...a.filter((n) => !b.find((o) => (o || {})[prop] === n[prop])), ...b];
  }
  const uniqueA = a.filter((n) => !b.find((o) => (o || {})[prop] === n[prop]));
  const uniqueB = b.filter((n) => !a.find((o) => (o || {})[prop] === n[prop]));
  const merged = a
    .filter((n) => !!b.find((o) => (o || {})[prop] === n[prop]))
    .map((aInstance) => {
      const bInstance = b.find((o) => (o || {})[prop] === aInstance[prop]);
      return rulesFn(aInstance, bInstance);
    });
  return [...uniqueA, ...uniqueB, ...merged];
};

export default mergeByProp;
