import createPersistedReducer from 'redux/lib/createPersistedReducer';
import { getLast24Hours } from 'shared/lib/dates';

const initialState = {
  periodPerformance: getLast24Hours(),
  period: getLast24Hours()
};

const persistConfig = {
  key: 'siteReducer',
  whitelist: [],
  transforms: []
};

export default createPersistedReducer(
  initialState,
  {
    SET_PERIOD_PERFORMANCE: (state, payload) => {
      return {
        ...state,
        periodPerformance: payload
      };
    },
    SET_PERIOD: (state, payload) => {
      return {
        ...state,
        period: payload
      };
    }
  },
  persistConfig
);
