import moment from 'moment';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getSiteUptime } from 'redux/actions/jobsActions';
import selectors from 'redux/selectors/selectors';
import { InfoBlock, InfoBlockFooterBase, InfoBlockValueSmall } from 'shared/components';

export function BlockUptimeFeature({ jobId }) {
  const range = useSelector(selectors.sitePeriod);
  const rangeFormatted = useSelector(selectors.sitePeriodFormatted);
  const uptime = useSelector(selectors.siteUptimePercentage);

  useEffect(() => {
    getSiteUptime({
      job_id: jobId, 
      startDate: moment(range.start).toISOString(), 
      endDate: moment(range.end).toISOString(),
    });
  }, [range])

  return (
    <InfoBlock
      thin
      autoHeight
      title="Uptime"
      value={<InfoBlockValueSmall value={`${uptime}%`} />}
      footer={
        <InfoBlockFooterBase>
          Range selected {rangeFormatted?.start} - {rangeFormatted?.end}
        </InfoBlockFooterBase>
      }
    />
  );
}
