import { applyMiddleware, compose, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import rootReducer from '../reducers/rootReducer';

export const history = createBrowserHistory();

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })
    : compose;

function configureStore(preloadedState) {
  return createStore(preloadedState, composeEnhancers(applyMiddleware(routerMiddleware(history), ReduxThunk)));
}

const store = configureStore(rootReducer(history));
const persistor = persistStore(store);

export { store, persistor };
