import React, { useEffect, useRef, useState } from 'react';
import { action } from '../../../redux/lib/api';
import { useSelector } from 'react-redux';
import formatPhoneNumber from '../../lib/formPhoneNumber';
import validateEmail from '../../lib/validateEmail';
import { HelpTooltip } from '../help/help-tooltip';

const stableObject = {};
const stableArray = [];

const requiredMessages = {
  email: 'Please enter an email address',
  name: 'Please enter your name',
  password: 'Please enter a password'
};

const validationMessages = {
  email: 'Please enter a valid email address',
  password: 'Password must be at least 8 characters long'
};

const formatValue = (value, format) => {
  if (format === 'camelCase') {
    value = String(value)
      .replace(/\s+/g, '')
      .replace(/[^0-9A-Za-z]/g, '');
    value = (value[0] || '').toLowerCase() + (value || '').slice(1);
  }
  if (format === 'url') {
    value = String(value).replace(/[\s]/g, '');
  }
  if (format === 'phone') {
    value = formatPhoneNumber(undefined, value);
  }
  if (format === 'number') {
    value = Number(value);
  }
  return value;
};

const BoundInput = React.memo(
  ({
     formId,
     id,
     className,
     placeholder,
     label,
     format,
     value,
     required,
     disabled,
     autoFocus,
     type,
     autoComplete,
     onChange,
     onKeyPress,
     noLabel,
     helpText
   }) => {
    const reduxValue = useSelector(
      (state) => ((state.ui.boundForms[formId] || stableObject).state || stableObject)[id]
    );
    const validating = useSelector(
      (state) => (state.ui.boundForms[formId] || stableObject).validating
    );
    const reset = useSelector((state) => (state.ui.boundForms[formId] || stableObject).reset);
    const invalid = useSelector((state) =>
      ((state.ui.boundForms[formId] || stableObject).invalids || stableArray).find(
        (n) => n.id === id
      )
    );
    const empty = useSelector((state) =>
      ((state.ui.boundForms[formId] || stableObject).empties || stableArray).find((n) => n === id)
    );
    const inputRef = useRef(null);
    const formGroupRef = useRef(null);
    const [showEmptyErrors, setShowEmptyErrors] = useState(false);

    const valid = invalid === undefined;
    let displayValue = formId !== undefined ? reduxValue : value;
    const requiredMessage =
      requiredMessages[format] || `Please enter a ${label || placeholder || 'value'}.`;
    const invalidMessage =
      (invalid || {}).message || validationMessages[format] || 'Please enter another value.';

    useEffect(() => {
      if (invalid && formGroupRef.current) {
        formGroupRef.current.classList.add('shake-horizontal');
        setTimeout(() => {
          formGroupRef.current.classList.remove('shake-horizontal');
        }, 1200);
      }
    }, [invalid]);

    const setValid = (valid) => {
      action(!valid ? 'uiSetFormInvalidField' : 'uiRemoveFormInvalidField', {
        formId,
        fieldId: id
      });
    };

    const setEmpty = (empty) => {
      action(empty ? 'uiSetFormEmptyField' : 'uiRemoveFormEmptyField', {
        formId,
        fieldId: id
      });
    };

    const validate = (opts = {}) => {
      let valid = true;
      const empty = !reduxValue && required;
      setEmpty(empty);
      if (empty) {
        if (opts.soft !== true) {
          setShowEmptyErrors(true);
        }
        return;
      }
      if (format === 'email' && reduxValue) {
        valid = validateEmail(reduxValue);
      }
      if (format === 'password' && reduxValue) {
        valid = reduxValue.length >= 8 && reduxValue.length <= 300;
      }
      setValid(valid);
    };

    const handleChange = (e) => {
      let value = formatValue(e.target.value, format);
      if (formId && id) {
        action('uiPatchFormState', {
          formId: formId,
          state: { [id]: value }
        });
        const empty = !value && required;
        setEmpty(empty);
      }
      if (typeof onChange === 'function') {
        onChange(e);
      }
      setValid(true);
      setShowEmptyErrors(false);
    };

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        handleChange(e);
      }
    };

    const handleBlur = () => {
      validate({ soft: true });
    };

    useEffect(() => {
      if (validating === true) {
        validate();
      }
    }, [validating]);

    useEffect(() => {
      validate({ soft: true });
    }, [required]);

    useEffect(() => {
      validate({ soft: true });
    }, [reset]);

    const showErrors = !valid || (showEmptyErrors && empty);

    return (
      <div
        className={`bound-input ${className || ''}`}
        ref={formGroupRef}>
        <div className="control-label">
          {!!label && <div className={'label'}>{label} </div>}

          {!!helpText && (
            <div className="control-help">
              <HelpTooltip text={helpText} />
            </div>
          )}
        </div>

        <input
          ref={inputRef}
          name={id}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          onBlur={handleBlur}
          disabled={disabled}
          as={type === 'textarea' ? 'textarea' : undefined}
          type={type}
          placeholder={placeholder ?? ''}
          spellCheck={type === 'textarea'}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onKeyPress={onKeyPress}
          value={displayValue || ''}
        />
        {showErrors && (
          <div className="invalid-feedback">{!displayValue ? requiredMessage : invalidMessage}</div>
        )}
      </div>
    );
  }
);

export default BoundInput;
