import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';
import { InfoBlock } from 'shared/components';
import { InfoBlockFooterBase } from 'shared/components/info-block/info-block-footer-base';
import { InfoBlockValueSmall } from 'shared/components/info-block/info-block-value-small';
import { getUiDateShort } from 'shared/lib/dates';

export function BlockIncidentsFeature({ jobId }) {
  const incidents = useSelector(selectors.monitorEventsByJobId(jobId)) || [];
  const incidentsCount = incidents.length;
  const lastUpdate = incidents[0]?.started_at;
  return (
    <InfoBlock
      thin
      autoHeight
      title="Incidents"
      value={<InfoBlockValueSmall value={incidentsCount} />}
      footer={<InfoBlockFooterBase>Last Update {getUiDateShort(lastUpdate)}</InfoBlockFooterBase>}
    />
  );
}
