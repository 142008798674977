import styled from 'styled-components';

const Container = styled.div`
  padding: 6px;
  cursor: pointer;
  &:hover {
    background-color: #06abc1;
  }
`;

export function DropdownItem({ children }) {
  return <Container>{children}</Container>;
}
