import { Table } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import selectors from 'redux/selectors/selectors';
import styled from 'styled-components';
import { Body, Container, Head, Title } from '../../shared/styled/styled';
import { getMonitorEvents } from 'redux/actions/jobsActions';
import { getUiDateTimeShort } from 'shared/lib/dates';
import secondsToTime from 'shared/lib/secondsToTime';
import { useEffect, useState } from 'react';

const IncidentTable = styled.div`
  width: 100%;
  padding: 36px;
`;

export function SiteIncidentsTableFeature({ jobId }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const events = useSelector(selectors.monitorEventsByJobIdDesc(jobId));

  useEffect(() => {
    setIsLoading(true);
    getMonitorEvents({ jobId }).then(() => {
      setIsLoading(false);
    });
  }, []);

  const goToEvent = (id) => {
    navigate(`/site/${jobId}/events/${id}`);
  };

  const failureReasonMap = {
    connection: 'Connection error',
    timeout: 'Timeout',
    status: 'Status code',
    max_redirect: 'Max redirect'
  };

  const columns = [
    {
      title: 'Failure reason',
      render: (item) => {
        return <div>{failureReasonMap[item?.failure_reason] || item?.failure_reason}</div>;
      }
    },
    {
      title: 'Response code',
      dataIndex: 'response_http_code'
    },
    {
      title: 'Started',
      dataIndex: 'started_at',
      render: (item) => {
        return <div>{getUiDateTimeShort(item)}</div>;
      }
    },
    {
      title: 'Duration',
      render: (item) => {
        if (!item.ended_at) {
          return <div>Ongoing</div>;
        }
        const duration = moment(item.ended_at).diff(moment(item.started_at), 'seconds');
        const durationHuman = secondsToTime({ seconds: duration, short: true });
        return <div>{durationHuman}</div>;
      }
    }
  ];

  return (
    <>
      <Container>
        <Head>
          <Title>Incident Report</Title>
        </Head>
        <Body>
          <IncidentTable>
            <Table
              loading={isLoading}
              rowKey={(record) => record.id}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    goToEvent(record.started_by_http_job_result_id);
                  }
                };
              }}
              style={{ cursor: 'pointer' }}
              pagination={{
                hideOnSinglePage: true,
                defaultPageSize: 10,
                showSizeChanger: false,
                position: ['bottomRight']
              }}
              dataSource={events}
              columns={columns}
              scroll={{ x: '100%' }}
            />
          </IncidentTable>
        </Body>
      </Container>
    </>
  );
}
