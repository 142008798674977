import { del, get, post, put } from '../lib/api';

export const getCronMonitorDataById = (jobId) => {
  return get(`cron/job/${jobId}`, {
    action: 'GET_CRON_MONITOR_DATA',
    meta: {
      jobId
    }
  });
};

export const deleteCronMonitor = (id, callback) => {
  return del(`cron/job/${id}`, {
    action: 'DELETE_CRON_MONITOR',
    onResponse: () => {
      return {
        status: 200,
        data: {
          job_id: id
        }
      };
    },
    callback
  });
};

export function postCronMonitor(data, callback) {
  return post(`cron/job`, {
    data: {
      friendly_name: data.friendly_name,
      check_interval_seconds: data.check_interval_seconds * 60,
      group_notification_ids: data.group_notification_ids || []
    },
    action: 'CREATE_CRON_MONITOR',
    callback
  });
}

export function putCronMonitor(id, data, callback) {
  return put(`cron/job/${id}`, {
    data: {
      friendly_name: data.friendly_name,
      check_interval_seconds: data.check_interval_seconds * 60,
      group_notification_ids: data.group_notification_ids || [],
      state: data.state
    },
    action: 'UPDATE_CRON_MONITOR',
    callback
  });
}

export function getCronJobEvents(job_id) {
  return get(`cron/job/${job_id}/events`, {
    action: 'GET_CRON_JOB_EVENTS',
    meta: {
      job_id
    }
  });
}

export function getCronJobRecents(job_id) {
  return get(`cron/job/${job_id}/recent`, {
    action: 'GET_CRON_JOB_RECENTS',
    meta: {
      job_id
    }
  });
}

export function getCronJobUptimePercentage({ jobId, startDate, endDate }) {
  return get(`cron/job/${jobId}/uptime_percentage`, {
    action: 'GET_CRON_JOB_UPTIME',
    query: {
      start_date_time: startDate,
      end_date_time: endDate
    }
  });
}
