import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';
import AppBanner from 'shared/components/AppBanner';

export function BannerTrialExpired({ setShowSignupModal }) {
  const [showBanner, setShowBanner] = useState(false);
  const isTrialExpired = useSelector(selectors.isTrialExpired);

  useEffect(() => {
    setShowBanner(isTrialExpired);
  }, [isTrialExpired]);

  return (
    <AppBanner show={showBanner}>
      {isTrialExpired && (
        <div className="trial-badge-container">
          <div className="trial-badge">
            <div className="trial-badge__text">
              <span>Your trial period expired! You need to upgrade subscription.</span>
            </div>
            <div
              className="trial-badge__button"
              onClick={() => setShowSignupModal(true)}>
              <span>Sign Up</span>
            </div>
          </div>
        </div>
      )}
    </AppBanner>
  );
}
