import { SignUpFromInviteButtonFeature } from '../../features/site-up-from-invite-button/sign-up-button';
import { SignUpFromInviteFormFeature } from '../../features/sign-up-from-invite-form/sign-up-from-invite-form';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'shared/components/Modal';
import ModalBody from 'shared/components/ModalBody';
import useBoundForm from 'shared/hooks/useBoundForm';

const getInitialState = (email) => ({
  account_id: '',
  invite_code: '',
  name: '',
  email: email || '',
  password: ''
});

export function SignUpFromInviteWidget({ invite_code, account_id, email }) {
  const wrapperRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading] = useState(false);
  const navigate = useNavigate();
  const form = useBoundForm({ initialState: getInitialState(email) });

  const goToLogin = () => {
    navigate('/auth/login');
  };

  return (
    <>
      <Modal show={success}>
        <ModalBody>
          <h2 style={{ textAlign: 'center' }}>Success</h2>
          <div style={{ textAlign: 'center' }}>You are successfully Signed Up.</div>
        </ModalBody>

        <div className={'footer'}>
          <button
            className={'primary'}
            onClick={goToLogin}>
            Ok
          </button>
        </div>
      </Modal>
      <div
        className={`sign-up-wrapper`}
        ref={wrapperRef}>
        <div className={'heading'}>You're invited to join</div>
        <div className={`subheading ${errorMessage ? 'error' : ''} ${loading ? 'submitting' : ''}`}>
          {loading ? 'Creating user...' : errorMessage || `Create your user now`}
        </div>
        <SignUpFromInviteFormFeature form={form} />
        <SignUpFromInviteButtonFeature
          form={form}
          invite_code={invite_code}
          account_id={account_id}
          onSuccess={() => {
            if (!errorMessage) {
              setSuccess(true);
            }
          }}
          onError={(error) => {
            setErrorMessage(error);
          }}
        />
        <div className={'alt-link'} style={{ display: 'flex' }}>
          <span>By clicking Sign Up, you agree to our
            <div style={{ paddingTop: '4px' }}>
              <span>
              <a
                href={'https://siteqwality.com/service-agreement/'}
                target={'_blank'}>Terms and Conditions
              </a> &
              <a
                href={'https://siteqwality.com/privacy-policy/'}
                target={'_blank'}>Privacy Policy
              </a>.
                </span>
            </div>
          </span>
        </div>
      </div>
    </>
  );
}
