import { useEffect } from 'react';

export const useHandleClickOutside = (ref, fn) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current?.contains(event.target)) {
        fn(event);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref]);
};
