import React, { useState } from 'react';
import { Search } from 'shared/components/search/search';
import { useDebounce } from 'shared/hooks/useDebounce';
import { listMonitors } from '../../redux/actions/monitorsActions';

export function SiteListSearchFeature() {
  const [isLoading, setIsLoading] = useState(false);

  const runSearch = (query) => {
    setIsLoading(true);
    listMonitors({ searchText: query }).then(() => {
      setIsLoading(false);
    });
  };

  const searchDebounced = useDebounce({
    callback: runSearch,
    delay: 400,
  });

  return (
    <Search
      loading={isLoading}
      placeholder="Search Sites"
      setValue={searchDebounced}
    />
  );
}
