import { Badge } from 'antd';

const statusColorMap = {
  success: '#26AF74',
  failed: '#E8403E',
  Default: '#8e8e8e'
};

const stateColorMap = {
  active: '#26AF74',
  paused: '#8e8e8e',
  Default: '#8e8e8e'
};

export const getStateBadge = (state) => {
  return (<Badge
    count={state}
    style={{
      backgroundColor: stateColorMap[state] || stateColorMap.Default,
      fontWeight: 'bold',
      fontSize: '12px',
      textTransform: 'capitalize'
    }}
  />);
};

export const getStatusBadge = (status) => {
  return (<Badge
    count={status}
    style={{
      backgroundColor: statusColorMap[status] || statusColorMap.Default,
      fontWeight: 'bold',
      fontSize: '12px',
      textTransform: 'capitalize'
    }}
  />);
};