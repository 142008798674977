import React, { useState } from 'react';
import { Input, Spin } from 'antd';
import { Icon } from 'shared/components';
import { LoadingOutlined } from '@ant-design/icons';

export function Search({ value = '', setValue, placeholder, onSubmit, autoFocus, width, loading }) {
  const [searchValue, setSearchValue] = useState(value);
  const onValueChange = (e) => {
    const value = e.target.value || '';
    setSearchValue(value);
    setValue?.(value);
  };

  return (
    <div className="app-search">
      <div className="list-search__icon">
        {loading ? (
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 16 }}
                spin
              />
            }
          />
        ) : (
          <Icon
            name="search"
            style={{ marginTop: 3 }}
          />
        )}
      </div>
      <div className="list-search__input">
        <Input
          autoFocus={autoFocus}
          className="list-search__input"
          value={searchValue}
          onChange={onValueChange}
          onKeyDown={onSubmit}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}
