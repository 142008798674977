import { SignUpButtonFeature } from 'features/sign-up-button/sign-up-button';
import { SignUpFormFeature } from 'features/sign-up-form/sign-up-form';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useBoundForm from 'shared/hooks/useBoundForm';
import { initApp } from '../../redux/actions/uiActions';
import { notification } from 'antd';
import { Turnstile } from '@marsidev/react-turnstile';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { post } from '../../redux/lib/api';
import setDeep from '../../shared/lib/setDeep';
import jwtDecode from 'jwt-decode';
import { setToken } from '../../shared/lib/authHelper';

const getInitialState = () => ({
  name: '',
  email: '',
  password: ''
});

export function SignUpWidget() {
  const wrapperRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading] = useState(false);
  const [allowSignupButton, setAllowSignupButton] = useState(false);
  const [googleBtnWidth, setGoogleBtnWidth] = React.useState('400');
  const navigate = useNavigate();
  const form = useBoundForm({ initialState: getInitialState() });

  useEffect(() => {
    const handleResize = () => {
      // set button width based on screen size
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        setGoogleBtnWidth('300');
      } else {
        setGoogleBtnWidth('400');
      }
    };

    // set initial size and listen for window resize events
    handleResize();
    window.addEventListener('resize', handleResize);

    // cleanup
    return () => window.removeEventListener('resize', handleResize);

  }, []);

  return (
    <GoogleOAuthProvider clientId="115134820333-qkl7g6kc46ejsrmcuqg4bag6nmg12apg.apps.googleusercontent.com">
      <div
        className={`sign-up-wrapper`}
        ref={wrapperRef}>
        <div className={'heading'}>Get started</div>
        <div
          className={`subheading ${errorMessage ? 'error' : ''} ${loading ? 'submitting' : ''}`}>{loading ? 'Creating account...' : errorMessage || `Create your account now`}</div>
        <SignUpFormFeature form={form} />
        <SignUpButtonFeature
          form={form}
          disabled={!allowSignupButton}
          onSuccess={() => {
            notification.success({
              message: 'Success',
              description: 'You are successfully Signed Up!'
            });
            initApp().then(() => {
              navigate('/');
              form.reset(getInitialState());
            });
          }}
          onError={(error) => {
            setErrorMessage(error);
          }}
        />
        <div className={'alt-link'}>
          <span>Already have an account?</span>
          <Link to={'/auth/login'}>Login</Link>
        </div>

        {/* Create a horizontal row with the text "or" in the middle */}
        <div style={{ display: 'flex', alignItems: 'center', margin: '32px 0' }}>
          <div style={{ flex: 1, height: '1px', backgroundColor: '#757D84FF' }} />
          <div style={{ margin: '0 16px', color: '#757D84FF', fontSize: '13px', fontWeight: 600 }}>OR</div>
          <div style={{ flex: 1, height: '1px', backgroundColor: '#757D84FF' }} />
        </div>

        <div className={'google-login-container'}>
          <GoogleLogin
            shape={'pill'}
            text={'signup_with'}
            context={'signup'}
            size={'large'}
            width={googleBtnWidth}
            onSuccess={credentialResponse => {
              setAllowSignupButton(false);
              post('signup/google', {
                data: {
                  credential: credentialResponse.credential,
                  turnstile_response: form.state.turnstile_response,
                  promotekit_referral: window.promotekit_referral
                },
                onResponse: (response) => {
                  return setDeep(response, 'data.data', {
                    ...(response.data || {}).data,
                    decoded: jwtDecode(((response.data || {}).data || {}).token)
                  });
                },
                callback: (data = {}, err) => {
                  if (err?.message) {
                    setAllowSignupButton(true);
                    setErrorMessage(err.message);
                    return;
                  }

                  if (data.token) {
                    setToken(data.token);

                    notification.success({
                      message: 'Success',
                      description: 'You are successfully Signed Up!'
                    });
                    initApp().then(() => {
                      navigate('/');
                      form.reset(getInitialState());
                    });
                  }
                },
                action: 'POST_LOGIN'
              });
            }}
          />
        </div>

        <div className={'alt-link'} style={{ display: 'flex' }}>
          <span>By clicking sign up, you agree to our
            <div style={{ paddingTop: '4px' }}>
              <span>
              <a
                href={'https://siteqwality.com/service-agreement/'}
                target={'_blank'}>Terms and Conditions
              </a> &
              <a
                href={'https://siteqwality.com/privacy-policy/'}
                target={'_blank'}>Privacy Policy
              </a>.
                </span>
            </div>
          </span>
        </div>
        <div style={{ marginTop: '24px' }} />
        <Turnstile
          // https://developers.cloudflare.com/turnstile/troubleshooting/testing/
          siteKey="0x4AAAAAAAbEj4Z98iOT4kw7" // 2x00000000000000000000AB
          onError={(error) => {
            console.error(error);
            setErrorMessage('Please try again later.');
            setAllowSignupButton(false);
          }}
          onExpire={() => {
            setAllowSignupButton(false);
          }}
          onSuccess={(token) => {
            setAllowSignupButton(true);
            setErrorMessage('');
            form.setState({ turnstile_response: token, ...form.state });
          }}
          style={{ width: '100%' }}
        />
      </div>
    </GoogleOAuthProvider>
  );
}
