export const marksInterval = {
  1: '1m',
  2: '2m',
  3: '3m',
  4: '4m',
  5: '5m',
  6: '30m',
  7: '1h',
  8: '2h',
  9: '3h',
  10: '6h',
  11: '12h',
  12: '24h'
};

export const valuesInterval = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 30,
  7: 60,
  8: 120,
  9: 180,
  10: 320,
  11: 640,
  12: 1440
};

export const marksTimeout = {
  1: '1s',
  10: '10s',
  20: '20s',
  30: '30s',
  40: '40s',
  50: '50s',
  60: '60s'
};

export const mapBackInterval = (value) => {
  let result = '';
  Object.entries(valuesInterval).forEach(([k, v]) => {
    if (value === v) {
      result = k;
    }
  });
  return result;
};
