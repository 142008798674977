import LogRocket from 'logrocket';
import jwtDecode from 'jwt-decode';

export function getToken() {
  return localStorage.getItem(`token`);
}

export function setToken(token) {
  try {
    const jwt = jwtDecode(token);
    LogRocket.identify(jwt.user_id, {
      email: jwt.email,
      account_id: jwt.account_id
    });
  } catch (_) {
  }
  localStorage.setItem(`token`, token);
}
