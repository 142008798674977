import { store } from './configureStore';

export const dispatchHelper = (action, dispatch = store.dispatch) => {
  return (response, ...additionalArgs) => {
    if (response === undefined) {
      return;
    }

    const callbacks = additionalArgs.filter((n) => typeof n === 'function');
    const meta = additionalArgs.find((n) => typeof n === 'object');
    const handle = ({ type, payload, error, middleware = {} }) => {
      type = type || action;
      type = error ? `${type}_FAIL` : `${type}_SUCCESS`;
      if (typeof payload === 'object' && payload.data && meta) {
        payload.meta = meta;
      }
      dispatch({ type, payload, ...middleware });
      callbacks.forEach((callback) => {
        let data = error ? undefined : (payload || {}).data || payload;
        let err = error ? payload : undefined;
        callback(null, data, err);
      });
      if (error) {
        const status = (response || {}).status;
        const url = ((response || {}).config || {}).url;
        console.info(`${error || ''}: %c${status}: %c${url}`, 'color: red', 'color: black', response);
      }
    };

    if (Array.isArray(response)) {
      const isSuccess = response.filter((n) => n.status !== 200).length < 1;
      if (isSuccess) {
        handle({ payload: response });
      }
      return;
    }

    if (!response.status) {
      handle({
        payload: response.data,
        error: 'No response status',
      });
      return;
    }

    if (response.status >= 400) {
      handle({
        payload: response.data,
        error: 'Authorization error',
      });
      return;
    }

    if (response.status >= 200 && response.status < 400) {
      handle({
        payload: response.data,
        middleware: response.middleware,
      });
    }
  };
};

export default dispatchHelper;
