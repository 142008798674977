import React, { useState } from 'react';
import BoundFormGroup from './boundForm/BoundFormGroup';
import Modal from './Modal';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import { Button } from 'antd';
import { postContactSupport } from '../../redux/actions/userActions';
import { notification } from 'antd';

// Turn this feature off for all pages by setting SHOW_FORM to false
const SHOW_FORM = true;

const FloatingContactForm = () => {
  const [showForm, setShowForm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  const toggleForm = () => {
    setShowForm(!showForm);
    setSubmitting(false);
  };

  const handleKeyPress = (e) => {
    setMessage(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    // post form data to the server
    postContactSupport({
      message
    }, (data, err) => {
      setSubmitting(false);
      if (!err) {
        notification.success({
          message: 'Success',
          description: 'We\'ll be in touch!'
        });
        setMessage('');
        toggleForm();
      }
    });
  };

  return (SHOW_FORM &&
    <div>
      <button id="floating-button" onClick={toggleForm}></button>
      {(showForm && (
        <Modal
          show={showForm}
          onHide={toggleForm}
          maxWidth={600}>
          <div className={'inner-wrapper'}>
            <ModalHeader title="Contact Us" />
            <ModalBody>
              <BoundFormGroup
                formId={'contact-form'}
                fields={[
                  [{
                    id: 'message',
                    placeholder: 'Please type your message here...',
                    autoFocus: true,
                    type: 'textarea',
                    required: true,
                    rows: 8,
                    label: 'Message',
                    onChange: handleKeyPress
                  }]
                ]}
              >
              </BoundFormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                loading={submitting}
                className={'save primary'}
                onClick={handleFormSubmit}
                disabled={submitting || message.length < 1}>
                Submit
              </Button>
              <button
                className={'cancel'}
                onClick={() => setShowForm(false)}>
                Cancel
              </button>
            </ModalFooter>
          </div>
        </Modal>
      ))}
    </div>
  );
};

export default FloatingContactForm;
