import { NotificationGroupTableFeature } from 'features/notifications/notification-group-table';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getNotificationGroups } from 'redux/actions/notificationsActions';
import { PageWithLoading } from 'shared/components';
import { AddNotificationGroupWidget } from 'widgets/notifications/add-notification-group.widget';
import { ManageNotificationWidget } from 'widgets/notifications/manage-notification.widget';
import FloatingContactForm from '../../shared/components/FloatingContactForm';
import { getIntegrations } from '../../redux/actions/integrationsActions';

export function NotificationsPage() {
  const { actionId, arg1, arg2 } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const addGroup = actionId === 'add';
  const editGroup = arg1 === 'edit';
  const addNotification = arg1 === 'add-notification';
  const editNotification = arg1 === 'edit-notification';

  useEffect(() => {
    setIsLoading(true);
    getNotificationGroups().then(() => {
      getIntegrations().then(() => {
        setIsLoading(false);
      });
    });
  }, []);

  return (
    <PageWithLoading
      isLoading={isLoading}
      actions={
        <AddNotificationGroupWidget
          id={actionId}
          edit={editGroup}
          add={addGroup}
        />
      }>
      <NotificationGroupTableFeature edit={editGroup} />
      <ManageNotificationWidget
        notificationId={arg2}
        notificationGroupId={actionId}
        add={addNotification}
        edit={editNotification}
      />
      <FloatingContactForm />
    </PageWithLoading>
  );
}
