import createPersistedReducer from 'redux/lib/createPersistedReducer';
import mergeByProp from 'shared/lib/mergeByProp';

import * as R from 'ramda';
import { updateAt } from 'shared/lib/updateObjectInArrayAt';

const initialState = {
  groups: [],
  notifications: [],
};

const persistConfig = {
  key: 'notificationsReducer',
  whitelist: [],
  transforms: [],
};

export default createPersistedReducer(
  initialState,
  {
    GET_NOTIFICATION_GROUPS_SUCCESS: (state, payload) => ({
      ...state,
      groups: payload?.data,
    }),
    CREATE_NOTIFICATION_GROUP_SUCCESS: (state, payload) => ({
      ...state,
      groups: [...state.groups, payload?.data],
    }),
    UPDATE_NOTIFICATION_GROUP_SUCCESS: (state, payload) => {
      const notifications = state.groups.find(item => item.id === payload?.data?.id)?.notifications;
      return {
        ...state,
        groups: mergeByProp('id', state?.groups, [{...payload?.data, notifications,}]),
      }
    },
    DELETE_NOTIFICATION_GROUP_SUCCESS: (state, payload) => ({
      ...state,
      groups: state.groups.filter((i) => i.id !== payload?.id),
    }),

    CREATE_NOTIFICATION_SUCCESS: (state, payload) => {
      const group = R.find(R.propEq(payload?.meta?.notification_group_id, 'id'))(state.groups);
      return {
        ...state,
        groups: updateAt(payload.meta.notification_group_id, R.assoc('notifications', [...(group.notifications || []), payload.data]))(state.groups),
      };
    },

    UPDATE_NOTIFICATION_SUCCESS: (state, payload) => {
      const group = R.find(R.propEq(payload?.meta?.notification_group_id, 'id'))(state.groups);
      const notifications = updateAt(payload.meta.id, R.assoc('details', payload.data.details))(group.notifications);
      return {
        ...state,
        groups: updateAt(payload.meta.notification_group_id, R.assoc('notifications', notifications))(state.groups),
      };
    },
  },
  persistConfig
);
