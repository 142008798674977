const parseJson = (str, opts = {}) => {
  if (typeof str === 'object') {
    return str;
  }
  try {
    return JSON.parse(str);
  } catch (e) {
    return undefined;
  }
};

export default parseJson;
