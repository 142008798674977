import React from 'react';
import iconMap from 'shared/components/icon/icon-map';


export function Icon({ name, size = 15, color = '#ffffff', colorStroke, ...rest }) {
  // @ts-ignore
  const SvgIcon = iconMap[name];

  let style;
  if (size !== undefined) {
    style = { width: size, height: size };
  }

  return (
    <SvgIcon fill={color} color={color} stroke={colorStroke} style={style} height={size} width={size} {...rest} />
  );
}

