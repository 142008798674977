import { Menu } from 'antd';
import MenuItem from 'antd/es/menu/MenuItem';
import { Link, useLocation } from 'react-router-dom';
import { Icon } from 'shared/components';
import styled from 'styled-components';

const MenuItemCustom = styled.li`
    background-color: #011741;
    color: #06abc1;
    padding: 18px 12px;
    margin: 0 24px 0 46px;
    text-overflow: ellipsis;
`;

export function MainMenu({ isExpiredTrial }) {
  const location = useLocation();
  return (
    <>
      <Menu
        selectedKeys={[location.pathname.replace(/\//g, '')]}
        defaultSelectedKeys={[location.pathname.replace(/\//g, '')]}>
        <MenuItem
          disabled={isExpiredTrial}
          key="dashboard"
          icon={<Icon name="dashboard" />}>
          <Link
            disabled={isExpiredTrial}
            to="/dashboard">
            Dashboard
          </Link>
        </MenuItem>
        {location.pathname.includes('/site/') &&
          <MenuItemCustom key="site">Site</MenuItemCustom>}
        {location.pathname.includes('/cron/') &&
          <MenuItemCustom key="cron">Cron</MenuItemCustom>}
        <MenuItem
          disabled={isExpiredTrial}
          key="notifications"
          icon={
            <Icon
              name="envelope"
              color="#fff"
              size={15}
            />
          }>
          <Link
            disabled={isExpiredTrial}
            to="/notifications">
            Notifications
          </Link>
        </MenuItem>
        <MenuItem
          disabled={isExpiredTrial}
          key="users"
          icon={<Icon name="group" />}>
          <Link
            disabled={isExpiredTrial}
            to="/users">
            Users
          </Link>
        </MenuItem>
        <MenuItem
          key="account"
          icon={<Icon name="accounts" />}>
          <Link to="/account">Account</Link>
        </MenuItem>
        <MenuItem
          disabled={isExpiredTrial}
          key="integrations"
          icon={<Icon name="integration" color="#ffffff" />}>
          <Link to="/integrations">Integrations</Link>
        </MenuItem>
      </Menu>
    </>
  );
}
