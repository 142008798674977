import createPersistedReducer from 'redux/lib/createPersistedReducer';
import mergeByProp from '../../shared/lib/mergeByProp';

const initialState = {
  http: {
    monitors: [],
    currentDetails: {}
  },
  cron: {
    monitors: [],
    currentDetails: {},
    currentJobEvents: [],
    currentJobRecents: [],
    currentUptimePercentage: {}
  },
  combinedMonitorsSorted: [],
  filters: {
    searchStatus: '',
    searchState: '',
    searchText: '',
    limit: 10,
    offset: 0
  },
  totals: {
    total: 0,
    online: 0,
    down: 0,
    paused: 0
  }
};

const persistConfig = {
  key: 'monitorsReducer',
  whitelist: [],
  transforms: []
};

function sortMonitors(httpMonitors, cronMonitors) {
  const combinedMonitors = [...httpMonitors, ...cronMonitors];
  combinedMonitors.sort((a, b) => {
    if (a.current_status === 'failed' && b.current_status !== 'failed') {
      return -1;
    }
    if (a.current_status !== 'failed' && b.current_status === 'failed') {
      return 1;
    }
    return a.friendly_name?.localeCompare(b.friendly_name);
  });
  return combinedMonitors;
}

export default createPersistedReducer(
  initialState,
  {
    // ---------------
    // http monitors
    GET_HTTP_MONITOR_DATA_SUCCESS: (state, payload) => {
      return {
        ...state,
        http: {
          ...state.http,
          currentDetails: payload?.data
        }
      };
    },
    CREATE_HTTP_MONITOR_SUCCESS: (state, payload) => {
      return {
        ...state,
        http: {
          ...state.http,
          monitors: [...state.http.monitors, payload?.data]
        },
        combinedMonitorsSorted: sortMonitors(
          [...state.http.monitors, payload?.data],
          state.cron.monitors
        ),
        totals: {
          ...state.totals,
          total: state.totals.total + 1,
          online: state.totals.online + 1
        }
      };
    },
    UPSERT_HTTP_MONITOR_SUCCESS: (state, payload) => {
      return {
        ...state,
        http: {
          ...state.http,
          monitors: mergeByProp('id', state?.http.monitors, [payload?.data]),
          currentDetails: payload?.data
        },
        combinedMonitorsSorted: sortMonitors(
          mergeByProp('id', state?.http.monitors, [payload?.data]),
          state.cron.monitors
        )
      };
    },
    DELETE_HTTP_MONITOR_SUCCESS: (state, payload) => {
      return {
        ...state,
        http: {
          ...state.http,
          monitors: state.http.monitors.filter((monitor) => monitor.id !== payload?.data?.id)
        },
        combinedMonitorsSorted: sortMonitors(
          state.http.monitors.filter((monitor) => monitor.id !== payload?.data?.id),
          state.cron.monitors
        ),
        totals: {
          ...state.totals,
          total: state.totals.total - 1
        }
      };
    },

    // ---------------
    // cron monitors
    GET_CRON_MONITOR_DATA_SUCCESS: (state, payload) => {
      return {
        ...state,
        cron: {
          ...state.cron,
          currentDetails: payload?.data
        }
      };
    },
    CREATE_CRON_MONITOR_SUCCESS: (state, payload) => {
      return {
        ...state,
        cron: {
          ...state.cron,
          monitors: [...state.cron.monitors, payload?.data]
        },
        combinedMonitorsSorted: sortMonitors(state.http.monitors, [
          ...state.cron.monitors,
          payload?.data
        ]),
        totals: {
          ...state.totals,
          total: state.totals.total + 1,
          online: state.totals.online + 1
        }
      };
    },
    UPDATE_CRON_MONITOR_SUCCESS: (state, payload) => {
      return {
        ...state,
        cron: {
          ...state.cron,
          monitors: mergeByProp('id', state?.cron.monitors, [payload?.data]),
          currentDetails: payload?.data
        },
        combinedMonitorsSorted: sortMonitors(
          state.http.monitors,
          mergeByProp('id', state?.cron.monitors, [payload?.data])
        )
      };
    },
    DELETE_CRON_MONITOR_SUCCESS: (state, payload) => {
      return {
        ...state,
        cron: {
          ...state.cron,
          monitors: state.cron.monitors.filter((monitor) => monitor.id !== payload?.data?.job_id)
        },
        combinedMonitorsSorted: sortMonitors(
          state.http.monitors,
          state.cron.monitors.filter((monitor) => monitor.id !== payload?.data?.job_id)
        ),
        totals: {
          ...state.totals,
          total: state.totals.total - 1
        }
      };
    },
    GET_CRON_JOB_EVENTS_SUCCESS: (state, payload) => {
      return {
        ...state,
        cron: {
          ...state.cron,
          currentJobEvents: payload?.data
        }
      };
    },
    GET_CRON_JOB_RECENTS_SUCCESS: (state, payload) => {
      return {
        ...state,
        cron: {
          ...state.cron,
          currentJobRecents: payload?.data
        }
      };
    },
    GET_CRON_JOB_UPTIME_SUCCESS: (state, payload) => {
      return {
        ...state,
        cron: {
          ...state.cron,
          currentUptimePercentage: payload?.data
        }
      };
    },

    // ---------------
    // all monitors
    GET_MONITOR_TOTALS_SUCCESS: (state, payload) => {
      return {
        ...state,
        totals: payload?.data
      };
    },
    LIST_MONITORS_SUCCESS: (state, payload) => {
      return {
        ...state,
        http: {
          ...state.http,
          monitors: payload?.data?.http_jobs || []
        },
        cron: {
          ...state.cron,
          monitors: payload?.data?.cron_jobs || []
        },
        combinedMonitorsSorted: sortMonitors(
          payload?.data?.http_jobs || [],
          payload?.data?.cron_jobs || []
        )
      };
    },
    LIST_MORE_MONITORS_SUCCESS: (state, payload) => {
      return {
        ...state,
        http: {
          ...state.http,
          monitors: [...state.http.monitors, ...payload?.data?.http_jobs]
        },
        cron: {
          ...state.cron,
          monitors: [...state.cron.monitors, ...payload?.data?.cron_jobs]
        },
        combinedMonitorsSorted: sortMonitors(
          [...state.http.monitors, ...payload?.data?.http_jobs],
          [...state.cron.monitors, ...payload?.data?.cron_jobs]
        )
      };
    },

    // ---------------
    // filters
    SET_MONITOR_FILTERS: (state, payload) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload
        }
      };
    }
  },
  persistConfig
);
