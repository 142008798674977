import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { action } from "redux/lib/api";

export const ReduxRouteWrapper = (props) => {
    const isFixedWidth = useSelector((state) => state.ui.fixedWidth);
    const params = useParams();
  
    useEffect(() => {
      if (props.fixedWidth !== isFixedWidth) {
        action('uiSetFixedWidth', props.fixedWidth === true);
      }
    }, [props.fixedWidth, isFixedWidth]);
  
    useEffect(() => {
      action('uiSetActiveRouteId', props.routeId);
    }, [props.routeId]);
  
    useEffect(() => {
      action('SET_PARAMS', params);
    }, [params]);
  
    return <props.element {...props} />;
  };