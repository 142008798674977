import { Button } from 'antd';
import { useNavigate } from 'react-router';
import { Icon } from 'shared/components';

export function AddNotificationGroupButtonFeature() {
  const navigate = useNavigate();
  const openModal = () => {
    navigate('add');
  };
  return (
    <Button
      onClick={openModal}
      className="primary_big"
      icon={
        <Icon
        name="plus"
        size={11}
        style={{ marginTop: 3 }}
      />
      }>
      Add Notification Group
    </Button>
  );
}
