import React from 'react';
import BoundFormGroup from 'shared/components/boundForm/BoundFormGroup';

export const LoginForm = ({ form, onKeyPress }) => {
  const fields = [
    [{ id: 'email', placeholder: 'Email', format: 'email', required: true, onKeyPress: onKeyPress }], //
    [{ id: 'password', placeholder: 'Password', type: 'password', required: true, onKeyPress: onKeyPress }],
  ];

  return (
    <BoundFormGroup
      formId={form.id}
      fields={fields}
      onKey
    />
  );
};
