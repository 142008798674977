import React from 'react';
import BoundInput from './BoundInput';
import BoundSelect from './BoundSelect';
import BoundSlider from './BoundSlider';
import BoundCheckbox from './BoundCheckbox';
import BoundTextarea from './BoundTextarea';

const BoundFormGroup = ({ formId, fields, onChange, show = true, sameColumn, hasPostfix, onClickPostfix }) => {
  const fieldGroups = (fields || [])
    .filter((m) => typeof m === 'object' && m !== null)
    .map((m) =>
      m
        .filter((n) => typeof n === 'object' && n !== null && n.hidden !== true)
        .map((n) => ({
          ...n,
          type: n.type || (n.options ? 'select' : 'input')
        }))
        .map((n) => ({
          ...n,
          Component: {
            password: BoundInput,
            number: BoundInput,
            textarea: BoundTextarea,
            input: BoundInput,
            select: BoundSelect,
            slider: BoundSlider,
            checkbox: BoundCheckbox
          }[n.type]
        }))
    )
    .filter((n) => n.length > 0);

  if (!show) {
    return null;
  }

  return (
    <>
      {fieldGroups.map((group, groupIdx) => (
        <div
          className={`col${sameColumn ? '1' : group.length}`}
          key={groupIdx}>
          {group.map((field = {}, idx) => (
            <div
              className="form-control"
              key={idx}>
              <field.Component
                key={idx}
                formId={formId}
                label={field.label}
                id={field.id}
                placeholder={field.placeholder}
                options={field.options}
                autocomplete={field.autoComplete}
                onChange={field.onChange || onChange}
                defaultValue={field.defaultValue}
                disabled={field.disabled}
                autoFocus={field.autoFocus}
                autoComplete={field.autoComplete}
                required={field.required}
                type={field.type}
                freeform={field.freeform}
                format={field.format}
                marks={field.marks}
                step={field.step}
                min={field.min}
                max={field.max}
                rows={field.rows}
                tooltip={field.tooltip}
                helpText={field.helpText}
              />
              {field.postfix && (
                <div
                  onClick={() => {
                    onClickPostfix(field.id);
                  }}
                  className="input-postfix">
                  {field.postfix}
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default BoundFormGroup;
