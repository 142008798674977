import {
  RequestResetPasswordButtonFeature
} from 'features/request-reset-password-button/request-reset-password-button';
import { RequestResetPasswordFormFeature } from 'features/request-reset-password-form/request-reset-password-form';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Modal from 'shared/components/Modal';
import ModalBody from 'shared/components/ModalBody';
import useBoundForm from 'shared/hooks/useBoundForm';

const getInitialState = () => ({
  email: ''
});

export function RequestResetPasswordWidget() {
  const navigate = useNavigate();
  const form = useBoundForm({ initialState: getInitialState() });
  const wrapperRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [success, setSuccess] = useState(false);

  const goToLogin = () => {
    navigate(`/auth/login`);
  };

  return (
    <>
      <Modal show={success} title="Reset Password">
        <ModalBody>
          <h2 style={{ textAlign: 'center' }}>Email sent</h2>
          <div style={{ textAlign: 'center' }}>An email has been sent to {form.state.email} with a link to reset your
            password.
          </div>
        </ModalBody>
        <div className={'footer'}>
          <button
            className={'primary'}
            onClick={goToLogin}>
            Ok
          </button>
        </div>
      </Modal>

      <div
        className={`log-in-wrapper`}
        ref={wrapperRef}>
        <div className={'heading'}>Forgot Password?</div>
        <div className={`subheading ${errorMessage ? 'error' : ''}`}>Enter your email to reset your password</div>
        <RequestResetPasswordFormFeature form={form} />
        <RequestResetPasswordButtonFeature
          form={form}
          onSuccess={() => {
            setSuccess(true);
          }}
          onError={(error) => {
            setErrorMessage(error);
          }}
        />
      </div>
    </>
  );
}
