import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setPeriodAction } from 'redux/actions/siteActions';
import selectors from 'redux/selectors/selectors';
import { DateRangePicker, Icon } from 'shared/components';
import styled from 'styled-components';
import { Tooltip } from 'antd';

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
    width: 100%;
`;

const Title = styled.div`
    text-align: center;
    font-size: 40px;
    font-weight: 700;
`;

const UrlContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #00122c;
    border-radius: 5px;
    border: 1px solid #06abc1;
    margin-top: 20px;
`;

const UrlHeader = styled.div`
    width: 100%;
    padding: 5px 10px;
    border-radius: 5px 5px 0 0;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    background-color: #001b3a;
`;

const UrlTextContainer = styled.div`
    display: flex;
    padding: 10px;
    align-items: center;
    width: 100%;
`;

const UrlText = styled.span`
    flex-grow: 1;
    font-size: 14px;
    user-select: text;
    max-width: 300px;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }
`;

const Range = styled.div`
    text-align: center;
    margin-top: 12px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export function CronHeaderWidget() {
  const monitor = useSelector(selectors.cronMonitorDetails);
  const period = useSelector(selectors.sitePeriod);
  const [isLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [url, setUrl] = useState('');

  useEffect(() => {
    let accountId = monitor.account_id.replaceAll('-', '');
    let monitorId = monitor.id.replaceAll('-', '');
    setUrl(`https://ping.siteqwality.com/${accountId}/${monitorId}`);
  }, [monitor]);

  const handleCopy = () => {
    navigator.clipboard.writeText(url).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <Container style={{ display: 'block' }}>
      <Content>
        <Header>
          <Title>{monitor?.friendly_name}</Title>
          <UrlContainer>
            <UrlHeader>Your Cron Push URL</UrlHeader>
            <UrlTextContainer>
              <UrlText>
                {url}
              </UrlText>
              <Tooltip title={copied ? 'Copied!' : 'Copy'}>
                <Icon style={{ cursor: 'pointer' }} size={25} name={'copy'} onClick={handleCopy} />
              </Tooltip>
            </UrlTextContainer>
          </UrlContainer>
          <Range>
            <DateRangePicker
              loading={isLoading}
              defaultValue={period}
              onChange={(p) => {
                setPeriodAction(p);
              }}
            />
          </Range>
        </Header>
      </Content>
    </Container>
  );
}
