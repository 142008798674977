import { createTransform } from 'redux-persist';
import setDeep from 'shared/lib/setDeep.js';
import createPersistedReducer from '../lib/createPersistedReducer';

const initialState = {
  fixedWidth: false,
  activeRouteId: undefined,
  boundForms: {},
  mobile: document.body.clientWidth < 640,
  params: {},
  filters: {
    search: '',
  },
  isInitializing: true,
};

const filterTimeouts = {
  datePicker: 1000 * 60 * 60 * 24,
};

const persistConfig = {
  key: 'uiReducer',
  whitelist: ['filters'],
  transforms: [
    createTransform(
      (state, key) => {
        const newState = { ...state };
        if (key === 'filters') {
          for (const filterKey in filterTimeouts) {
            const diff = new Date() - new Date((state.timestamps || {})[filterKey]);
            if (diff > filterTimeouts[filterKey]) {
              delete newState[filterKey];
            }
          }
        }
        return newState;
      },
      (state) => state,
      { whitelist: 'filters' }
    ),
  ],
};

export default createPersistedReducer(
  initialState,
  {
    SET_APP_INITIALIZING: (state, payload) => ({
      ...state,
      isInitializing: payload,
    }),
    SET_PARAMS: (state, payload) => ({
      ...state,
      params: payload,
    }),

    uiSetActiveRouteId: (state, payload) => ({
      ...state,
      activeRouteId: payload,
    }),

    uiSetFormInvalidField: (state, payload) => ({
      ...setDeep(state, `boundForms.${payload.formId}.invalids`, (n) => [...(n || [])].filter((n) => n.id !== payload.fieldId).concat({ id: payload.fieldId, message: payload.message })),
    }),
    uiRemoveFormInvalidField: (state, payload) => ({
      ...setDeep(state, `boundForms.${payload.formId}.invalids`, (n) => [...(n || [])].filter((n) => n.id !== payload.fieldId)),
    }),
    uiSetFormEmptyField: (state, payload) => ({
      ...setDeep(state, `boundForms.${payload.formId}.empties`, (n) => [...(n || [])].filter((n) => n !== payload.fieldId).concat(payload.fieldId)),
    }),
    uiRemoveFormEmptyField: (state, payload) => ({
      ...setDeep(state, `boundForms.${payload.formId}.empties`, (n) => [...(n || [])].filter((n) => n !== payload.fieldId)),
    }),
    uiPatchFormState: (state, payload) => ({
      ...setDeep(state, `boundForms.${payload.formId}.state`, (n) => ({
        ...n,
        ...payload.state,
      })),
    }),
    uiPutFormState: (state, payload) => ({
      ...setDeep(state, `boundForms.${payload.formId}.state`, (n) => ({
        ...payload.state,
      })),
    }),
    uiClearFormState: (state, formId) => ({
      ...setDeep(state, `boundForms.${formId}`, (n) => null),
    }),
    uiToggleFormValidation: (state, payload) => ({
      ...setDeep(state, `boundForms.${payload.formId}.validating`, (n) => payload.validating),
    }),
    uiToggleFormReset: (state, payload) => ({
      ...setDeep(state, `boundForms.${payload.formId}.reset`, (n) => payload.reset),
    }),
    uiPatchFilters: (state, payload) => ({
      ...state,
      filters: {
        ...state.filters,
        ...payload,
        timestamps: {
          ...(state.filters.timestamps || {}),
          ...Object.fromEntries(Object.entries(payload).map((n) => [n[0], new Date()])),
        },
      },
    }),
    uiResetPatchFilters: (state) => ({ ...state, filters: initialState.filters }),
    uiSetFixedWidth: (state, bool) => ({ ...state, fixedWidth: bool }),
  },
  persistConfig
);
