import React from 'react';
import logo from './logo.png';
import './logo.scss';

export const Logo = ({ width = 180 }) => {
  return (
    <div
      className="app-logo"
      style={{ width: width+'px' }}>
      <img
        src={logo}
        alt="logo"
      />
    </div>
  );
};
