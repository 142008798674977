import { Head, Title } from 'shared/styled/styled';
import { Footer, Item, Key, Value } from './styled';
import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';

export function BlockBillingDetails() {
  const userFullName = useSelector(selectors.userFullName);
  const email = useSelector(selectors.userEmail);
  return (
    <div>
      <Head>
        <Title>Billing Details</Title>
      </Head>
      <Item>
        <Key>Name</Key>
        <Value>{userFullName}</Value>
      </Item>
      <Item>
        <Key>Email</Key>
        <Value>{email}</Value>
      </Item>
      <Item>
        <Key>Country</Key>
        <Value>USA</Value>
      </Item>
      <Footer>
      </Footer>
    </div>
  );
}
