import { Button, notification, Tooltip } from 'antd';
import { Footer, Item, Key, Value, ValueCapitalized } from './styled';
import { Head, Title } from 'shared/styled/styled';
import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';
import { getUiDateShort } from 'shared/lib/dates';
import { useState } from 'react';
import { getStripeUrl } from 'redux/actions/eCommerceActions';
import { Preloader } from 'shared/components/preloader/Preloader';
import ModalHeader from 'shared/components/ModalHeader';
import ModalBody from 'shared/components/ModalBody';
import ModalFooter from 'shared/components/ModalFooter';
import Modal from 'shared/components/Modal';

export function BlockCurrentPlan() {
  const accountInfo = useSelector(selectors.accountInfo);
  const usage = useSelector(selectors.usage);
  const subscriptionRenewalDate = useSelector(selectors.subscriptionRenewalDate);
  const planName = useSelector(selectors.planName);
  const [isLoading, setIsLoading] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);

  const handleSignup = () => {
    setIsLoading(true);
    getStripeUrl()
      .then((request) => {
        window.location.href = request.url;
      })
      .catch((e) => {
        notification.error({
          key: 'response.id',
          message: 'Error occurred',
          description: e.message
        });
        setShowSignupModal(false);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div>
        {isLoading && <Preloader over />}
        <Head>
          <Title>Plan</Title>
        </Head>
        <Item>
          <Key>Account ID</Key>
          <Value>
            <Value>{accountInfo?.id}</Value>
          </Value>
        </Item>
        <Item>
          <Key>Current Plan</Key>

          <Value>
            <ValueCapitalized>{planName}</ValueCapitalized>
          </Value>
        </Item>
        <div>
          <Item>
            <Key>Sites</Key>
            <Value>
              <Tooltip title="Used/Limit" placement="top">
                {usage.sites_usage}/{usage.sites_limit}
              </Tooltip>
            </Value>
          </Item>
          <Item>
            <Key>SMS</Key>
            <Value>
              <Tooltip title="Used/Limit" placement="top">
                {usage.sms_usage}/{usage.sms_limit}
              </Tooltip>
            </Value>
          </Item>
          <Item>
            <Key>Users</Key>
            <Value>
              <Tooltip title="Used/Limit" placement="top">
                {usage.users_usage}/{usage.users_limit}
              </Tooltip>
            </Value>
          </Item>
        </div>
        <Item>
          <Key>Renewal Date</Key>
          <Value>{getUiDateShort(subscriptionRenewalDate)}</Value>
        </Item>
        <Footer>
          <Button
            onClick={() => setShowSignupModal(true)}
            className="primary">
            Manage Plan
          </Button>
        </Footer>
      </div>
      <Modal
        show={showSignupModal}
        onHide={() => setShowSignupModal(false)}
        maxWidth={600}>
        <div className={'inner-wrapper'}>
          <ModalHeader title="Paid Plan Subscription" />
          <ModalBody>
            <div style={{ fontSize: '19px', textAlign: 'center' }}>
              You will be redirected to Stripe to pay your subscription. Do you want to proceed?
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className={'save primary'}
              onClick={handleSignup}>
              Proceed
            </button>
            <button
              className={'cancel'}
              onClick={() => setShowSignupModal(false)}>
              Cancel
            </button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
}
