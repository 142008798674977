import Modal from '../../shared/components/Modal';
import ModalBody from '../../shared/components/ModalBody';
import ModalFooter from '../../shared/components/ModalFooter';
import { MonitorModalTitle } from './monitor-modal-title';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import selectors from '../../redux/selectors/selectors';
import { useState } from 'react';
import useBoundForm from '../../shared/hooks/useBoundForm';
import { marksInterval, valuesInterval } from './mappings';
import BoundFormGroup from '../../shared/components/boundForm/BoundFormGroup';
import { SelectNotificationGroup } from '../notifications/select-notification-group';
import { postCronMonitor, putCronMonitor } from '../../redux/actions/cronJobsActions';

const getInitialState = (monitor = {}) => ({
  friendly_name: monitor.friendly_name || '',
  check_interval_seconds: parseFloat(monitor?.check_interval_seconds || 300) / 60
});

const CronModal = ({ show, adding, cronId }) => {
  const navigate = useNavigate();
  const monitor = useSelector(selectors.cronMonitorDetails);
  const [notifications, setNotifications] = useState(monitor?.group_notifications ?? []);
  const form = useBoundForm({ initialState: getInitialState(monitor) });

  const fields = [
    [{ id: 'friendly_name', label: 'Name', placeholder: 'My Server', required: true }],
    [
      {
        id: 'check_interval_seconds',
        label: 'Check Interval Minutes',
        placeholder: 'In minutes',
        type: 'slider',
        marks: marksInterval,
        min: 1,
        max: 12,
        step: 1,
        tooltip: { open: false },
        helpText: 'How often to check if your cron job hasn\'t reported in.'
      }
    ]
  ];

  const handleSaveClick = () => {
    // handle save click
    const data = {
      ...form.state,
      run_interval_seconds: valuesInterval[form.state.run_interval_seconds],
      group_notification_ids: notifications.map((notification) => notification.id)
    };
    form.validate();
    if (form.isValid) {
      // save data
      if (cronId) {
        // update
        putCronMonitor(cronId, data).then(() => {
          form.reset(getInitialState());
        });
      } else {
        // create
        postCronMonitor(data).then(() => {
          form.reset(getInitialState());
        });
      }
      handleHide();
    }
  };

  const handleHide = () => {
    if (!adding) {
      navigate(`/cron/${cronId}`);
      return;
    }
    navigate('/dashboard');
  };

  return (
    <Modal
      show={show}
      className={'site-modal'}
      onHide={handleHide}
      title={MonitorModalTitle({ adding })}>
      <ModalBody>
        <BoundFormGroup
          formId={form.id}
          fields={fields}
        />
        <SelectNotificationGroup
          value={notifications}
          setValue={setNotifications}
        />
      </ModalBody>
      <ModalFooter>
        <button
          className={'save primary'}
          onClick={handleSaveClick}>
          Submit
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default CronModal;
