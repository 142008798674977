/*
 * This file is automatically generated with the 'build-icons' script.
 *
 * DO NOT MODIFY THE FILE.
 *
 * Any changes will be rewritten after the next script execution.
 */

import React from 'react';
import { ReactComponent as AccountBalance } from './assets/icon-account-balance.svg';
import { ReactComponent as Accounts } from './assets/icon-accounts.svg';
import { ReactComponent as AdminView } from './assets/icon-admin-view.svg';
import { ReactComponent as Appointments } from './assets/icon-appointments.svg';
import { ReactComponent as ArrowDown } from './assets/icon-arrow-down.svg';
import { ReactComponent as ArrowRightCircle } from './assets/icon-arrow-right-circle.svg';
import { ReactComponent as ArrowRight } from './assets/icon-arrow-right.svg';
import { ReactComponent as ArrowUp } from './assets/icon-arrow-up.svg';
import { ReactComponent as Attachment } from './assets/icon-attachment.svg';
import { ReactComponent as BackOrangeArrow } from './assets/icon-back-orange-arrow.svg';
import { ReactComponent as Back } from './assets/icon-back.svg';
import { ReactComponent as BubbleChart } from './assets/icon-bubble-chart.svg';
import { ReactComponent as BusketCheck } from './assets/icon-busket-check.svg';
import { ReactComponent as Calendar } from './assets/icon-calendar.svg';
import { ReactComponent as Chart } from './assets/icon-chart.svg';
import { ReactComponent as CheckCircle } from './assets/icon-check-circle.svg';
import { ReactComponent as Check } from './assets/icon-check.svg';
import { ReactComponent as CheckmarkSsl } from './assets/icon-checkmark-ssl.svg';
import { ReactComponent as Checkmark } from './assets/icon-checkmark.svg';
import { ReactComponent as ChevronDown } from './assets/icon-chevron-down.svg';
import { ReactComponent as ChevronLeft } from './assets/icon-chevron-left.svg';
import { ReactComponent as ChevronRight } from './assets/icon-chevron-right.svg';
import { ReactComponent as ChevronUp } from './assets/icon-chevron-up.svg';
import { ReactComponent as CircleArrowRight } from './assets/icon-circle-arrow-right.svg';
import { ReactComponent as Copy } from './assets/icon-copy.svg';
import { ReactComponent as CreateChat } from './assets/icon-create-chat.svg';
import { ReactComponent as CrossThin } from './assets/icon-cross-thin.svg';
import { ReactComponent as Cross } from './assets/icon-cross.svg';
import { ReactComponent as CurrentLocation } from './assets/icon-current-location.svg';
import { ReactComponent as Dashboard } from './assets/icon-dashboard.svg';
import { ReactComponent as Direction } from './assets/icon-direction.svg';
import { ReactComponent as DocumentUpload } from './assets/icon-document-upload.svg';
import { ReactComponent as Download } from './assets/icon-download.svg';
import { ReactComponent as Edit } from './assets/icon-edit.svg';
import { ReactComponent as Envelope } from './assets/icon-envelope.svg';
import { ReactComponent as EyeCrossed } from './assets/icon-eye-crossed.svg';
import { ReactComponent as Eye } from './assets/icon-eye.svg';
import { ReactComponent as Facility } from './assets/icon-facility.svg';
import { ReactComponent as FilePdf } from './assets/icon-file-pdf.svg';
import { ReactComponent as FileText } from './assets/icon-file-text.svg';
import { ReactComponent as FileZip } from './assets/icon-file-zip.svg';
import { ReactComponent as FilterMoney } from './assets/icon-filter-money.svg';
import { ReactComponent as Flag } from './assets/icon-flag.svg';
import { ReactComponent as Forward } from './assets/icon-forward.svg';
import { ReactComponent as Gift } from './assets/icon-gift.svg';
import { ReactComponent as Group } from './assets/icon-group.svg';
import { ReactComponent as Hamburger } from './assets/icon-hamburger.svg';
import { ReactComponent as Hyphen } from './assets/icon-hyphen.svg';
import { ReactComponent as Indicators } from './assets/icon-indicators.svg';
import { ReactComponent as Info } from './assets/icon-info.svg';
import { ReactComponent as Integration } from './assets/icon-integration.svg';
import { ReactComponent as LeftArrow } from './assets/icon-left-arrow.svg';
import { ReactComponent as Location } from './assets/icon-location.svg';
import { ReactComponent as Lock } from './assets/icon-lock.svg';
import { ReactComponent as Members } from './assets/icon-members.svg';
import { ReactComponent as Menu } from './assets/icon-menu.svg';
import { ReactComponent as Message } from './assets/icon-message.svg';
import { ReactComponent as Messages } from './assets/icon-messages.svg';
import { ReactComponent as Minus } from './assets/icon-minus.svg';
import { ReactComponent as NavigationLock } from './assets/icon-navigation-lock.svg';
import { ReactComponent as Pathway } from './assets/icon-pathway.svg';
import { ReactComponent as Patients } from './assets/icon-patients.svg';
import { ReactComponent as Pencil } from './assets/icon-pencil.svg';
import { ReactComponent as Plugins } from './assets/icon-plugins.svg';
import { ReactComponent as PlusSquare } from './assets/icon-plus-square.svg';
import { ReactComponent as Plus } from './assets/icon-plus.svg';
import { ReactComponent as Qrcode } from './assets/icon-qrcode.svg';
import { ReactComponent as QuestionCircle } from './assets/icon-question-circle.svg';
import { ReactComponent as Refresh } from './assets/icon-refresh.svg';
import { ReactComponent as Reports } from './assets/icon-reports.svg';
import { ReactComponent as SearchUser } from './assets/icon-search-user.svg';
import { ReactComponent as Search } from './assets/icon-search.svg';
import { ReactComponent as Settings } from './assets/icon-settings.svg';
import { ReactComponent as Shield } from './assets/icon-shield.svg';
import { ReactComponent as ShoppingCart } from './assets/icon-shopping-cart.svg';
import { ReactComponent as SignExclamation } from './assets/icon-sign-exclamation.svg';
import { ReactComponent as SignOut } from './assets/icon-sign-out.svg';
import { ReactComponent as Slack } from './assets/icon-slack.svg';
import { ReactComponent as Sort } from './assets/icon-sort.svg';
import { ReactComponent as ThinPlus } from './assets/icon-thin-plus.svg';
import { ReactComponent as ToggleOffFill } from './assets/icon-toggle-off-fill.svg';
import { ReactComponent as ToggleOnFill } from './assets/icon-toggle-on-fill.svg';
import { ReactComponent as Trash } from './assets/icon-trash.svg';
import { ReactComponent as Triangle } from './assets/icon-triangle.svg';
import { ReactComponent as User } from './assets/icon-user.svg';
import { ReactComponent as Webhook } from './assets/icon-webhook.svg';

const iconMap = {
  'account-balance': AccountBalance,
  'accounts': Accounts,
  'admin-view': AdminView,
  'appointments': Appointments,
  'arrow-down': ArrowDown,
  'arrow-right-circle': ArrowRightCircle,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  'attachment': Attachment,
  'back-orange-arrow': BackOrangeArrow,
  'back': Back,
  'bubble-chart': BubbleChart,
  'busket-check': BusketCheck,
  'calendar': Calendar,
  'chart': Chart,
  'check-circle': CheckCircle,
  'check': Check,
  'checkmark-ssl': CheckmarkSsl,
  'checkmark': Checkmark,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  'circle-arrow-right': CircleArrowRight,
  'copy': Copy,
  'create-chat': CreateChat,
  'cross-thin': CrossThin,
  'cross': Cross,
  'current-location': CurrentLocation,
  'dashboard': Dashboard,
  'direction': Direction,
  'document-upload': DocumentUpload,
  'download': Download,
  'edit': Edit,
  'envelope': Envelope,
  'eye-crossed': EyeCrossed,
  'eye': Eye,
  'facility': Facility,
  'file-pdf': FilePdf,
  'file-text': FileText,
  'file-zip': FileZip,
  'filter-money': FilterMoney,
  'flag': Flag,
  'forward': Forward,
  'gift': Gift,
  'group': Group,
  'hamburger': Hamburger,
  'hyphen': Hyphen,
  'indicators': Indicators,
  'info': Info,
  'integration': Integration,
  'left-arrow': LeftArrow,
  'location': Location,
  'lock': Lock,
  'members': Members,
  'menu': Menu,
  'message': Message,
  'messages': Messages,
  'minus': Minus,
  'navigation-lock': NavigationLock,
  'pathway': Pathway,
  'patients': Patients,
  'pencil': Pencil,
  'plugins': Plugins,
  'plus-square': PlusSquare,
  'plus': Plus,
  'qrcode': Qrcode,
  'question-circle': QuestionCircle,
  'refresh': Refresh,
  'reports': Reports,
  'search-user': SearchUser,
  'search': Search,
  'settings': Settings,
  'shield': Shield,
  'shopping-cart': ShoppingCart,
  'sign-exclamation': SignExclamation,
  'sign-out': SignOut,
  'slack': Slack,
  'sort': Sort,
  'thin-plus': ThinPlus,
  'toggle-off-fill': ToggleOffFill,
  'toggle-on-fill': ToggleOnFill,
  'trash': Trash,
  'triangle': Triangle,
  'user': User,
  'webhook': Webhook,
};

export default iconMap;
