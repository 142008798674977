import { get, post, del } from '../lib/api';

export const getStripeUrl = () => {
  return post('account/create_customer_portal_session', {
    action: 'GET_STRIPE_URL'
  });
};

export const getAccountInfo = () => {
  return get('account/info', {
    action: 'GET_ACCOUNT_INFO'
  });
};

export const getAccountApiKeys = () => {
  return get('account/api_key', {
    action: 'GET_ACCOUNT_API_KEYS'
  });
};

export const createAccountApiKey = (friendlyName) => {
  return post('account/api_key', {
    action: 'CREATE_ACCOUNT_API_KEY',
    data: {
      friendly_name: friendlyName
    }
  });
};

export const deleteAccountApiKey = (apiKeyId) => {
  return del(`account/api_key/${apiKeyId}`, {
    action: 'DELETE_ACCOUNT_API_KEY',
    meta: {
      apiKeyId
    }
  });
};
