import { Table, Tooltip } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { deleteUser } from 'redux/actions/usersActions';
import selectors from 'redux/selectors/selectors';
import { Icon } from 'shared/components';
import ConfirmModal from 'shared/components/ConfirmModal';
import { Preloader } from 'shared/components/preloader/Preloader';
import { ActionIcon, Body, Container, Head, TableCellAction, TableCellActions, Title } from 'shared/styled/styled';
import styled from 'styled-components';
import { getUiDateShort } from '../../shared/lib/dates';
import { notification } from 'antd';

const UserTable = styled.div`
    width: 100%;
    padding: 12px 36px 36px;
`;

export function UsersTableFeature() {
  const navigate = useNavigate();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState('');
  const users = useSelector(selectors.users);
  const usersInvited = useSelector(selectors.usersInvited);
  const usersAll = [...users, ...usersInvited];

  const isAdmin = useSelector(selectors.isAdmin);
  const editItem = (id) => {
    navigate(`/users/${id}/edit`);
  };

  const deleteItem = (id) => {
    setShowDeleteConfirm(true);
    setDeleteUserId(id);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Email',
      render: (user) => {
        return <Tooltip title={`User ID: ${user.id}`}>{user.email}</Tooltip>;
      }
    },
    {
      title: 'Position',
      render: (user) => {
        let position = 'User';
        if (user?.primary_account_user === true) {
          position = 'Primary Account User';
        }
        if (user?.invited === true) {
          position = 'Invited User';
        }
        return (<span>{position}</span>);
      }
    },
    {
      title: 'Created At',
      render: (user) => <span>{getUiDateShort(user.created_at)}</span>
    },
    {
      title: 'Actions',
      width: '11%',
      render: (item) => {
        const isInvited = item.invited;
        const isDeletable = !item.primary_account_user && !item.invited;
        return (
          <TableCellActions>
            {!isInvited && (
              <TableCellAction>
                <ActionIcon>
                  <Icon
                    name="pencil"
                    onClick={() => editItem(item.id)}
                  />
                </ActionIcon>
              </TableCellAction>
            )}

            {(isAdmin && isDeletable) && (
              <TableCellAction>
                <ActionIcon>
                  <Icon
                    name="cross"
                    onClick={() => {
                      deleteItem(item.id);
                    }}
                  />
                </ActionIcon>
              </TableCellAction>
            )}
          </TableCellActions>
        );
      }
    }
  ];

  return (
    <Container>
      {isLoading && <Preloader over />}
      <Head>
        <Title>Users</Title>
      </Head>
      <Body>
        <UserTable>
          <Table
            pagination={{
              hideOnSinglePage: true,
              defaultPageSize: 10,
              showSizeChanger: false,
              position: ['bottomRight']
            }}
            rowKey={(record) => record.id}
            dataSource={usersAll}
            columns={columns}
            scroll={{ x: '100%' }}
          />
        </UserTable>
      </Body>

      {showDeleteConfirm && (
        <ConfirmModal
          title="Delete User"
          show={showDeleteConfirm}
          text={
            <div>
              <div style={{ textAlign: 'center' }}>Are you sure you want to delete?</div>
              <br />
              <div style={{ textAlign: 'center' }}>Note: This action cannot be undone</div>
            </div>
          }
          onOk={() => {
            setIsLoading(true);
            deleteUser({ user_id: deleteUserId }, () => {
              setDeleteUserId('');
              setShowDeleteConfirm(false);
              setIsLoading(false);
            }).then((response) => {
              // check if response is successful
              if (response?.success) {
                // show success notification
                notification.success({
                  key: response.id,
                  message: 'User deleted',
                  description: 'User has been successfully deleted'
                });
              }
              navigate(`/users`);
            });
          }}
          okText="Yes"
          onCancel={() => {
            setShowDeleteConfirm(false);
            setDeleteUserId('');
          }}
          cancelText="No"
        />
      )}
    </Container>
  );
}
