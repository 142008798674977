import { AddNotificationModalFeature } from 'features/notifications/add-notification-modal';
import { useSelector } from 'react-redux';
import { createNotification, updateNotification } from 'redux/actions/notificationsActions';
import selectors from 'redux/selectors/selectors';
import { notification as notification_antd } from 'antd';

export function ManageNotificationWidget({ notificationGroupId, notificationId, add, edit }) {
  const notificationGroups = useSelector(selectors.notificationGroups);
  const notifications = notificationGroups.map((item) => item.notifications).flat();
  const notification = notifications?.find((item) => item?.id === notificationId);
  return (
    <AddNotificationModalFeature
      formData={notification}
      onSubmit={(data) => {
        if (add) {
          createNotification({ notification_group_id: notificationGroupId, ...data }, (response) => {
            if (response) {
              notification_antd.success({
                message: 'Notification added',
                description: 'Notification has been added successfully.'
              });
            }
          });
        }
        if (edit) {
          updateNotification({
            notification_group_id: notificationGroupId,
            id: notificationId, ...data
          }, (response) => {
            if (response) {
              notification_antd.success({
                message: 'Notification updated',
                description: 'Notification has been updated successfully.'
              });
            }
          });
        }
      }}
      title={add ? 'Add Notification' : 'Edit Notification'}
      addText={add ? 'Add' : 'Edit'}
      show={add || edit}
    />
  );
}
