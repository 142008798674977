import * as userSelectors from './userSelectors';
import * as uiSelectors from './uiSelectors';
import * as settingsSelectors from './settingsSelectors';
import * as monitorsSelectors from './monitorsSelectors';
import * as jobsSelectors from './jobsSelectors';
import * as eCommerceSelectors from './eCommerceSelectors';
import * as siteSelectors from './siteSelectors';
import * as notifications from './notificationsSelector';
import * as integrations from './integrationsSelector';

export const selectors = {
  ...userSelectors,
  ...uiSelectors,
  ...settingsSelectors,
  ...monitorsSelectors,
  ...jobsSelectors,
  ...eCommerceSelectors,
  ...siteSelectors,
  ...notifications,
  ...integrations
};

export default selectors;
