import { Divider } from 'antd';
import { AddMonitorFeature } from 'features/add-edit-monitor/add-edit-monitor';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import SiteModal from 'features/add-edit-monitor/site-modal';
import { PageWithLoading } from 'shared/components';
import styled from 'styled-components';
import { DashboardBodyWidget } from 'widgets/dashboard/dashboard-body.widget';
import { DashboardHeaderWidget } from 'widgets/dashboard/dashboard-header.widget';
import FloatingContactForm from '../../shared/components/FloatingContactForm';
import { getNotificationGroups } from '../../redux/actions/notificationsActions';
import CronModal from '../../features/add-edit-monitor/cron-modal';
import { getMonitorTotals, listMonitors } from '../../redux/actions/monitorsActions';
import { getIntegrations } from '../../redux/actions/integrationsActions';

const AddSiteContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export function DashboardPage() {
  const { actionId, arg1 } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const show = arg1 === 'add';
  useEffect(() => {
    Promise.all([
      getMonitorTotals(),
      listMonitors({}),
      getNotificationGroups(),
      getIntegrations(),
    ]).then(() => {
      setIsLoading(false);
    });
  }, []);
  return (
    <PageWithLoading isLoading={isLoading}>
      <Divider />
      <Divider />
      <Divider />
      <DashboardHeaderWidget />
      <Divider style={{ margin: '24px 0' }} />
      <AddSiteContainer>
        <AddMonitorFeature />
      </AddSiteContainer>
      <Divider style={{ margin: '12px 0' }} />
      <DashboardBodyWidget />

      <SiteModal
        show={actionId === 'site' && show}
        adding={true}
      />
      <CronModal
        show={actionId === 'cron' && show}
        adding={true}
      />
      <FloatingContactForm />
    </PageWithLoading>
  );
}
