import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { LayoutLogin } from 'shared/layout';
import { ResetPasswordWidget } from 'widgets/reset-password/reset-password.widget';

export function ResetPasswordPage() {
  const { code } = useParams();
  const [searchParams] = useSearchParams();
  return (
    <LayoutLogin>
      <ResetPasswordWidget
        code={code}
        email={searchParams.get('email')}
      />
    </LayoutLogin>
  );
}
