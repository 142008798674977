import React from 'react';
import { Tooltip } from 'antd';
import { Icon } from '../icon/Icon';
import styled from 'styled-components';

const Container = styled.div`
  cursor: pointer;
  transition: opacity 0.3s;
  &:hover {
    opacity: 1;
  }
`;

export function HelpTooltip({ text }) {
  return (
    <Container>
      <Tooltip
        title={text}>
        <Icon
          name="question-circle"
          size={14}
          color="#06abc1"
        />
      </Tooltip>
    </Container>
  );
}
