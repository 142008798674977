import { Route } from 'react-router';
import { ReduxRouteWrapper } from './redux-route';
import { LoginPage } from 'pages/login/login';
import { SignUpPage } from 'pages/sign-up/sign-up';
import { SignUpFromInvitePage } from 'pages/sign-up/sign-up-from-invite';
import { ResetPasswordPage } from 'pages/reset-password/reset-password';
import { RequestResetPasswordPage } from 'pages/request-reset-password/request-reset-password';

export const routes = [
  {
    id: 'login',
    routeId: 'login',
    path: '/auth/login',
    element: LoginPage
  },
  {
    id: 'sign-up',
    routeId: 'sign_up',
    path: '/auth/signup',
    element: SignUpPage
  },
  {
    id: 'sign-up-from-invite',
    routeId: 'sign_up_from_invite',
    path: '/signup_from_invite',
    element: SignUpFromInvitePage
  },
  {
    id: 'request-reset-password',
    routeId: 'request_reset_password',
    path: '/auth/request_reset',
    element: RequestResetPasswordPage
  },
  {
    id: 'reset-password',
    routeId: 'password_reset',
    path: '/password_reset/:code',
    element: ResetPasswordPage
  }
];

export const publicRoutes = () =>
  routes.map((route, idx) => (
    <Route
      key={`route-${idx}`}
      path={route.path}
      element={
        <ReduxRouteWrapper
          routeId={route.id}
          element={route.element}
          fixedWidth={route.fixedWidth}
        />
      }
    />
  ));
