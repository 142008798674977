const sortByProp =
  (prop, dir = 'asc', mapFn = (n) => n) =>
  (a, b) => {
    const aVal = mapFn(a[prop]);
    const bVal = mapFn(b[prop]);
    if (typeof aVal === 'number' && typeof bVal === 'number') {
      return aVal - bVal;
    }
    return (aVal || '').localeCompare(bVal || '');
  };

export const sortByProps =
  (props, order = []) =>
  (a, b) => {
    props = Array.isArray(props) ? props : [props];
    order = Array.isArray(order) ? order : [order];
    order = order.map((n) => n || 'asc');
    for (let i = 0; i < props.length; ++i) {
      let n = props[i];
      let aValue = typeof n === 'function' ? n(a) : a[n];
      let bValue = typeof n === 'function' ? n(b) : b[n];
      if (aValue !== bValue) {
        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return order[i] === 'asc' ? aValue - bValue : bValue - aValue;
        }
        return order[i] === 'asc' ? (aValue > bValue ? 1 : aValue < bValue ? -1 : 0) : aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
      }
    }
    return 0;
  };

export default sortByProp;
