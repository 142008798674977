import { useSelector } from 'react-redux';
import selectors from '../../redux/selectors/selectors';
import { useEffect, useState } from 'react';
import { getUiDateShort, getUiRelativeTime } from '../../shared/lib/dates';
import styled from 'styled-components';
import getNotificationTooltip from '../../shared/lib/notificationsHelper';
import { Tooltip } from 'antd';
import { getStateBadge, getStatusBadge } from '../../shared/components/Badges';
import secondsToTime from '../../shared/lib/secondsToTime';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px #06abc1;
  border-radius: 2px;
  width: 100%;
  min-width: 240px;
  height: 100%;
  padding: 24px 12px;
`;

const Header = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 34px;
  font-size: 16px;
  font-weight: 700;
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
`;
const Item = styled.div`
  display: flex;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: solid 1px #06abc1;
`;
const Key = styled.div`
  width: 50%;
`;
const Value = styled.div`
  width: 50%;
  text-transform: capitalize;
`;

export function CronSidebarFeature() {
  const integrations = useSelector(selectors.integrations);
  const monitor = useSelector(selectors.cronMonitorDetails);

  const [monitorState, setMonitorState] = useState(monitor);

  useEffect(() => {
    const interval = setInterval(() => {
      setMonitorState(monitor);
    }, 1000);

    return () => clearInterval(interval);
  }, [monitor]);

  const notificationGroupTitles = monitorState?.group_notifications?.map((group, index) => {
    const notifications = group.notifications?.map((notification) =>
      getNotificationTooltip(notification, integrations)
    );
    return (
      <Tooltip
        key={`${index}-${group?.id}`}
        title={notifications}>
        {group.friendly_name}
        {index < monitorState.group_notifications.length - 1 ? ', ' : ''}
      </Tooltip>
    );
  });

  return (
    <Container>
      <Header>Details</Header>
      <Body>
        <Item>
          <Key>Status</Key>
          <Value>{getStatusBadge(monitorState.current_status)}</Value>
        </Item>
        <Item>
          <Key>State</Key>
          <Value>{getStateBadge(monitorState.state)}</Value>
        </Item>
        <Item>
          <Key>Check Interval</Key>
          <Value>
            {secondsToTime({ seconds: monitorState.check_interval_seconds, withoutSeconds: true })}
          </Value>
        </Item>
        <Item>
          <Key>Last Received</Key>
          <Value>
            {monitorState?.last_received_at
              ? getUiRelativeTime(monitorState?.last_received_at)
              : 'Never'}
          </Value>
        </Item>
        <Item>
          <Key>Last Checked</Key>
          <Value>
            {monitorState.last_checked_at
              ? getUiRelativeTime(monitorState.last_checked_at)
              : 'Never'}
          </Value>
        </Item>
        <Item>
          <Key>Last Change</Key>
          <Value>
            {monitorState.last_status_change_at
              ? getUiDateShort(monitorState.last_status_change_at)
              : 'Never'}
          </Value>
        </Item>
        <Item>
          <Key>Date Created</Key>
          <Value>{getUiDateShort(monitorState.created_at)}</Value>
        </Item>
        <Item>
          <Key>Notification Group</Key>
          <Value>{notificationGroupTitles}</Value>
        </Item>
      </Body>
    </Container>
  );
}
