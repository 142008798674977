import createPersistedReducer from 'redux/lib/createPersistedReducer';
import mergeByProp from 'shared/lib/mergeByProp';

const initialState = {
  users: [],
};

const persistConfig = {
  key: 'usersReducer',
  whitelist: [],
  transforms: [],
};
export default createPersistedReducer(
  initialState,
  {
    CREATE_USER_SUCCESS: (state, payload) => ({
      ...state,
      users: [...state.users, payload.data],
    }),

    UPDATE_USER_SUCCESS: (state, payload) => ({
      ...state,
      users: mergeByProp('id', state?.users, [payload?.data]),
    }),
  },
  persistConfig
);
