const setDeep = (object, path, method) => {
	let parts = String(path).split('.');
	let newObject = {
		...object
	}
	let runningObject = newObject;
	for (let i = 0; i < parts.length; ++i) {
		let key = parts[i];
		if (i === parts.length - 1) {
			runningObject[key] = typeof method === 'function' ? method(runningObject[key]) : method;
			continue;
		}
		runningObject[key] = {
			...runningObject[key] || {},
		}
		runningObject = runningObject[key];
	}
	return newObject;
}

export default setDeep;