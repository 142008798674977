const hasClassInTree = (element, className, iters = 4, iter = 0) => {
  const classList = [...element.classList];
  if (classList.includes(className)) {
    return true;
  }
  if (iter < iters && element.parentElement) {
    return hasClassInTree(element.parentElement, className, iters, iter + 1);
  }
  return false;
};

export default hasClassInTree;
