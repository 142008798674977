import { notification } from 'antd';
import { useNavigate } from 'react-router';
import { createNotificationGroup, updateNotificationGroup } from 'redux/actions/notificationsActions';
import { AddNotificationGroupButtonFeature } from 'features/notifications/add-notification-group-button';
import { AddNotificationGroupModalFeature } from 'features/notifications/add-notification-group-modal';
import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';
import { useState } from 'react';
import { Preloader } from 'shared/components/preloader/Preloader';

export function AddNotificationGroupWidget({ id, edit, add }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const show = edit || add;

  const group = useSelector(selectors.getGroupById(id));
  const onHide = () => {
    navigate('/notifications');
  };

  const onSubmit = (data) => {
    const handler = edit ? updateNotificationGroup : createNotificationGroup;
    const finalData = edit ? { ...data, id } : data;
    setIsLoading(true);
    handler(finalData).then((response) => {
      setIsLoading(false);
      notification.success({
        key: response.id,
        message: 'Success',
        description: `Notification Group ${edit ? 'Saved' : 'Added'}`,
      });
    });
  };
  return (
    <>
      {isLoading && <Preloader lower />}
      <AddNotificationGroupButtonFeature />
      <AddNotificationGroupModalFeature
        title={edit ? 'Edit Group' : 'Add Group'}
        addText={edit ? 'Edit' : 'Add'}
        formData={group}
        show={show}
        onHide={onHide}
        onSubmit={onSubmit}
      />
    </>
  );
}
