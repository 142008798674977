import createPersistedReducer from 'redux/lib/createPersistedReducer';

const initialState = {
  stripeUrl: '',
  accountInfo: {},
  accountApiKeys: [],
};

const persistConfig = {
  key: 'eCommerce',
  whitelist: [],
  transforms: [],
};

export default createPersistedReducer(
  initialState,
  {
    GET_STRIPE_URL_SUCCESS: (state, payload) => ({
      ...state,
      stripeUrl: payload.data.url,
    }),
    GET_ACCOUNT_INFO_SUCCESS: (state, payload) => ({
      ...state,
      accountInfo: payload.data,
    }),
    GET_ACCOUNT_API_KEYS_SUCCESS: (state, payload) => ({
      ...state,
      accountApiKeys: payload.data,
    }),
    CREATE_ACCOUNT_API_KEY_SUCCESS: (state, payload) => ({
      ...state,
      accountApiKeys: [...state.accountApiKeys, payload.data],
    }),
    DELETE_ACCOUNT_API_KEY_SUCCESS: (state, payload) => {
      return {
        ...state,
        // This isn't working because payload.meta.apiKeyId is undefined
        // accountApiKeys: state.accountApiKeys.filter(
        //   (apiKey) => apiKey.id !== payload.meta.apiKeyId
        // ),
      };
    },
  },
  persistConfig
);
