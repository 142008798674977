import React from 'react'
import { Logo } from 'shared/components/logo/logo'

export const LayoutLogin = ({children}) => {
  return (
    <div className={'screen login-screen'}>
    <div className={'left'}>
      {children}
    </div>
    <div className={'right'}>
      <Logo width="240" />
    </div>
  </div>
  )
}
