import styled from 'styled-components';
import { Icon } from 'shared/components';

const Header = styled.div`
    font-size: 18px;
    color: ${(props) => (props.$contrast ? '#000C22' : '#fff')};
    text-align: center;
    font-weight: 700;
    justify-content: center;
`;

const Body = styled.div`
    padding: 24px 0 0 0;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const Value = styled.div`
    font-size: 100px;
    color: #06abc1;
    text-align: center;
    font-weight: 700;
    word-break: break-all;
`;

const Footer = styled.div`
    font-size: 12px;
    width: 100%;
    color: ${(props) => (props.$contrast ? '#000C22' : '#fff')};
`;

const Action = styled.div`
    position: absolute;
    top: 24px;
    right: 24px;
`;

export function InfoBlock({ title, value, footer, onClick, onActionClick, contrast }) {
  return (
    <div className={'info-block-container'}

         onClick={onClick}
         style={{ cursor: onClick ? 'pointer' : 'default' }}>
      {onActionClick && (
        <Action onClick={onActionClick}>
          <Icon
            name="settings"
            color={contrast ? '#000' : '#fff'}
          />
        </Action>
      )}

      <Header $contrast={contrast}>{title}</Header>
      <Body>
        <Value>{value}</Value>
      </Body>
      {footer && <Footer $contrast={contrast}>{footer}</Footer>}
    </div>
  );
}
