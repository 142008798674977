export const stripeUrl = (state) => state.stripeUrl;
export const accountInfo = (state) => state.eCommerce.accountInfo;
export const users = (state) => state.eCommerce.accountInfo.users || [];
export const usersInvited = (state) => {
  const users = state.eCommerce.accountInfo.invited_users || [];
  return users.map((item) => ({ ...item, invited: true }));
};
export const subscriptionRenewalDate = (state) =>
  state.eCommerce.accountInfo.subscription_renewal_date || '';
export const planName = (state) => state.eCommerce.accountInfo.subscription_plan_name || '';
export const userById = (id) => (state) =>
  state.eCommerce.accountInfo.users?.find((user) => user.id === id) || {};
export const currentUserId = (state) => state.eCommerce.accountInfo.id;
export const subscriptionStatus = (state) => state.eCommerce.accountInfo?.subscription_status;
export const usage = (state) => state.eCommerce.accountInfo?.usage || {};
export const isTrialExpired = (state) => {
  if (state.ui.isInitializing) {
    return false;
  } else {
    return (
      !state.eCommerce.accountInfo?.subscription_status &&
      !state.eCommerce.accountInfo?.trial_days_left
    );
  }
};

export const currentUser = (state) => {
  const info = accountInfo(state);
  const currentUserId = info?.primary_user_id;
  const users = info.users;
  return users?.find((u) => u.id === currentUserId);
};

export const userFullName = (state) => {
  const user = currentUser(state);
  return user?.name;
};
export const userEmail = (state) => {
  const user = currentUser(state);
  return user?.email;
};

export const isAdmin = (state) => {
  const user = currentUser(state);
  return !!user?.primary_account_user;
};

export const canAddSite = (state) => {
  const info = accountInfo(state);
  const sitesUsage = info?.usage?.sites_usage || 0;
  const sitesLimit = info?.usage?.sites_limit || 0;
  return sitesUsage < sitesLimit;
};

export const accountApiKeys = (state) => state?.eCommerce?.accountApiKeys || [];
export const accountApiKeyById = (id) => (state) =>
  state?.eCommerce?.accountApiKeys.find((apiKey) => apiKey.id === id) || {};
