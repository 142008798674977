import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';
import AppBanner from 'shared/components/AppBanner';

export function BannerTrialExpire({ setShowSignupModal }) {
  const [showBanner, setShowBanner] = useState(false);
  const accountInfoData = useSelector(selectors.accountInfo);

  const trialingStatus = 'trialing';

  useEffect(() => {
    if (accountInfoData) {
      setShowBanner(accountInfoData['subscription_status'] === trialingStatus);
    }
  }, [accountInfoData]);

  return (
    <AppBanner
      show={showBanner}
      onClose={() => {
        setShowBanner(false);
      }}>
      {accountInfoData && (
        <div className="trial-badge-container">
          <div className="trial-badge">
            <div className="trial-badge__text">
              <span>Your trial period expires in: {accountInfoData.trial_days_left} days</span>
            </div>
            <div
              className="trial-badge__button"
              onClick={() => setShowSignupModal(true)}>
              <span>Sign Up</span>
            </div>
          </div>
        </div>
      )}
    </AppBanner>
  );
}
