import { Button } from 'antd';
import { LoginForm } from 'features/login-form/login-form';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { initApp } from 'redux/actions/uiActions';
import { postLogin } from 'redux/actions/userActions';
import useBoundForm from 'shared/hooks/useBoundForm';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { put } from '../../redux/lib/api';
import setDeep from '../../shared/lib/setDeep';
import jwtDecode from 'jwt-decode';
import { setToken } from '../../shared/lib/authHelper';

const getInitialState = () => ({
  email: '',
  password: ''
});

export const LoginWidget = () => {
  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [googleBtnWidth, setGoogleBtnWidth] = React.useState('400');
  const form = useBoundForm({ initialState: getInitialState() });

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      handleFormSubmit(e);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      // set button width based on screen size
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        setGoogleBtnWidth('300');
      } else {
        setGoogleBtnWidth('400');
      }
    };

    // set initial size and listen for window resize events
    handleResize();
    window.addEventListener('resize', handleResize);

    // cleanup
    return () => window.removeEventListener('resize', handleResize);

  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    form.validate();
    if (!form.isValid) {
      return;
    }
    setLoading(true);
    postLogin({ ...form.state }, (data, err) => {
      setLoading(false);
      if (err) {
        const message =
          {
            Unauthorized: 'Invalid username or password'
          }[err.message] ||
          err.message ||
          err;
        setErrorMessage(message);
        return;
      }
      if (data?.token) {
        setErrorMessage('');
        initApp().then(() => {
          navigate('/');
          form.reset(getInitialState());
        });
      }
    });
  };

  return (
    <GoogleOAuthProvider clientId="115134820333-qkl7g6kc46ejsrmcuqg4bag6nmg12apg.apps.googleusercontent.com">
      <div
        className={`log-in-wrapper`}
        ref={wrapperRef}>
        <div className={'heading'}>Log In</div>

        <div
          className={`subheading ${errorMessage ? 'error' : ''} 
        ${loading ? 'submitting' : ''}`}>
          {loading ? 'Logging in...' : errorMessage || `Welcome back`}
        </div>

        <LoginForm
          form={form}
          onKeyPress={onEnter}
        />

        <Button
          block
          loading={loading}
          className="primary"
          onClick={handleFormSubmit}
          disabled={loading}>
          Log In
        </Button>
        <div className="forgot-password-container">
          <a href={'/auth/request_reset'}>Forgot Password?</a>
        </div>
        <div className={'alt-link'}>
          <span>Don't have an account?</span>
          <a href={'/auth/signup'}>Sign Up</a>
        </div>

        {/* Create a horizontal row with the text "or" in the middle */}
        <div style={{ display: 'flex', alignItems: 'center', margin: '32px 0' }}>
          <div style={{ flex: 1, height: '1px', backgroundColor: '#757D84FF' }} />
          <div style={{ margin: '0 16px', color: '#757D84FF', fontSize: '13px', fontWeight: 600 }}>OR</div>
          <div style={{ flex: 1, height: '1px', backgroundColor: '#757D84FF' }} />
        </div>

        <div className={'google-login-container'}>
          <GoogleLogin
            shape={'pill'}
            text={'signin_with'}
            context={'signin'}
            size={'large'}
            width={googleBtnWidth}
            onSuccess={credentialResponse => {
              setLoading(true);
              put('login/google', {
                data: {
                  credential: credentialResponse.credential
                },
                onResponse: (response) => {
                  return setDeep(response, 'data.data', {
                    ...(response.data || {}).data,
                    decoded: jwtDecode(((response.data || {}).data || {}).token)
                  });
                },
                callback: (data = {}, err) => {
                  setLoading(false);
                  if (err?.message) {
                    setErrorMessage(err.message);
                    return;
                  }

                  if (data.token) {
                    setToken(data.token);
                    setErrorMessage('');
                    initApp().then(() => {
                      navigate('/');
                      form.reset(getInitialState());
                    });
                  }

                },
                action: 'POST_LOGIN'
              });
            }}
          />
        </div>

      </div>
    </GoogleOAuthProvider>
  );
};
