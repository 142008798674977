import axios from 'axios';
import mapObjectToQueryParams from 'shared/lib/mapObjectToQueryParams';
import dispatchHelper from './dispatchHelper';
import { store } from './configureStore';
import { getToken } from 'shared/lib/authHelper';
import { notification } from 'antd';

const local = window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1');
const BASE_API = local ? `https://api.siteqwality.com/` : `https://api.siteqwality.com/`;

const handleAPIRequest = async (config) => {
  const cancelSource = axios.CancelToken.source();
  const actionTypes = Array.isArray(config.action) ? config.action : [config.action];
  const dispatches = actionTypes.map((n) => dispatchHelper(n, store.dispatch));

  try {
    const request = new axios.create({ timeout: 1000 * 60 * 10 });
    let response = await request({
      url: config.url,
      data: config.data,
      crossDomain: config.crossDomain,
      method: config.method,
      headers: {
        ...(config || {}).headers,
        Authorization: config.requiresToken ? `Bearer ${getToken()}` : ((config || {}).headers || {}).Authorization
      },
      cancelToken: cancelSource.token
    });

    if (config.onResponse) {
      response = config.onResponse(response);
    }

    dispatches.forEach((n) => n(response, config.callback, config.meta));
  } catch (error) {
    notification.error({
      message: 'Error occurred',
      description: error.message
    });
    const authRoute = config.url.includes('login') || config.url.includes('password');
    if (((error || {}).request || {}).status === 401 && !authRoute) {
      // logoutAndRedirect({ saveRedirectPath: true });
      // return;
    }
    const response = error.response;
    if (!!response && !response.data) {
      response.data = { message: error?.message, status: response?.status };
    }

    dispatches.forEach((n) => n(response, config.callback));
  }

  return cancelSource.cancel;
};

const apiRequest = (method, url, config = {}) => {
  const query = method === 'GET' ? { ...config.query } : {};
  const params = method === 'GET' ? String(mapObjectToQueryParams(query)).slice(1) : '';
  let fixedUrl = String(String(url).match(/^https*:\/\//) ? url : `${BASE_API}${url}?${params}`);

  return new Promise((resolve) => {
    const callback = (nothing, data, err) => {
      resolve(data, err);
      if (config.callback && typeof config.callback === 'function') {
        config.callback(data, err);
      }
    };

    handleAPIRequest({
      url: fixedUrl,
      data: config.data,
      headers: config.headers,
      action: config.action,
      meta: config.meta,
      requiresToken: config.requiresToken === undefined ? true : config.requiresToken,
      onResponse: config.onResponse || null,
      callback,
      method
    });
  });
};

export default apiRequest;
