import { Button } from 'antd';
import { useEffect, useState } from 'react';
import Modal from 'shared/components/Modal';
import ModalBody from 'shared/components/ModalBody';
import ModalFooter from 'shared/components/ModalFooter';
import BoundFormGroup from 'shared/components/boundForm/BoundFormGroup';
import useBoundForm from 'shared/hooks/useBoundForm';
import styled from 'styled-components';

const initialState = {
  friendly_name: '',
  delay_send_after_minutes: 3,
  resend_every_minutes: null
};

const AdvancedLink = styled.div`
    width: 100%;
    text-align: center;
    margin: 12px 0;
`;

export function AddNotificationGroupModalFeature({ show, onHide, onSubmit, formData, title, addText }) {
  const form = useBoundForm({ initialState: formData ?? initialState });
  const [showAdvanced, setShowAdvanced] = useState(false);
  const fields = [
    [{ id: 'friendly_name', label: 'Name', required: true }] //
  ];

  const fieldsAdvanced = [[{
    id: 'delay_send_after_minutes',
    label: 'Delay after (minutes)',
    type: 'slider',
    min: 0,
    max: 60,
    step: 1,
    helpText: '(Optional) How long to wait after your site goes down before sending out a notification'
  }], [{
    id: 'resend_every_minutes',
    label: 'Resend every (minutes)',
    type: 'slider',
    min: 0,
    max: 60,
    step: 1,
    helpText: '(Optional) If this is set, a notification will continuously get sent out until the site comes back online'
  }]];

  useEffect(() => {
    form.setState(formData);
  }, [formData]);

  const toggleAdvanced = () => {
    setShowAdvanced(!showAdvanced);
  };

  const handleSubmit = () => {
    form.validate();
    if (!form.isValid) {
      return;
    }
    onSubmit(form.state);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      title={title}>
      <ModalBody>
        <BoundFormGroup
          formId={form.id}
          fields={fields}
        />

        <AdvancedLink>
          <Button
            size="small"
            onClick={toggleAdvanced}>
            Advanced
          </Button>
        </AdvancedLink>

        {showAdvanced && (
          <BoundFormGroup
            formId={form.id}
            fields={fieldsAdvanced}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          className="primary"
          onClick={handleSubmit}>
          {addText}
        </Button>
        <Button
          className="cancel"
          onClick={onHide}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
