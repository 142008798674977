import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { memberRoutes } from './router/member-routes.jsx';
import { publicRoutes } from './router/public-routes.jsx';
import { DefaultRedirect } from './router/default-redirect.jsx';
import { NotFoundPage } from 'pages/not-found/not-found.jsx';
import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors.js';
import { TrialRedirect } from './router/trial-redirect.jsx';

const AppRouter = () => {
  const isTrialExpired = useSelector(selectors.isTrialExpired);
  return (
    <>
      <Routes>
        {!isTrialExpired ? (
          <Route
          path="*"
          element={<DefaultRedirect />}
        />
          
        ) : (
          <Route
            exact={false}
            path="*"
            element={<TrialRedirect />}
          />
        )}

        {memberRoutes(isTrialExpired)}

        <Route
          path="*"
          element={<NotFoundPage />}
        />

        {publicRoutes()}
      </Routes>
    </>
  );
};

export default AppRouter;
