import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';
import { InfoBlock, InfoBlockValuePositive } from 'shared/components';
import { listMonitors } from '../../redux/actions/monitorsActions';
import { useState } from 'react';
import { LoadingSpinner } from '../../shared/components/LoadingSpinner';

export function BlockOnlineSites() {
  const [loading, setLoading] = useState(false);
  const monitorTotals = useSelector(selectors.monitorsTotals);
  return (
    <InfoBlock
      title="Sites Online"
      value={
        loading ? <LoadingSpinner /> :
          <InfoBlockValuePositive
            value={<div style={{ cursor: 'pointer' }}>{monitorTotals.online}</div>}
          />
      }
      onClick={() => {
        setLoading(true);
        listMonitors({ statusFilter: 'success' }).then(() => {
          setLoading(false);
        });
      }}
    />
  );
}
