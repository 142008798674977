import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectors } from 'redux/selectors/selectors';

export const DefaultRedirect = () => {
  const loggedIn = useSelector(selectors.loggedIn);

  let to = '/auth/login';

  if (loggedIn) {
    to = '/dashboard';
  }

  return <Navigate to={to} />;
};
