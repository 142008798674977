import { PageWithLoading } from '../../shared/components';
import { Divider } from 'antd';
import FloatingContactForm from '../../shared/components/FloatingContactForm';
import { useEffect, useState } from 'react';
import { CronHeaderWidget } from '../../widgets/cron/cron-header.widget';
import { getCronMonitorDataById } from '../../redux/actions/cronJobsActions';
import { useParams } from 'react-router';
import { CronBodyWidget } from '../../widgets/cron/cron-body.widget';
import CronModal from '../../features/add-edit-monitor/cron-modal';
import { getIntegrations } from '../../redux/actions/integrationsActions';

export function CronDetailPage() {
  const { actionId: cronId, arg1 } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const show = arg1 === 'edit';

  useEffect(() => {
    Promise.all([getCronMonitorDataById(cronId), getIntegrations()])
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <PageWithLoading isLoading={isLoading}>
      <Divider />
      <CronHeaderWidget />
      <Divider />
      <CronBodyWidget />
      <CronModal
        cronId={cronId}
        show={show}
        adding={false}
      />
      <FloatingContactForm />
    </PageWithLoading>
  );
}
