import { Icon } from '..';
import { useCallback, useEffect, useState } from 'react';
import * as moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { DatePicker } from 'antd';
import {
  CalendarIcon,
  Container,
  Dropdown,
  DropdownItem,
  ExternalDatePicker,
  ExternalDatePickerFooter,
  OpenIcon,
  Select,
  Value
} from './styled';
import {
  dateRangePickerOptionsDefault,
  getISOStringRange,
  is30DaysRange,
  is7DaysRange,
  is90DaysRange
} from './lib';
import { getLast7Days } from 'shared/lib/dates';

const { RangePicker } = DatePicker;

export function DateRangePicker({
                                  defaultValue,
                                  onChange,
                                  loading,
                                  options = dateRangePickerOptionsDefault,
                                  customOptions
                                }) {
  const [value, setValue] = useState(getLast7Days());
  const [show, setShow] = useState(false);
  const [showRangePicker, setShowRangePicker] = useState(false);
  const disabledDate = (current, { from }) => {
    if (from) {
      return Math.abs(current.diff(from, 'days')) >= 89;
    }
    return false;
  };

  const getLabel = useCallback(() => {
    const template = `${moment(value?.start).format('MMM D, YYYY')} - ${moment(value?.end).format(
      'MMM D, YYYY'
    )}`;
    if (value.start === 'all time' || value.start === null) {
      return `All time`;
    } else if (is7DaysRange(value)) {
      return `Last 7 days: ${template}`;
    } else if (is30DaysRange(value)) {
      return `Last 30 days: ${template}`;
    } else if (is90DaysRange(value)) {
      return `Last 90 days: ${template}`;
    } else {
      return template;
    }
  }, [value, defaultValue]);

  const toggleDropdown = () => setShow(!show);

  useEffect(() => {
    if (defaultValue) {
      setValue(getISOStringRange(defaultValue));
    }
  }, [defaultValue]);

  return (
    <Container>
      <Select onClick={toggleDropdown}>
        <CalendarIcon>
          <Icon
            name="calendar"
            size={18}></Icon>
        </CalendarIcon>
        <Value>{getLabel()}</Value>
        <OpenIcon>
          {loading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 16, color: '#06abc1' }}
                  spin
                />
              }
            />
          ) : (
            <Icon
              name="chevron-down"
              size={14}
            />
          )}
        </OpenIcon>
      </Select>

      {show && (
        <>
          <Dropdown>
            {[...options, ...(customOptions?.length > 0 ? customOptions : [])].map((option) => (
              <DropdownItem
                key={option.label}
                onClick={() => {
                  setShow(false);
                  if (option.value === null) {
                    setShowRangePicker(true);
                    return;
                  }
                  setValue(option.value);

                  onChange(getISOStringRange(option.value));
                }}>
                {option.label}
              </DropdownItem>
            ))}
          </Dropdown>
        </>
      )}
      <ExternalDatePicker>
        <RangePicker
          renderExtraFooter={() => {
            return (
              <ExternalDatePickerFooter>
                <Button
                  size="small"
                  onClick={() => {
                    setShowRangePicker(false);
                  }}>
                  Close
                </Button>
              </ExternalDatePickerFooter>
            );
          }}
          className="custom-picker"
          open={showRangePicker}
          disabledDate={disabledDate}
          onChange={(_, [start, end]) => {
            const range = {
              start,
              end
            };
            const isoRange = getISOStringRange(range);
            setValue(isoRange);
            onChange(isoRange);
            setShowRangePicker(false);
          }}
        />
      </ExternalDatePicker>
    </Container>
  );
}
