import SVG from 'assets/svg/SVG';

const AppBanner = ({ children, show, onClose }) => {
  return (
    show && (
      <div className="app-banner">
        <div className="app-banner__content">{children}</div>
        {onClose && (
          <div
            className="app-banner__close"
            onClick={onClose}>
            <div className="app-banner__close-icon">
              <SVG icon="Cross" />
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default AppBanner;
