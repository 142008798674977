import React from 'react'
import { LayoutLogin } from 'shared/layout';
import { RequestResetPasswordWidget } from 'widgets/request-reset-password/request-reset-password.widget';

export const RequestResetPasswordPage = () => {
  return (
    <LayoutLogin>
    <RequestResetPasswordWidget />
  </LayoutLogin>
  )
}
