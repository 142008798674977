import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import BoundInput from './BoundInput';
import Portal from '../Portal';
import useBoundForm from 'shared/hooks/useBoundForm';
import hasClassInTree from 'shared/lib/hasClassInTree';
import { action } from 'redux/lib/api';
import { useHandleClickOutside } from 'shared/hooks/useHandleClickOutside';
import { Icon } from '..';

const stableObject = {};

const optionSelectors = {};

const definedOptions = {
  gender: [
    { value: 'M', label: 'Male' },
    { value: 'F', label: 'Female' },
    { value: 'O', label: 'Other' },
  ],
  active: [
    { value: '1', label: 'Active' },
    { value: '0', label: 'Inactive' },
  ],
};

const getInitialState = () => ({
  active: false,
  optionIndex: 0,
  searching: false,
  search: '',
});

const stableArray = [];

const BoundSelect = React.memo(
  ({
    formId,
    id,
    placeholder,
    options,
    disabled,
    freeform,
    required,
    nullable,
    nullableLabel,
    className,
    onChange,
    label,
  }) => {
    const selectorMethod =
      typeof options === 'string' ? optionSelectors[options] || (() => null) : () => null;

    const reduxOptions = useSelector(selectorMethod);
    const validating = useSelector(
      (state) => (state.ui.boundForms[formId] || stableObject).validating
    );
    const empties = useSelector(
      (state) => (state.ui.boundForms[formId] || stableObject).empties || stableArray
    );
    let reduxValue = useSelector(
      (state) => ((state.ui.boundForms[formId] || stableObject).state || stableObject)[id]
    );

    const wrapperRef = useRef(null);
    const dropdownRef = useRef(null);
    const portalId = useRef(undefined);

    const form = useBoundForm({ initialState: getInitialState() });

    const isMarkedEmpty = empties.includes(id);
    let position = {};
    let maxHeight = 400;
    let minWidth = 200;
    const windowHeight = document.body.offsetHeight;
    const windowWidth = document.body.offsetWidth;
    reduxValue = reduxValue === 'undefined' ? undefined : reduxValue;
    let optionSet =
      typeof options === 'string' ? [...(definedOptions[options] || reduxOptions)] : [...options];
    if (!portalId.current) {
      portalId.current = `bound-select-${Math.floor(Math.random() * 100000)}`;
    }
    if (wrapperRef.current) {
      position = wrapperRef.current.getBoundingClientRect();
      const remainingHeight = windowHeight - position.y - position.height - 32;
      const remainingWidth = windowWidth - position.x;
      maxHeight = remainingHeight < maxHeight ? remainingHeight : maxHeight;
      minWidth = minWidth > remainingWidth ? position.width : minWidth;
    }
    if (nullable) {
      optionSet.unshift({ value: null, label: nullableLabel || 'All' });
    }
    const reduxLabel =
      (optionSet.find((n) => String(n.value) === String(reduxValue)) || {}).label || reduxValue;
    const singleton = optionSet.length === 1 && reduxValue === optionSet[0].value;
    optionSet = optionSet.filter((n) => {
      return (
        !form.state.searching ||
        n.label.toLowerCase().includes(String(form.state.search).toLowerCase().trim())
      );
    });
    const invalid = optionSet.length === 0 && !freeform;

    const validate = () => {
      const noValue = !reduxValue || false;
      const empty = !!(noValue && required);
      const changed = empty !== isMarkedEmpty;
      if (changed) {
        action(empty ? 'uiSetFormEmptyField' : 'uiRemoveFormEmptyField', {
          formId,
          fieldId: id,
        });
      }
    };

    const handleFormStateChange = (value) => {
      action('uiPatchFormState', {
        formId: formId,
        state: {
          [id]: value,
        },
      });
      if (onChange) {
        onChange(value);
      }
    };

    const handleBoundInputChange = (e) => {
      if (freeform) {
        const value = e.target.value;
        handleFormStateChange((optionSet.find((n) => n.label === value) || {}).value || value);
      }
      if (form.state.searching) {
        form.setState({
          search: e.target.value,
          optionIndex: 0,
        });
      }
    };

    const handleWrapperClick = () => {
      if (!singleton || freeform) {
        form.set('active', !form.state.active);
      }
      if (freeform && optionSet.length < 1) {
        focusSearchInput();
      }
    };

    const handleOptionClick = (n) => {
      handleFormStateChange(n.value);
      form.reset(getInitialState());
    };

    const focusSearchInput = () => {
      if (wrapperRef.current) {
        const input = wrapperRef.current.querySelector('input');
        if (input) {
          input.focus();
        }
      }
    };

    const blurSearchInput = () => {
      if (wrapperRef.current) {
        const input = wrapperRef.current.querySelector('input');
        if (input) {
          input.blur();
        }
      }
    };

    const handleDropdownKeydown = (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        form.reset(getInitialState());
        blurSearchInput();
        return;
      }

      if (e.key === 'Enter') {
        form.reset(getInitialState());
        blurSearchInput();
        if (!invalid) {
          handleFormStateChange((optionSet[form.state.optionIndex] || {}).value);
        }
        return;
      }

      if (e.key.match(/^[a-zA-Z0-9]$/) && form.state.active && !form.state.searching) {
        form.set('searching', true);
        focusSearchInput();
        return;
      }

      let newIndex = (
        {
          ArrowUp: (n) => n - 1,
          ArrowDown: (n) => n + 1,
          PageUp: (n) => n - 7,
          PageDown: (n) => n + 7,
          Home: () => 0,
          End: () => optionSet.length - 1,
        }[e.key] || ((n) => n)
      )(form.state.optionIndex || 0);
      newIndex = newIndex < 0 ? 0 : newIndex;
      newIndex = newIndex > optionSet.length - 1 ? optionSet.length - 1 : newIndex;
      if (newIndex !== form.state.optionIndex) {
        form.set('optionIndex', newIndex);
      }
    };

    const handleDropdownMouseover = (n, idx) => {
      form.set('optionIndex', idx);
    };

    const handleInputBlur = (e) => {
      setTimeout(() => {
        form.reset(getInitialState());
      }, 0);
    };

    const handleInputFocus = (e) => {
      if (form.state.searching === false) {
        form.setState({
          active: true,
        });
      }
    };

    useHandleClickOutside(
      dropdownRef,
      (e) => {
        if (hasClassInTree(e.target, id, 6)) {
          return;
        }
        form.set('active', false);
      },
      { event: 'mouseup' }
    );

    useEffect(() => {
      if (validating === true) {
        validate();
      }
    }, [validating]);

    useEffect(() => {
      if (form.state.active === true && dropdownRef.current) {
        if (document.activeElement && document.activeElement.getAttribute('name') !== id) {
          dropdownRef.current.focus();
        }
      }
    }, [form.state.active]);

    useEffect(() => {
      if (!freeform && optionSet.length > 0 && reduxValue) {
        const match = optionSet.find((n) => String(n.value) === String(reduxValue));
        if (!match) {
          handleFormStateChange(optionSet[0].value);
        }
      }
      validate();
    }, [reduxValue, freeform, optionSet]);

    useEffect(() => {
      validate({ soft: true });
    }, [required]);

    if (disabled && freeform) {
      return (
        <BoundInput
          formId={formId}
          id={id}
          placeholder={placeholder}
          disabled={disabled}
        />
      );
    }

    const dropdownStyles = {
      top: maxHeight < 60 ? undefined : (position.top || 0) + 8,
      bottom: maxHeight >= 60 ? undefined : windowHeight - position.top + 45,
      left: position.left || 0,
      width: position.width || 40,
      minWidth: minWidth,
      maxHeight: maxHeight < 60 ? undefined : maxHeight,
    };

    const inputValue = form.state.searching
      ? form.state.search
      : reduxLabel === 'None'
      ? ''
      : reduxLabel;

    return (
      <div className="bound-select-group">
        {!!label && <div className={'label'}>{label}</div>}
        <div
          className={`bound-select-wrapper ${id} ${form.state.active ? 'active' : ''} ${
            disabled ? 'disabled' : ''
          } ${singleton ? 'singleton' : ''} ${invalid ? 'invalid' : ''}`}
          onClick={handleWrapperClick}
          ref={wrapperRef}>
          <div className={`bound-select-overlay`} />
          {!singleton && optionSet.length > 0 && (
            <div className={'bound-select-icons'}>
              <div className={'svg-wrapper'}>
                <Icon name="chevron-down" />
              </div>
            </div>
          )}
          <BoundInput
            id={id}
            placeholder={placeholder}
            onChange={handleBoundInputChange}
            value={inputValue}
            autoComplete={'off'}
            onKeyDown={handleDropdownKeydown}
            onBlur={handleInputBlur}
            onFocus={handleInputFocus}
          />
        </div>
        {form.state.active && optionSet.length > 0 && (
          <Portal
            id={portalId.current}
            className={'bound-select-portal'}>
            <div
              className={'bound-select-dropdown'}
              style={dropdownStyles}
              ref={dropdownRef}
              tabIndex={-1}
              onKeyDown={handleDropdownKeydown}>
              {optionSet.map((n, idx) => (
                <div
                  key={idx}
                  className={`bound-select-dropdown-item ${n.disabled === true ? 'disabled' : ''} ${
                    n.value === reduxValue ? 'active' : ''
                  } ${idx === form.state.optionIndex ? 'highlighted' : ''}`}
                  onMouseDown={() => handleOptionClick(n)}
                  onMouseEnter={() => handleDropdownMouseover(n, idx)}>
                  {n.label}
                </div>
              ))}
            </div>
          </Portal>
        )}
      </div>
    );
  }
);

export default BoundSelect;
