import { Drawer, Layout } from 'antd';
import { Footer } from 'antd/es/layout/layout';
import { BannerTrialExpire } from 'features/banners/banner-trial-expire';
import { BannerTrialExpired } from 'features/banners/banner-trial-expired';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getStripeUrl } from 'redux/actions/eCommerceActions';
import selectors from 'redux/selectors/selectors';
import Modal from 'shared/components/Modal';
import ModalBody from 'shared/components/ModalBody';
import ModalFooter from 'shared/components/ModalFooter';
import ModalHeader from 'shared/components/ModalHeader';
import { Preloader } from 'shared/components/preloader/Preloader';
import { Icon } from '../components';

const { Header, Sider, Content } = Layout;

export function LayoutGlobal({ children, header, sidebar }) {
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isInitializing = useSelector(selectors.isInitializing);
  const handleSignup = () => {
    setIsLoading(true);
    getStripeUrl()
      .then((request) => {
        window.location.href = request.url;
      })
      .finally(() => {
        setShowSignupModal(false);
        setIsLoading(false);
      });
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const hideDrawer = (e) => {
    setDrawerVisible(false);
  };

  return (
    <div>
      {isLoading && <Preloader over />}
      {showSignupModal && (
        <Modal
          show={showSignupModal}
          maxWidth={600}>
          <div className={'inner-wrapper'}>
            <ModalHeader title="Paid Plan Subscription" />
            <ModalBody>
              <div style={{ fontSize: '19px', textAlign: 'center' }}>You will be redirected to Stripe to manage your
                subscription. Do you want to proceed?
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                className={'save primary'}
                onClick={handleSignup}>
                Proceed
              </button>
              <button
                className={'cancel'}
                onClick={() => setShowSignupModal(false)}>
                Cancel
              </button>
            </ModalFooter>
          </div>
        </Modal>
      )}

      <BannerTrialExpire setShowSignupModal={setShowSignupModal} />
      {!isInitializing && <BannerTrialExpired setShowSignupModal={setShowSignupModal} />}
      <Layout
        hasSider
        style={{ minHeight: '100vh' }}>
        <Sider className={'sidebar'} trigger={null} collapsible collapsed={false}>
          {sidebar}
        </Sider>

        <Layout>
          <Icon className={'hamburger-menu-icon'} name={'hamburger'} size={35} onClick={showDrawer} />
          <Header>{header}</Header>

          <Drawer
            open={drawerVisible}
            placement="left"
            onClick={hideDrawer}
            onClose={hideDrawer}
            closeIcon={false}
          >
            {sidebar}
          </Drawer>
          <Content style={{ position: 'relative', height: '100%', width: '100%' }}>{children}</Content>
          <Footer></Footer>
        </Layout>
      </Layout>
    </div>
  );
}
