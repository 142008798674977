import { Button } from 'antd';
import React from 'react';
import { postSignUpFromInvite } from 'redux/actions/userActions';

export function SignUpFromInviteButtonFeature({ form, invite_code, account_id, onReset, onError, onSuccess }) {
  const [submitting, setSubmitting] = React.useState(undefined);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    form.validate();
    if (!form.isValid) {
      return;
    }
    setSubmitting(true);
    postSignUpFromInvite({ ...form.state, invite_code, account_id }, (data, err) => {
      setSubmitting(false);
      if (err) {
        onError(err.message);
        return;
      } else {
        if (data?.token) {
          onReset && onReset();
        }
        onSuccess();
      }
    });
  };
  return (
    <Button
      block
      loading={submitting}
      className={'primary'}
      onClick={handleFormSubmit}
      disabled={submitting}>
      Sign Up
    </Button>
  );
}
