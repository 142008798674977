import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { getAccountInfo } from 'redux/actions/eCommerceActions';
import { setAppInitializing } from 'redux/actions/uiActions';
import selectors from 'redux/selectors/selectors';
import { LayoutGlobal } from 'shared/layout';

export default function AuthenticatedLayout({ children, header, menu }) {
  const isAuthenticated = useSelector(selectors.loggedIn);

  useEffect(() => {
    if (isAuthenticated) {
      getAccountInfo().then(() => {
        setAppInitializing(false);
      });
    }
  }, []);

  return isAuthenticated ? (
    <>
      <LayoutGlobal
        sidebar={menu}
        header={header}>
        {children}
      </LayoutGlobal>
    </>
  ) : (
    <Navigate to="/auth/login" />
  );
}
