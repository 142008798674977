import jwtDecode from 'jwt-decode';

import { action, post, put } from '../lib/api';
import pluck from 'shared/lib/pluck';
import { setToken } from 'shared/lib/authHelper';
import setDeep from 'shared/lib/setDeep';

export const logoutAndRedirect = () => {
  action('LOGOUT');
  localStorage.removeItem('token');
  [...Object.keys(localStorage)]
    .filter((n) => n.match(/^persist:/))
    .filter((n) => n !== 'persist:appReducer')
    .forEach((n) => localStorage.removeItem(n));
  if (window.location.pathname !== '/login') {
    window.location = '/auth/login';
  }
};

export const loginAndRedirect = (token) => {
  action('LOGIN_SUCCESS', token);
};

export const postLogin = (args, callback) => {
  return put('login', {
    data: pluck(args, ['email', 'password']),
    onResponse: (response) => {
      return setDeep(response, 'data.data', {
        ...(response.data || {}).data,
        decoded: jwtDecode(((response.data || {}).data || {}).token)
      });
    },
    callback: (data = {}, err) => {
      if (data.token) {
        setToken(data.token);
      }
      if (callback) {
        callback(data, err);
      }
    },
    action: 'POST_LOGIN'
  });
};

export const requestResetPassword = (args, callback) => {
  return post('pw_reset/initiate', {
    data: pluck(args, ['email']),
    callback: (data = {}, err) => {
      if (callback) {
        callback(data, err);
      }
    },
    action: 'RESET_PASSWORD'
  });
};
export const setNewPassword = (args, callback) => {
  return post('pw_reset/complete', {
    data: pluck(args, ['code', 'password', 'email']),
    callback: (data = {}, err) => {
      if (callback) {
        callback(data, err);
      }
    },
    action: 'SET_NEW_PASSWORD'
  });
};

export const postToken = (token) => {
  action('POST_TOKEN', token);
};

export const postSignUp = ({ name, email, password, promotekitReferral }, callback) => {
  return post('signup', {
    data: {
      name,
      email,
      password,
      promotekit_referral: promotekitReferral
    },
    onResponse: (response) => {
      return setDeep(response, 'data.data', {
        ...(response.data || {}).data,
        decoded: jwtDecode(((response.data || {}).data || {}).token)
      });
    },
    callback: (data = {}, err) => {
      if (data.token) {
        setToken(data.token);
      }
      if (callback) {
        callback(data, err);
      }
    },
    action: 'POST_LOGIN'
  });
};

export const postSignUpFromInvite = ({ invite_code, account_id, name, email, password }, callback) => {
  return post('signup_from_invite', {
    data: {
      invite_code,
      account_id,
      name,
      email,
      password
    },
    onResponse: (response) => {
      return setDeep(response, 'data.data', {
        ...(response.data || {}).data,
        decoded: jwtDecode(((response.data || {}).data || {}).token)
      });
    },
    callback: (data = {}, err) => {
      if (data.token) {
        setToken(data.token);
      }
      if (callback) {
        callback(data, err);
      }
    },
    action: 'POST_LOGIN'
  });
};

export const postContactSupport = ({ message }, callback) => {
  return post('account/contact_support', {
    data: {
      message
    },
    callback: (data = {}, err) => {
      if (callback) {
        callback(data, err);
      }
    },
    action: 'CONTACT_SUPPORT'
  });
};
