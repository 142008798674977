import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px #06abc1;
  border-radius: 2px;
  width: 100%;
  height: 100%;
`;

export const Head = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding: 24px 34px;
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 600;
`;

export const Range = styled.div`
  display: flex;
  margin-left: auto;
  min-width: 400px;
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
`;

export const PerformanceChart = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 250px;
  padding: 24px 12px 0 12px;
  overflow: hidden;
  canvas {
    height: 100%;
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding: 48px 80px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Key = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #f4f5f3;
`;

export const Value = styled.div`
  font-size: 30px;
  font-weight: 700;
  color: #06abc1;
`;

export const TableCellActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const TableCellAction = styled.div`
  display: flex;
  padding: 2px;
  align-items: center;
  justify-content: center;
  border: solid 1px white;
  margin-right: 12px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
`;

export const ActionIcon = styled.div`
  display: flex;
  min-height: 24px;
  align-items: center;
  padding: 0 8px;
`;
export const ActionText = styled.div`
  padding-right: 8px;
`;
