import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Modal from 'shared/components/Modal';
import { getMonitorEventDetails } from 'redux/actions/jobsActions';
import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';
import parseJson from 'shared/lib/parseJson';
import { action } from 'redux/lib/api';
import { Preloader } from 'shared/components/preloader/Preloader';
import ModalBody from 'shared/components/ModalBody';

const statusCodeRootCauseMap = {
  400: (
    <>
      <div className="value">Bad Request</div>
      <div className="description">
        A 400 status code or "Bad Request" is sent by a web server when it cannot process the
        client's request due to something that is perceived to be a client error.
      </div>
    </>
  ),
  401: (
    <>
      <div className="value">Unauthorized</div>
      <div className="description">
        A 401 status code or "Unauthorized" is sent by a web server when a request requires user
        authentication but has not provided valid credentials.
      </div>
    </>
  ),
  403: (
    <>
      <div className="value">Forbidden</div>
      <div className="description">
        A 403 status code or "Forbidden" is sent by a web server when the client is authenticated
        but does not have the necessary permissions to access the requested resource.
      </div>
    </>
  ),
  404: (
    <>
      <div className="value">Not Found</div>
      <div className="description">
        A 404 status code or "Not Found" is sent by a web server when the requested resource could
        not be found.
      </div>
    </>
  ),
  405: (
    <>
      <div className="value">Method Not Allowed</div>
      <div className="description">
        A 405 status code or "Method Not Allowed" is sent by a web server when the request method
        specified in the request line (e.g., GET, POST, PUT, DELETE) is not allowed for the
        requested resource.
      </div>
    </>
  ),
  409: (
    <>
      <div className="value">Conflict</div>
      <div className="description">
        A 409 status code or "Conflict" is sent by a web server when the request conflicts with the
        current state of the server. This could happen, for example, when trying to create a
        resource with a name that already exists.
      </div>
    </>
  ),
  422: (
    <>
      <div className="value">Unprocessable Entity</div>
      <div className="description">
        A 422 status code or "Unprocessable Entity" is sent by a web server when the request was
        well-formed but was unable to be processed due to semantic errors in the request entity.
        This could happen, for example, if a request to create a user includes an invalid email
        address.
      </div>
    </>
  ),
  500: (
    <>
      <div className="value">Internal Server Error</div>
      <div className="description">
        A 500 status code or "Internal Server Error" is sent by a web server when it encounters an
        unexpected condition that prevents it from fulfilling the request. This is a generic error
        code, and the actual cause of the error may vary.
      </div>
    </>
  ),
  502: (
    <>
      <div className="value">Bad Gateway</div>
      <div className="description">
        A 502 status code or "Bad Gateway" is sent by a web server when it receives an invalid
        response from an upstream server it relied on in order to fulfill the client's request. This
        could be due to various reasons, such as the upstream server being overloaded, down for
        maintenance, or returning an unexpected response format.
      </div>
    </>
  ),
  503: (
    <>
      <div className="value">Service Unavailable</div>
      <div className="description">
        A 503 status code or "Service Unavailable" is sent by a web server when the server is
        currently unavailable (overloaded or down for maintenance) and cannot handle the client's
        request at this time. This response is usually temporary.
      </div>
    </>
  ),
  504: (
    <>
      <div className="value">Gateway Timeout</div>
      <div className="description">
        A 504 status code or "Gateway Timeout" is sent by a web server when it does not receive a
        timely response from an upstream server it relied on to fulfill the client's request. This
        could be due to various reasons, such as the upstream server being overloaded, down for
        maintenance, or returning an unexpected response format.
      </div>
    </>
  ),
};

const failureReasons = {
  connection: (
    <>
      <div className="value">Connection Error</div>
      <div className="description">A connection error can be caused by any of the following:</div>
      <div className={'description'}>
        <ul>
          <li>Web server is offline</li>
          <li>Wrong URL</li>
          <li>Domain name not registered</li>
          <li>Firewall blocking the connection</li>
        </ul>
      </div>
    </>
  ),
  connection_reset: (
    <>
      <div className="value">Connection Reset</div>
      <div className="description">
        "Connection Reset" signifies an abrupt termination of an already established connection.
        This could can be caused by any of the following:
      </div>
      <div className={'description'}>
        <ul>
          <li>Remote server crashed unexpectedly</li>
          <li>Remote server encountered an internal error</li>
          <li>
            Remote server purposely closed the connection due to suspicious activity or inactivity
            timeouts
          </li>
          <li>
            Local firewall or security software interrupted the connection for security reasons
          </li>
        </ul>
      </div>
    </>
  ),
  timeout: (
    <>
      <div className="value">Timeout Error</div>
      <div className="description">
        A timeout is caused by your website not responding within the specified time detailed in the
        site creation. You can update this timeout setting in the 'Edit Site' section if you feel
        like this incident was erroneous.
      </div>
      <div className="description">A timeout error can be caused by any of the following:</div>
      <div className={'description'}>
        <ul>
          <li>Slow internet connection</li>
          <li>Server is overloaded</li>
          <li>Server is down</li>
        </ul>
      </div>
    </>
  ),
  max_redirect: (
    <>
      <div className="value">Max Redirect</div>
      <div className="description">
        "Max Redirect" indicates that the client was redirected too many times. This could be caused
        by any of the following:
      </div>
      <div className={'description'}>
        <ul>
          <li>Too many redirects in the server configuration (Max 10)</li>
          <li>Incorrect server configuration</li>
          <li>Incorrect URL</li>
        </ul>
      </div>
    </>
  ),
  body: (
    <>
      <div className="value">Body Error</div>
      <div className="description">
        "Body Error" indicates that the server was unable to process the request body. This could be
        caused by any of the following:
      </div>
      <div className={'description'}>
        <ul>
          <li>Invalid request body</li>
          <li>Incorrect server configuration</li>
          <li>Server is down</li>
        </ul>
      </div>
    </>
  ),
  decode: (
    <>
      <div className="value">Decode Error</div>
      <div className="description">
        "Decode Error" indicates that the server was unable to decode the response. This could be
        caused by any of the following:
      </div>
      <div className={'description'}>
        <ul>
          <li>Incorrect server configuration</li>
          <li>Server is down</li>
        </ul>
      </div>
    </>
  ),
  missing_keyword: (
    <>
      <div className="value">Missing Keyword</div>
      <div className="description">
        "Missing Keyword" indicates that the keyword specified in the site creation was not found in
        the response body.
      </div>
    </>
  ),
  unknown: (
    <>
      <div className="value">Unknown Error</div>
      <div className="description">
        An unknown error occurred. This could be caused by any of the following:
      </div>
      <div className={'description'}>
        <ul>
          <li>Server is down</li>
          <li>Server is overloaded</li>
          <li>Firewall blocking the connection</li>
          <li>Wrong URL</li>
        </ul>
      </div>
    </>
  ),
};

const MonitorEventDetailsModal = ({ jobId, eventId, show }) => {
  const navigate = useNavigate();

  const events = useSelector(selectors.monitorEventsByJobId(jobId));
  const monitor = useSelector(selectors.monitorById(jobId));
  const siteId = useSelector(selectors.param('actionId'));
  const details = useSelector(selectors.monitorEventDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [event, setEvent] = useState(false);
  const monitorName = monitor?.friendly_name;
  const urlRequest = monitor?.uri;
  const urlResponse = details?.final_uri;
  const ipAddress = details?.remote_address;
  const httpVersion = details?.http_version;
  const requestDurationMs = details?.request_duration_ms;
  let statusCode = details?.response_http_code;
  const headers = parseJson(details?.response_headers);
  const method = monitor?.method || 'GET';
  const incidentResolved = !!event?.ended_at;
  const monitorStatus = incidentResolved ? '↑ Resolved' : '↓ Down';
  const failureReason = details?.failure_reason;

  const getHeaders = (headers) => {
    return Object.entries(headers || {}).map(([key, value]) => {
      const prettyKey = String(key)
        .split('-')
        .map((n = '') => n[0]?.toUpperCase() + n.slice(1))
        .join('-');
      return { key: prettyKey, value };
    });
  };

  const headerSet = getHeaders(headers);

  const requestHeaders = details ? getHeaders(details?.request_headers) : [];

  const handleHide = () => {
    navigate(`/site/${siteId}`);
  };

  useEffect(() => {
    setEvent(events?.find((n) => n.started_by_http_job_result_id === eventId));
  }, [events, eventId]);

  useEffect(() => {
    if (show && !!eventId) {
      setIsLoading(true);
      getMonitorEventDetails({
        eventId: eventId,
      }).then(() => {
        setIsLoading(false);
      });
    }
  }, [show, event, eventId]);

  useEffect(() => {
    if (!show) {
      action('CLEAR_MONITOR_EVENT_DETAILS');
    }
  }, [show]);

  return (
    <Modal
      show={show}
      className={'monitor-event-details-modal'}
      onHide={handleHide}
      title={
        <div>
          <div className={'title'}>
            Incident on <span className={'accent'}>{monitorName}</span>
          </div>
          <div className={`event_status ${incidentResolved ? 'success' : 'failed'}`}>
            {monitorStatus}
          </div>
        </div>
      }>
      <>
        <ModalBody>
          {isLoading && <Preloader />}

          <section className={'root-cause'}>
            {statusCode !== 200 && <div className={'label'}>Root Cause</div>}

            {statusCode && statusCodeRootCauseMap[statusCode] && (
              <div className={'value'}>{statusCodeRootCauseMap[statusCode]}</div>
            )}
            {!statusCode && failureReason && (
              <div className={'value'}>{failureReasons[failureReason]}</div>
            )}
          </section>
          <section className={'request'}>
            <div className={'section-heading'}>Request</div>
            <div className={'row'}>
              <div className={'left'}>Address:</div>
              <div className={'right'}>
                <div className={'address'}>
                  <div className={'url'}>
                    <div className="uri">{urlRequest}</div>
                  </div>
                  <div className={`method ${method.toLowerCase()}`}>{method}</div>
                </div>
              </div>
            </div>
            {httpVersion && (
              <div className={'row'}>
                <div className={'left'}>HTTP Version:</div>
                <div className={'right'}>
                  <div className={'address'}>
                    <div className={'url'}>
                      <div className="ip">{httpVersion}</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {requestDurationMs && (
              <div className={'row'}>
                <div className={'left'}>Duration:</div>
                <div className={'right'}>
                  <div className={'address'}>
                    <div className={'url'}>
                      <div className={'ip'}>{requestDurationMs} ms</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {requestHeaders && (
              <>
                <div className={'row'}>
                  <div className={'left'}>Headers:</div>
                </div>
                <div className={'http-headers'}>
                  {requestHeaders.map(({ key, value }, idx) => (
                    <div
                      key={idx}
                      className={'http-header'}>
                      <div className={'key'}>{key}</div>
                      <div className={'value'}>{value}</div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </section>
          <section className={'response'}>
            <div className={'section-heading'}>Response</div>
            {urlResponse && (
              <div className={'row'}>
                <div className={'left'}>Final Address:</div>
                <div className={'right'}>
                  <div className={'address'}>
                    <div className={'url'}>
                      <div className="uri">{urlResponse}</div>
                      <div className={'ip'}>{ipAddress}</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {statusCode && (
              <div className={'row'}>
                <div className={'left'}>Status Code:</div>
                <div className={'right'}>{statusCode}</div>
              </div>
            )}
            {headerSet && (
              <>
                <div className={'row'}>
                  <div className={'left'}>Headers:</div>
                </div>
                <div className={'http-headers'}>
                  {headerSet.map(({ key, value }, idx) => (
                    <div
                      key={idx}
                      className={'http-header'}>
                      <div className={'key'}>{key}</div>
                      <div className={'value'}>{value}</div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </section>
        </ModalBody>

        <div className={'footer'}></div>
      </>
    </Modal>
  );
};

export default MonitorEventDetailsModal;
