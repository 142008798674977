import { Badge, Table, Tooltip } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  deleteNotification,
  deleteNotificationGroup,
  getNotificationGroups,
} from 'redux/actions/notificationsActions';
import selectors from 'redux/selectors/selectors';
import { Icon } from 'shared/components';
import ConfirmModal from 'shared/components/ConfirmModal';
import { Preloader } from 'shared/components/preloader/Preloader';
import {
  ActionIcon,
  Body,
  Container,
  Head,
  TableCellAction,
  TableCellActions,
  Title,
} from 'shared/styled/styled';
import styled from 'styled-components';
import { mapNotificationsData } from './data-mapper';
import { getUiDateTimeShort } from '../../shared/lib/dates';

const NotificationGroupTable = styled.div`
  width: 100%;
  padding: 36px;
  padding-top: 12px;
`;

export function NotificationGroupTableFeature({ edit }) {
  const navigate = useNavigate();
  const groups = useSelector(selectors.notificationGroups) || [];
  const integrations = useSelector(selectors.integrations);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDeleteNotificationConfirm, setShowDeleteNotificationConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [groupId, setGroupId] = useState('');
  const [notificationId, setNotificationId] = useState('');

  const editItem = (id) => {
    navigate(`/notifications/${id}/edit`);
  };

  const addNotification = (id) => {
    navigate(`/notifications/${id}/add-notification`);
  };

  const editNotification = (groupId, id) => {
    navigate(`/notifications/${groupId}/edit-notification/${id}`);
  };

  const deleteNotificationById = (id) => {
    setNotificationId(id);
    setShowDeleteNotificationConfirm(true);
  };

  const deleteItem = (id) => {
    setShowDeleteConfirm(true);
    setGroupId(id);
  };

  const renderNotificationsList = (groupId, data) => {
    return (
      <Table
        pagination={{
          hideOnSinglePage: true,
        }}
        columns={[
          {
            title: 'Type',
            render: (record) => {
              const type = record?.type;
              let type_name = type;
              let type_icon = '';
              switch (type) {
                case 'email':
                  type_name = 'Email';
                  type_icon = 'envelope';
                  break;
                case 'sms':
                  type_name = 'SMS';
                  type_icon = 'message';
                  break;
                case 'slack':
                  type_name = 'Slack';
                  type_icon = 'slack';
                  break;
                case 'webhook':
                  type_name = 'Webhook';
                  type_icon = 'webhook';
                  break;
                default:
                  break;
              }
              return (
                <Tooltip title={`Created At: ${getUiDateTimeShort(record?.created_at)}`}>
                  <span>
                    {type_icon && (
                      <span style={{ paddingRight: '8px' }}>
                        <Icon name={type_icon} />
                      </span>
                    )}
                    {type_name}
                  </span>
                </Tooltip>
              );
            },
          },
          {
            title: 'Details',
            render: (record) => {
              return <span>{mapNotificationsData(record, integrations).value}</span>;
            },
          },
          {
            title: '',
            render: (record) => {
              return (
                <TableCellActions>
                  <TableCellAction>
                    <ActionIcon>
                      <Icon
                        name="pencil"
                        onClick={() => editNotification(groupId, record.id)}
                      />
                    </ActionIcon>
                  </TableCellAction>

                  <TableCellAction>
                    <ActionIcon>
                      <Icon
                        name="cross"
                        onClick={() => {
                          deleteNotificationById(record.id);
                        }}
                      />
                    </ActionIcon>
                  </TableCellAction>
                </TableCellActions>
              );
            },
          },
        ]}
        dataSource={data}
      />
    );
  };

  const badgeStyle = {
    background: '#06ABC1',
    fontWeight: 'bold',
    fontSize: '12px',
  };

  const columns = [
    {
      title: 'Group Name',
      render: (record) => {
        return (
          <Tooltip
            title={`Created At: ${getUiDateTimeShort(record?.created_at)}`}
            key={`group-tooltip-created-at-${record.id}`}>
            <span>{record.friendly_name}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Emails',
      render: (record) => {
        const emails = record.notifications?.filter((item) => item.type === 'email');
        return (
          <span>
            <Badge
              count={emails?.length}
              style={badgeStyle}
            />
          </span>
        );
      },
      align: 'center',
    },
    {
      title: 'Phone numbers',
      render: (record) => {
        const phoneNumbers = record.notifications?.filter((item) => item.type === 'sms');
        return (
          <span>
            <Badge
              count={phoneNumbers?.length}
              style={badgeStyle}
            />
          </span>
        );
      },
      align: 'center',
    },
    {
      title: 'Sites',
      dataIndex: 'numb_of_sites',
      render: (record) => {
        return (
          <span>
            <Badge
              count={record}
              style={badgeStyle}
            />
          </span>
        );
      },
      align: 'center',
    },
    {
      title: 'Notification delay',
      dataIndex: 'delay_send_after_minutes',
      render: (record) => {
        return (
          <span>
            <Badge
              count={record ? `${record} min` : ''}
              style={badgeStyle}
            />
          </span>
        );
      },
      align: 'center',
    },
    {
      title: 'Resend interval',
      dataIndex: 'resend_every_minutes',
      render: (record) => {
        return (
          <span>
            <Badge
              count={record ? `${record} min` : ''}
              style={badgeStyle}
            />
          </span>
        );
      },
      align: 'center',
    },
    {
      title: 'Actions',
      width: '15%',
      render: (item) => {
        return (
          <TableCellActions>
            <TableCellAction onClick={() => addNotification(item.id)}>
              <ActionIcon>
                <Icon name="plus" />
              </ActionIcon>
            </TableCellAction>

            <TableCellAction>
              <ActionIcon>
                <Icon
                  name="pencil"
                  onClick={() => editItem(item.id)}
                />
              </ActionIcon>
            </TableCellAction>

            <TableCellAction>
              <ActionIcon>
                <Icon
                  name="cross"
                  onClick={() => {
                    deleteItem(item.id);
                  }}
                />
              </ActionIcon>
            </TableCellAction>
          </TableCellActions>
        );
      },
    },
  ];

  return (
    <>
      {isLoading && <Preloader over />}
      <Container>
        <Head>
          <Title>Notification Groups</Title>
        </Head>
        <Body>
          <NotificationGroupTable>
            <Table
              pagination={{
                hideOnSinglePage: true,
                defaultPageSize: 10,
                showSizeChanger: false,
                position: ['bottomRight'],
              }}
              dataSource={groups}
              columns={columns}
              rowKey={(record) => record.id}
              expandable={{
                expandedRowRender: (record) =>
                  renderNotificationsList(record.id, record.notifications),
                rowExpandable: (record) => record.notifications?.length > 0,
              }}
              scroll={{ x: '100%' }}
            />
          </NotificationGroupTable>
        </Body>
      </Container>

      {showDeleteConfirm && (
        <ConfirmModal
          title="Delete Notification Group"
          show={showDeleteConfirm}
          text={
            <div>
              <div style={{ textAlign: 'center' }}>Are you sure you want to delete?</div>
              <br />
              <div style={{ textAlign: 'center' }}>Note: This action cannot be undone</div>
            </div>
          }
          onOk={() => {
            setIsLoading(true);
            deleteNotificationGroup(groupId).then(() => {
              setGroupId('');
              setShowDeleteConfirm(false);
              navigate(`/notifications`);
              getNotificationGroups();
              setIsLoading(false);
            });
          }}
          okText="Yes"
          onCancel={() => {
            setShowDeleteConfirm(false);
            setGroupId('');
          }}
          cancelText="No"
        />
      )}

      {showDeleteNotificationConfirm && (
        <ConfirmModal
          title="Delete Notification"
          show={showDeleteNotificationConfirm}
          text={
            <div>
              <div style={{ textAlign: 'center' }}>Are you sure you want to delete?</div>
              <br />
              <div style={{ textAlign: 'center' }}>Note: This action cannot be undone</div>
            </div>
          }
          onOk={() => {
            setIsLoading(true);
            deleteNotification(notificationId).then(() => {
              setNotificationId('');
              setShowDeleteNotificationConfirm(false);
              getNotificationGroups();
              navigate(`/notifications`);
              setIsLoading(false);
            });
          }}
          okText="Yes"
          onCancel={() => {
            setShowDeleteNotificationConfirm(false);
            setNotificationId('');
          }}
          cancelText="No"
        />
      )}
    </>
  );
}
