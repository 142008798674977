export const monitorsTotals = (state) => state?.monitors?.totals || {};
export const combinedMonitorsSorted = (state) => state?.monitors?.combinedMonitorsSorted || [];

// http
export const httpMonitorDetails = (state) => state?.monitors?.http?.currentDetails || {};
export const httpMonitorById = (state, id) =>
  state?.monitors?.http?.monitors?.find((m) => m.id === id) || {};
export const httpMonitors = (state) => state?.monitors?.http?.monitors || [];

// cron
export const cronMonitorDetails = (state) => state?.monitors?.cron?.currentDetails || {};
export const cronMonitorById = (state, id) =>
  state?.monitors?.cron?.monitors?.find((m) => m.id === id) || {};
export const cronMonitors = (state) => state?.monitors?.cron?.monitors || [];
export const cronMonitorEvents = (state) => state?.monitors?.cron?.currentJobEvents || [];
export const cronMonitorRecents = (state) => state?.monitors?.cron?.currentJobRecents || [];
export const cronMonitorUptimePercentage = (state) =>
  state?.monitors?.cron?.currentUptimePercentage || {};

// filters
export const monitorsFilters = (state) => state?.monitors?.filters || {};
