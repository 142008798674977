import React from 'react';
import { Preloader } from 'shared/components/preloader/Preloader';

function WithLoading(Component) {
  return function WihLoadingComponent({ isLoading, ...props }) {
    return <div>{isLoading ? <Preloader lower transparent /> : <Component {...props} />}</div>;
  };
}

export default WithLoading;
