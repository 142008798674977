const tooltipTitle = (context) => {
  return context.raw.percent === 100 ? '' : `${context.raw.percent}%`;
};

const tooltipFooter = (tooltipItems) => {
  return tooltipItems[0].raw.status.toUpperCase();
};

const tooltipLabel = (context) => {
  return `${context.raw.y} ms`;
};

export const options = {
  plugins: {
    tooltip: {
      callbacks: {
        beforeLabel: tooltipTitle,
        label: tooltipLabel,
        footer: tooltipFooter
      }
    },
    legend: {
      display: false
    }
  },
  segment: {
    borderColor: function(context) {
      const isFailed = context.p1.raw.status === 'failed';
      if (isFailed) {
        return 'red';
      } else {
        return '#1875F0';
      }
    }
  },
  type: 'line',
  responsive: true,
  indexAxis: 'x',
  tension: 0,
  maintainAspectRatio: false,
  parsing: {
    xAxisKey: 'moment',
    yAxisKey: 'y'
  },
  elements: {
    point: {
      hitRadius: 10,
      pointStyle: false
    }
  },

  scales: {
    x: {
      grid: {
        drawTicks: false
      },
      border: {
        display: true,
        color: '#353E50'
      },
      ticks: {
        align: 'inner',
        autoSkip: true,
        distribution: 'nice',
        maxTicksLimit: 6,
        color: '#9DA1A8'
      }
    },

    y: {
      grid: {
        drawOnChartArea: true,
        borderColor: '#353E50',
        borderWidth: 1,
        color: '#353E50'
      },
      border: {
        display: true,
        color: '#353E50'
      },
      ticks: {
        display: true,
        autoSkip: false,
        color: '#9DA1A8',
        maxTicksLimit: 6,
        callback: function(value, index, values) {
          return `${value} ms`;
        }
      }
    }
  }
};
