import moment from 'moment';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getTlsState } from 'redux/actions/jobsActions';
import selectors from 'redux/selectors/selectors';
import { Icon, InfoBlock } from 'shared/components';
import { InfoBlockFooterBase } from 'shared/components/info-block/info-block-footer-base';
import { InfoBlockValueSmall } from 'shared/components/info-block/info-block-value-small';
import { getUiDateShort } from 'shared/lib/dates';
import styled from 'styled-components';

const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StatusIcon = styled.div`
    margin-right: 15px;
`;

const StatusText = styled.div`
    text-transform: capitalize;
    margin-top: -10px;
`;

const Title = styled.div`
    display: flex;
    text-align: center;
    justify-content: center;
`;

const TitleText = styled.div`
    line-height: 1.7;
    margin-right: 8px;
`;

const isExpired = (expireDate) => {
  return moment(expireDate).isBefore(moment());
};

export function BlockSSLCheckFeature({ tlsJobId }) {
  useEffect(() => {
    getTlsState(tlsJobId);
  }, []);

  const details = useSelector(selectors.tlsDetails);

  const statusMap = {
    good: 'Pass',
    bad: isExpired(details.expiry_date) ? 'Expired' : 'Fail',
    unknown: 'Unknown'
  };

  return (
    <InfoBlock
      thin
      autoHeight
      title={
        <Title>
          <TitleText>SSL</TitleText>{' '}
          {details.current_status === 'good' ? (
            <Icon
              name="shield"
              size={30}
            />
          ) : (
            <Icon name="cross" />
          )}
        </Title>
      }
      value={
        <InfoBlockValueSmall
          value={
            <Status>
              <StatusIcon>
                {details.current_status === 'good' ? (
                  <Icon
                    name="checkmark-ssl"
                    size={60}
                    color="#fff"
                  />
                ) : (
                  <Icon
                    name="cross-thin"
                    size={50}
                    color="#fff"
                  />
                )}
              </StatusIcon>
              <StatusText>{statusMap[details.current_status]}</StatusText>
            </Status>
          }
        />
      }
      footer={<InfoBlockFooterBase>Expires: {getUiDateShort(details.expiry_date)}</InfoBlockFooterBase>}
    />
  );
}
