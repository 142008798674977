import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import selectors from '../../redux/selectors/selectors';
import { getCronJobEvents } from '../../redux/actions/cronJobsActions';
import { getUiDateTimeShort } from '../../shared/lib/dates';
import moment from 'moment';
import styled from 'styled-components';
import { Table } from 'antd';
import { Body, Container, Head, Title } from '../../shared/styled/styled';
import secondsToTime from 'shared/lib/secondsToTime';

const IncidentTable = styled.div`
  width: 100%;
  padding: 36px;
`;

export function CronIncidentsTableFeature({ jobId }) {
  const [isLoading, setIsLoading] = useState(false);
  const events = useSelector(selectors.cronMonitorEvents);

  useEffect(() => {
    setIsLoading(true);
    getCronJobEvents(jobId).then(() => {
      setIsLoading(false);
    });
  }, []);

  const columns = [
    {
      title: 'Started',
      dataIndex: 'started_at',
      render: (item) => {
        return <div>{getUiDateTimeShort(item)}</div>;
      },
    },
    {
      title: 'Duration',
      render: (item) => {
        if (!item.ended_at) {
          return <div>Ongoing</div>;
        }
        const duration = moment(item.ended_at).diff(moment(item.started_at), 'seconds');
        const durationHuman = secondsToTime({ seconds: duration, short: true });
        return <div>{durationHuman}</div>;
      },
    },
  ];
  return (
    <>
      <Container>
        <Head>
          <Title>Incident Report</Title>
        </Head>
        <Body>
          <IncidentTable>
            <Table
              loading={isLoading}
              rowKey={(record) => record.id}
              pagination={{
                hideOnSinglePage: true,
                defaultPageSize: 10,
                showSizeChanger: false,
                position: ['bottomRight'],
              }}
              dataSource={events}
              columns={columns}
              scroll={{ x: '100%' }}
            />
          </IncidentTable>
        </Body>
      </Container>
    </>
  );
}
