import Modal from '../../shared/components/Modal';
import ModalBody from '../../shared/components/ModalBody';
import ModalFooter from '../../shared/components/ModalFooter';
import React from 'react';

export function CronRecentHeadersFeature({ show, setShow, headers }) {
  const renderHeaders = () => {
    if (!headers) {
      return null;
    }
    return Object.keys(headers).map((key) => {
      return (
        <div className={'http-header'}>
          <div className={'key'}>{key}</div>
          <div className={'value'}>{headers[key]}</div>
        </div>
      );
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}>
      <ModalBody>
        <div className={'http-headers'}>{renderHeaders()}</div>
      </ModalBody>
      <ModalFooter />
    </Modal>
  );
}
