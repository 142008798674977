import React from 'react';

const BoundTextarea = React.memo(({
                                    formId,
                                    id,
                                    className,
                                    placeholder,
                                    rows,
                                    label,
                                    format,
                                    value,
                                    required,
                                    disabled,
                                    autoFocus,
                                    type,
                                    autoComplete,
                                    onChange,
                                    onKeyPress,
                                    noLabel
                                  }) => {
  return (
    <div
      className={`bound-input ${className || ''}`}
    >
      {!!label && !noLabel && <div className={'label'}>{label}</div>}
      <textarea
        id={id}
        className={className}
        rows={rows || 4}
        placeholder={placeholder}
        value={value}
        required={required}
        disabled={disabled}
        autoFocus={autoFocus || true}
        spellCheck={true}
        autoComplete={autoComplete || 'off'}
        onChange={onChange}
      />
    </div>
  );
});

export default BoundTextarea;
