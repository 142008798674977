import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Icon } from 'shared/components';
import styled from 'styled-components';
import ConfirmModal from 'shared/components/ConfirmModal';
import { deleteMonitor, putMonitor } from 'redux/actions/jobsActions';
import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';
import { Preloader } from 'shared/components/preloader/Preloader';

const Container = styled.div`
  position: relative;
  display: flex;
  border: solid 1px #06abc1;
  width: 100%;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  z-index: 100;
`;

const Selector = styled.div`
  display: flex;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 24px;
  cursor: pointer;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #001c4f;
  padding: 12px;
`;

const DropdownItem = styled.div`
  padding: 6px;
  cursor: pointer;
  &:hover {
    background-color: #06abc1;
  }
`;

const Value = styled.div`
  font-weight: 700;
  width: 100%;
  color: #fff;
  text-align: center;
`;

export function SiteActionButtonFeature() {
  const monitor = useSelector(selectors.httpMonitorDetails);

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showPauseConfirm, setShowPauseConfirm] = useState(false);
  const isPaused = monitor?.state === 'paused';

  const navigate = useNavigate();

  const openModalEdit = () => {
    navigate('edit');
    setShow(false);
  };

  const openModalDelete = () => {
    setShowDeleteConfirm(true);
    setShow(false);
  };

  const openModalPause = () => {
    setShowPauseConfirm(true);
    setShow(false);
  };

  const getPausedTitle = () => {
    return isPaused ? 'Unpause' : 'Pause';
  };

  const toggleDropdown = () => setShow(!show);
  return (
    <>
      <Container>
        <Selector onClick={toggleDropdown}>
          <Value>Action</Value>
          <Arrow>
            <Icon
              name="chevron-down"
              size={16}
              color="#fff"
            />
          </Arrow>
        </Selector>

        {show && (
          <Dropdown>
            <DropdownItem onClick={openModalEdit}>Edit Site</DropdownItem>
            <DropdownItem onClick={openModalPause}>{getPausedTitle()} Site</DropdownItem>
            <DropdownItem onClick={openModalDelete}>Delete Site</DropdownItem>
          </Dropdown>
        )}
      </Container>

      {showDeleteConfirm && (
        <ConfirmModal
          title="Delete Site"
          show={showDeleteConfirm}
          text={
            <div>
              <div style={{ textAlign: 'center' }}>
                Are you sure you want to delete: {monitor?.friendly_name}?
              </div>
              <br />
              <div style={{ textAlign: 'center' }}>Note: This action cannot be undone</div>
            </div>
          }
          onOk={() => {
            deleteMonitor(monitor.id, () => {
              setShowDeleteConfirm(false);
              navigate(`/dashboard`);
            });
          }}
          okText="Yes"
          onCancel={() => {
            setShowDeleteConfirm(false);
          }}
          cancelText="No"
        />
      )}

      {showPauseConfirm && (
        <ConfirmModal
          title="Pause Site"
          show={showPauseConfirm}
          text={
            <div>
              {isLoading && <Preloader lower />}
              <p>
                Are you sure you want to {getPausedTitle()}: {monitor?.friendly_name}
              </p>
            </div>
          }
          onOk={() => {
            setIsLoading(true);
            putMonitor(monitor.id, {
              ...monitor,
              group_notification_ids: monitor.group_notifications.map((item) => item.id),
              timeout_ms: monitor.timeout_ms / 1000,
              run_interval_seconds: monitor.run_interval_seconds / 60,
              state: isPaused ? 'active' : 'paused'
            }).then(() => {
              setIsLoading(false);
            });
            setShowPauseConfirm(false);
          }}
          okText="Yes"
          onCancel={() => {
            setShowPauseConfirm(false);
          }}
          cancelText="No"
        />
      )}
    </>
  );
}
