import createPersistedReducer from 'redux/lib/createPersistedReducer';
import mergeByProp from 'shared/lib/mergeByProp';

const initialState = {
  monitors: [],
  monitorDetails: {},
  monitorChecks: {},
  monitorEvents: {},
  monitorEventDetails: {},
  totals: {},
  filterStatus: [],
  searchQuery: '',
  tlsDetails: {},
  dnsDetails: {},
  siteUptimePercentage: 0,
  performanceData: []
};

const persistConfig = {
  key: 'jobsReducer',
  whitelist: [],
  transforms: []
};

export default createPersistedReducer(
  initialState,
  {
    GET_MONITOR_CHECKS_SUCCESS: (state, payload) => ({
      ...state,
      monitorChecks: {
        ...state.monitorChecks,
        [payload?.meta?.jobId]: payload?.data
      }
    }),

    GET_MONITOR_DATA_SUCCESS: (state, payload) => {
      return {
        ...state,
        monitorDetails: {
          ...payload?.data
        }
      };
    },

    GET_MONITORS_SUCCESS: (state, payload) => {
      return {
        ...state,
        monitors: payload?.data?.jobs || [],
        totals: payload?.data?.totals || []
      };
    },

    GET_MORE_MONITORS_SUCCESS: (state, payload) => {
      return {
        ...state,
        monitors: [...state.monitors, ...payload?.data?.jobs]
      };
    },

    SEARCH_MONITORS_SUCCESS: (state, payload) => {
      return {
        ...state,
        monitors: payload?.data?.jobs || []
      };
    },

    UPSERT_MONITOR_SUCCESS: (state, payload) => {
      return {
        ...state,
        monitors: mergeByProp('job_id', state?.monitors, [payload?.data]),
        monitorDetails: payload?.data
      };
    },

    DELETE_MONITOR_SUCCESS: (state, payload) => ({
      ...state,
      monitors: state.monitors.filter((m) => m.id !== payload.id)
    }),

    GET_MONITOR_EVENTS_SUCCESS: (state, payload) => ({
      ...state,
      monitorEvents: {
        ...state.monitorChecks,
        [payload?.meta?.jobId]: payload?.data
      }
    }),

    CLEAR_MONITOR_EVENT_DETAILS: (state, payload) => ({
      ...state,
      monitorEventDetails: null
    }),

    GET_MONITOR_EVENT_DETAILS_SUCCESS: (state, payload) => ({
      ...state,
      monitorEventDetails: payload.data
    }),

    SET_FILTER_STATUS: (state, payload) => {
      return {
        ...state,
        filterStatus: payload
      };
    },

    TOGGLE_FILTER_STATUS: (state, payload) => {
      if (state.filterStatus.includes(payload)) {
        return {
          ...state,
          filterStatus: state.filterStatus.filter((s) => s !== payload)
        };
      }
      return {
        ...state,
        filterStatus: [payload]
      };
    },
    RESET_FILTER_STATUS: (state) => {
      return {
        ...state,
        filterStatus: []
      };
    },
    SET_SEARCH_QUERY: (state, payload) => {
      return {
        ...state,
        searchQuery: payload
      };
    },

    GET_TLS_STATE_SUCCESS: (state, payload) => {
      return {
        ...state,
        tlsDetails: payload.data
      };
    },

    GET_DNS_STATE_SUCCESS: (state, payload) => {
      return {
        ...state,
        dnsDetails: payload.data
      };
    },

    GET_SITE_UPTIME_SUCCESS: (state, payload) => {
      return {
        ...state,
        siteUptimePercentage: payload.data?.uptime_percentage || 0
      };
    },

    GET_PERFORMANCE_DATA_SUCCESS: (state, payload) => {
      return {
        ...state,
        performanceData: payload.data
      };
    }
  },
  persistConfig
);
