import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';
import styled from 'styled-components';
import { Icon } from 'shared/components';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const FilterItem = styled.div`
  padding: 10px 24px;
  border-radius: 40px;
  background-color: transparent;
  border: solid 2px #06abc1;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 12px;
  &:last-child {
    margin-right: 0;
  }
`;

const FilterLabel = styled.div`
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-right: 48px;
`;

const FilterItemIcon = styled.div`
  cursor: pointer;
  display: flex;
`;

const FilterItemText = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin-left: 12px;
  text-transform: capitalize;
`;

export function SiteListFilterActiveFeature() {
  const filter = useSelector(selectors.statusFilter);

  return (
    <Container>
      {filter.length > 0 && <FilterLabel>Filter:</FilterLabel>}
      {filter &&
        filter.map((item) => {
          return (
            <FilterItem key={`filter_${item}`}>
              <FilterItemIcon
                onClick={() => {
                  // TODO: Fix this
                  // toggleFilterStatus(item);
                }}>
                <Icon name="cross-thin" />
              </FilterItemIcon>
              <FilterItemText>{item}</FilterItemText>
            </FilterItem>
          );
        })}
    </Container>
  );
}
