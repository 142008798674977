import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';
import { InfoBlock, InfoBlockValueNegative } from 'shared/components';
import { listMonitors } from '../../redux/actions/monitorsActions';
import { useState } from 'react';
import { LoadingSpinner } from '../../shared/components/LoadingSpinner';

export function BlockDownSites() {
  const [loading, setLoading] = useState(false);
  const monitorTotals = useSelector(selectors.monitorsTotals);
  return (
    <InfoBlock
      title="Sites Down"
      value={
        loading ? (
          <LoadingSpinner />
        ) : (
          <InfoBlockValueNegative
            value={<div style={{ cursor: 'pointer' }}>{monitorTotals.down}</div>}
          />
        )
      }
      onClick={() => {
        setLoading(true);
        listMonitors({ statusFilter: 'failed' }).then(() => {
          setLoading(false);
        });
      }}
    />
  );
}
