import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import selectors from 'redux/selectors/selectors';
import { Icon } from 'shared/components';

export function AddMonitorFeature() {
  const navigate = useNavigate();
  const canAddSite = useSelector(selectors.canAddSite);
  const openModal = () => {
    navigate('/dashboard/site/add');
  };
  return (
    <Button
      id={'add-edit-monitor-btn'}
      disabled={!canAddSite}
      onClick={openModal}
      className="thick-boy"
      icon={
        <Icon
          name="plus"
          size={11}
          style={{ marginTop: 3 }}
        />
      }>
      Add Monitor
    </Button>
  );
}
