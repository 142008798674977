import React from 'react'
import { LayoutLogin } from 'shared/layout';
import { SignUpWidget } from 'widgets/sign-up/sign-up.widget';

export const SignUpPage = () => {
  return (
    <LayoutLogin>
    <SignUpWidget />
  </LayoutLogin>
  )
}
