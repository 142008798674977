import { PHONECODES_EN } from './phone-codes';
import { Select } from 'antd';

export function PhoneCodeSelector({ defaultValue, setValue }) {
  const options = Object.values(PHONECODES_EN);
  return (
    <div>
      <Select
        showSearch
        style={{
          width: 100,
          height: 40,
          border: 'solid 2px #06abc1',
          borderRadius: 4
        }}
        onChange={setValue}
        defaultValue={defaultValue}
        placeholder="Search"
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
        filterSort={(optionA, optionB) => (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.value ?? '').toLowerCase())}
        options={options}
        optionRender={(option) => {
          return (
            <div>
              {option.data.flag} +{option.value} {option.label}{' '}
            </div>
          );
        }}
        labelRender={({ label, value }) => {
          return (
            <div>
              {PHONECODES_EN[value]?.flag} +{value}
            </div>
          );
        }}
        popupMatchSelectWidth={false}
        dropdownStyle={{ width: 200 }}
      />
    </div>
  );
}
