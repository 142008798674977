import createPersistedReducer from '../lib/createPersistedReducer';

const initialState = {
  integrations: []
};

const persistConfig = {
  key: 'integrationsReducer',
  whitelist: [],
  transforms: []
};

export default createPersistedReducer(
  initialState,
  {
    GET_INTEGRATIONS_SUCCESS: (state, payload) => ({
      ...state,
      integrations: payload?.data
    })
  },
  persistConfig
);
