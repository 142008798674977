import { Button } from 'antd';
import React, { useState } from 'react';
import { requestResetPassword } from 'redux/actions/userActions';

export function RequestResetPasswordButtonFeature({ form, onReset, onError, onSuccess }) {
  const [submitting, setSubmitting] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    form.validate();
    if (!form.isValid) {
      return;
    }
    setSubmitting(true);
    requestResetPassword({ ...form.state }, (data, err) => {
      setSubmitting(false);
      if (err) {
        onError(err.message);
        return;
      }
      onSuccess();
      onReset();

    });
  };

  return (
    <Button
      block
      loading={submitting}
      className={'primary'}
      onClick={handleFormSubmit}
      disabled={submitting}>
      Reset
    </Button>
  );
}
