import styled from "styled-components";

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 12px; */
  margin: 0 34px 12px 34px;
  /* padding: 0 12px 12px 12px; */
  padding-bottom: 12px ;
  border-bottom: solid 1px #787878;
`;

export const Key = styled.div`
  display: flex;
`;
export const Value = styled.div`
  display: flex;
  
`;
export const ValueCapitalized = styled.div`
  text-transform: capitalize;
`;
export const Footer = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: center;
`;
