import moment from 'moment';
import { Tooltip } from 'antd';

const fullDateFormat = 'D MMM, YYYY hh:mm:ss a';

export const getUiDateTimeShort = (date) => {
  if (!date) return '';
  return moment(date).format('MMM D, YYYY hh:mm a');
};

export const getUiRelativeTime = (date) => {
  if (!date) return '';
  const fullDate = moment(date).format(fullDateFormat);
  return (<Tooltip title={fullDate}>{moment(date).fromNow()}</Tooltip>);
};

export const getUiDateShort = (date) => {
  if (!date) return '';
  const fullDate = moment(date).format(fullDateFormat);
  return (<Tooltip title={fullDate}>{moment(date).format('D MMM, YYYY')}</Tooltip>);
};

export const getUiDatePerfChart = (date) => {
  if (!date) return '';
  return moment(date).format('D MMM, YYYY hh:mm');
};

export const getLastPeriodByDays = (count) => {
  const end = moment();
  const start = end.clone().subtract(count, 'days');
  return {
    start,
    end
  };
};

export const getLast24Hours = () => getLastPeriodByDays(1);
export const getLast7Days = () => getLastPeriodByDays(7);
export const getLast30Days = () => getLastPeriodByDays(30);
export const getLast90Days = () => getLastPeriodByDays(90);
