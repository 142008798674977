import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { action } from '../../redux/lib/api';

const stableObject = {};
const stableArray = [];

const useBoundForm = ({ id, initialState, callback }) => {
  const randomId = Math.floor(Math.random() * 1000000);
  const formId = useRef(id || randomId);

  const state = useSelector(state => (state.ui.boundForms[formId.current] || stableObject).state || stableObject);
  const invalids = useSelector(state => (state.ui.boundForms[formId.current] || stableObject).invalids || stableArray);
  const empties = useSelector(state => (state.ui.boundForms[formId.current] || stableObject).empties || stableArray);
  const validating = useSelector(state => (state.ui.boundForms[formId.current] || stableObject).validating);

  const isValid = invalids.length < 1 && empties.length < 1;

  const setState = (payload) => {
    if (typeof payload === 'function') {
      action('uiPatchFormState', { formId: formId.current, state: payload(state) });
      return;
    }
    action('uiPatchFormState', { formId: formId.current, state: payload });
  };

  useEffect(() => {
    action('uiPatchFormState', { formId: formId.current, state: initialState || {} });
    return () => {
      action('uiClearFormState', formId.current);
    };
  }, []);

  useEffect((a, b) => {
    if (validating === false && (invalids.length > 0 || empties.length > 0)) {
      const id = empties[0] || invalids[0];
      const input = document.querySelector(`input[name="${id}"]`);
      if (input) {
        input.focus();
      }
    }
  }, [validating]);

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    if (callback) {
      callback();
    }
  };

  const set = (key, value) => {
    if (typeof key === 'object') {

      return;
    }
    setState({ [key]: value });
    const empty = !value;
    action(empty ? 'uiSetFormEmptyField' : 'uiRemoveFormEmptyField', {
      formId: formId.current,
      fieldId: key
    });
  };

  const validate = () => {
    action('uiToggleFormValidation', { formId: formId.current, validating: true });
    setTimeout(() => {
      action('uiToggleFormValidation', { formId: formId.current, validating: false });
    }, 0);
  };

  const invalidate = (id, data = {}) => {
    action('uiSetFormInvalidField', {
      formId: formId.current,
      fieldId: id,
      message: data.message
    });
  };

  const reset = (state) => {
    action('uiClearFormState', formId.current);
    action('uiPutFormState', { formId: formId.current, state: state || initialState || {} });
    setTimeout(() => {
      action('uiToggleFormReset', { formId: formId.current, reset: true });
      setTimeout(() => {
        action('uiToggleFormReset', { formId: formId.current, reset: false });
      }, 0);
    }, 0);
  };

  return {
    id: formId.current,
    handleSubmit,
    isValid,
    invalidate,
    reset,
    invalids,
    empties,
    set,
    state,
    setState,
    validate
  };
};

export default useBoundForm;
