import styled from 'styled-components';
import { Icon } from '..';
import { useState } from 'react';

const Container = styled.div`
  position: relative;
  display: flex;
`;

const Selector = styled.div`
  display: flex;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 24px;
  cursor: pointer;
`;

const DropdownContainer = styled.div`
  min-width: 160px;
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  background-color: #001c4f;
  padding: 12px;
`;

const Value = styled.div`
  font-weight: 700;
  width: 100%;
  color: #fff;
  text-align: center;
`;

export function Dropdown({ children, trigger }) {
  const [show, setShow] = useState(false);
    const toggle = () => setShow(!show);
  return (
    <Container>
      <Selector
        onClick={() => {
            toggle();
        }}>
        <Value>{trigger}</Value>
        <Arrow>
          <Icon
            name="chevron-down"
            size={16}
            color="#fff"
          />
        </Arrow>
      </Selector>
      {show && <DropdownContainer onClick={() => setShow(false)}>{children}</DropdownContainer>}
    </Container>
  );
}
