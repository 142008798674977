import React, { useEffect, useRef } from 'react';
import Portal from './Portal';
import { Icon } from '.';

const addBodyClass = (className) => document.body.classList.add(className);
const removeBodyClass = (className) => document.body.classList.remove(className);

const Modal = ({ show, children, height, onHide = () => {}, className, portal = false, maxWidth, title }) => {
  const id = useRef(undefined);
  if (!id.current) {
    id.current = `modal-${Math.floor(Math.random() * 100000)}`;
  }

  const handleWindowKeydown = (e) => {
    if (e.key === 'Escape') {
      onHide();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleWindowKeydown);
    return () => window.removeEventListener('keydown', handleWindowKeydown);
  }, []);

  const PortalComponent = portal ? Portal : React.Fragment;
  const portalProps = portal ? { id: id.current } : {};

  useEffect(() => {
    show ? addBodyClass('modal-open') : removeBodyClass('modal-open');
    return () => removeBodyClass('modal-open');
  }, [show]);

  return show && 
    <PortalComponent {...portalProps}>
      <div className={`modal-component ${show ? 'active' : ''} ${className || ''}`}>
        <div
          className="modal-backing"
          onClick={onHide}></div>
        <div
          className={`modal-content`}
          style={{ height, maxWidth }}>
          <div className={'inner-wrapper'}>
            <div className={'header'}>
              <div className={'heading'}>{title}</div>
            </div>
            <div className="modal-close-icon">
              <Icon
                name="cross-thin"
                size={24}
                color="#06abc1"
                onClick={onHide}
              />
            </div>

            {children}
          </div>
        </div>
      </div>
    </PortalComponent>
  ;
};

export default Modal;
