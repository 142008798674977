import { useEffect } from 'react';

export const useImportPromotekit = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.promotekit.com/promotekit.js';
    script.setAttribute('data-promotekit', '94d66bac-2098-4f05-aade-0620e8439c22');
    document.body.appendChild(script);
  }, []);
};
