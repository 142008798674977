import { del, put, get, post } from 'redux/lib/api';

export const getNotificationGroups = () => {
  return get('notification_group', {
    action: 'GET_NOTIFICATION_GROUPS'
  });
};

export const getNotificationGroup = ({ id }) => {
  return get(`notification_group/${id}`, {
    action: 'GET_NOTIFICATION_GROUP'
  });
};

export const createNotificationGroup = ({
                                          friendly_name,
                                          delay_send_after_minutes,
                                          resend_every_minutes
                                        }, callback) => {
  return post(`notification_group`, {
    data: {
      friendly_name,
      delay_send_after_minutes: parseFloat(delay_send_after_minutes),
      resend_every_minutes: parseFloat(resend_every_minutes)
    },
    action: 'CREATE_NOTIFICATION_GROUP',
    callback
  });
};

export const updateNotificationGroup = ({
                                          id,
                                          friendly_name,
                                          delay_send_after_minutes,
                                          resend_every_minutes
                                        }, callback) => {
  return put(`notification_group/${id}`, {
    data: {
      friendly_name,
      delay_send_after_minutes: parseFloat(delay_send_after_minutes),
      resend_every_minutes: parseFloat(resend_every_minutes)
    },
    action: 'UPDATE_NOTIFICATION_GROUP',
    callback
  });
};

export const deleteNotificationGroup = (id, callback) => {
  return del(`notification_group/${id}`, {
    action: 'DELETE_NOTIFICATION_GROUP',
    onResponse: () => {
      return {
        status: 200,
        data: {
          id: id
        }
      };
    },
    callback
  });
};

export const createNotification = ({ notification_group_id, type, details }, callback) => {
  return post(`notification`, {
    data: {
      notification_group_id,
      type,
      details
    },
    meta: {
      notification_group_id
    },
    action: 'CREATE_NOTIFICATION',
    callback
  });
};

export const updateNotification = ({ notification_group_id, id, type, details }, callback) => {
  return put(`notification/${id}`, {
    data: {
      type,
      details
    },
    meta: {
      notification_group_id,
      id
    },
    action: 'UPDATE_NOTIFICATION',
    callback
  });
};

export const getNotification = (notificationId, callback) => {
  return get(`notification/${notificationId}`, {
    action: 'GET_NOTIFICATION',
    callback
  });
};

export const deleteNotification = (notificationId, callback) => {
  return del(`notification/${notificationId}`, {
    action: 'DELETE_NOTIFICATION',
    callback
  });
};
