import React, { useState } from 'react';
import { logoutAndRedirect } from 'redux/actions/userActions.js';
import styled from 'styled-components';
import selectors from 'redux/selectors/selectors';
import { useSelector } from 'react-redux';
import { Dropdown } from 'shared/components/dropdown/dropdown';
import { DropdownItem } from 'shared/components/dropdown/dropdown-item';
import { getStripeUrl } from 'redux/actions/eCommerceActions';
import { Preloader } from 'shared/components/preloader/Preloader';

const HeaderItems = styled.div`
    display: flex;
`;

const HeaderItem = styled.div`
    border: solid 1px #06abc1;
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 6px 12px;
    margin-right: 12px;

    &:last-child {
        margin-right: 0;
    }
`;

const HeaderAction = styled.div`
    padding: 6px 12px;
    cursor: pointer;
`;

const Name = styled.div``;

export function Header() {
  const [isLoading, setIsLoading] = useState(false);
  const userName = useSelector(selectors.userFullName) || 'Site Qwality User';
  const handleSignOutClick = () => {
    logoutAndRedirect();
  };

  const handleSignup = () => {
    setIsLoading(true);
    getStripeUrl()
      .then((request) => {
        window.location.href = request.url;
      }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <Preloader over />}
      <div className="app-header">
        <div className="left"></div>
        <div className="right">
          <HeaderItems>
            <HeaderItem>
              <Dropdown
                trigger={
                  <HeaderAction>
                    <Name>{userName}</Name>
                  </HeaderAction>
                }>
                <DropdownItem>
                  <div onClick={handleSignup}>Manage Plan</div>
                </DropdownItem>
                <DropdownItem>
                  <div onClick={handleSignOutClick}>Sign Out</div>
                </DropdownItem>
              </Dropdown>
            </HeaderItem>
          </HeaderItems>
        </div>
      </div>
    </>
  );
}
