import { useSelector } from 'react-redux';
import selectors from '../../redux/selectors/selectors';
import { Icon, PageWithLoading } from '../../shared/components';
import FloatingContactForm from '../../shared/components/FloatingContactForm';
import { BlockIntegration } from '../../features/integrations/block-integration';
import { Divider, notification, Table, Tooltip } from 'antd';
import styled from 'styled-components';
import { Head, Title } from '../../shared/styled/styled';
import { ModalIntegrationSlack } from '../../features/integrations/modal-integration-slack';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { Body } from '../../shared/styled/styled';
import { getIntegrations } from '../../redux/actions/integrationsActions';
import { useNavigate } from 'react-router-dom';
import { getUiDateShort } from '../../shared/lib/dates';
import { ModalIntegrationWebhook } from '../../features/integrations/modal-integration-webhook';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Block = styled.div`
  position: relative;
  border: solid 2px #06abc1;
  width: 100%;
  padding: 24px;
`;

const Row = styled.div`
  padding: 24px 34px;
  display: flex;
  justify-content: space-evenly;
`;

const IntegrationsTable = styled.div`
  width: 100%;
  padding: 36px;
  padding-top: 12px;
`;

export function IntegrationsPage() {
  const navigate = useNavigate();
  const { actionId, callback } = useParams();
  const isInitializing = useSelector(selectors.isInitializing);
  const integrations = useSelector(selectors.integrations);

  const [fetchingIntegrations, setFetchingIntegrations] = useState(true);
  const [showSlackModal, setShowSlackModal] = useState(false);
  const [showWebhookModal, setShowWebhookModal] = useState(false);

  useEffect(() => {
    setFetchingIntegrations(true);
    getIntegrations().then(() => {
      setFetchingIntegrations(false);
    });
  }, [isInitializing]);

  const integrationTypeMap = {
    slack: 'Slack',
    webhook: 'Webhook'
  };

  const getIntegrationDetails = (record) => {
    switch (record?.type) {
      case 'slack':
        return <></>;
      case 'webhook':
        return (
          <div>
            <div>{record?.details?.webhook_url}</div>
          </div>
        );
      default:
        return <div>Unknown</div>;
    }
  };

  const columns = [
    {
      title: 'Type',
      render: (record) => {
        return (
          <Tooltip title={integrationTypeMap[record?.type] || record?.type}>
            <Icon
              name={record?.type}
              size={24}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'Name',
      dataIndex: 'friendly_name'
    },
    {
      title: 'Created',
      render: (record) => {
        return <div>{getUiDateShort(record?.created_at)}</div>;
      }
    },
    {
      title: 'Details',
      render: (record) => {
        return getIntegrationDetails(record);
      }
    }
  ];

  useEffect(() => {
    switch (actionId) {
      case 'slack':
        if (actionId && !callback) {
          setShowSlackModal(true);
        } else if (callback) {
          setShowSlackModal(true);
          // get `code` from query params
          const urlParams = new URLSearchParams(window.location.search);
          const code = urlParams.get('code');
          if (!code) {
            notification.error({
              message: 'Error',
              description: 'Slack integration failed'
            });
            setShowSlackModal(false);
            navigate('/integrations');
            return;
          }
        }
        break;
      case 'webhook':
        setShowWebhookModal(true);
        break;
      default:
        setShowSlackModal(false);
        setShowWebhookModal(false);
        break;
    }
  }, [isInitializing, actionId, callback]);

  return (
    <PageWithLoading isLoading={isInitializing}>
      <Divider />
      <Divider />
      <Divider />
      <Container>
        <Block>
          <Head style={{ height: 0 }}>
            <Title>Add Integrations</Title>
          </Head>
          <Row>
            <BlockIntegration
              icon={'slack'}
              title={'Slack'}
              action={'slack'}
            />
            <BlockIntegration
              icon={'webhook'}
              title={'Webhook'}
              action={'webhook'}
            />
          </Row>
        </Block>
      </Container>
      <Divider />
      <Divider />
      <Divider />
      <Container>
        <Block>
          <Head style={{ height: 0 }}>
            <Title>Existing Integrations</Title>
          </Head>
          <Body>
            <IntegrationsTable>
              <Table
                loading={fetchingIntegrations}
                pagination={{
                  hideOnSinglePage: true,
                  defaultPageSize: 10,
                  showSizeChanger: false,
                  position: ['bottomRight']
                }}
                dataSource={integrations}
                columns={columns}
                rowKey={(record) => record.id}
                scroll={{ x: '100%' }}
              />
            </IntegrationsTable>
          </Body>
        </Block>
      </Container>
      <ModalIntegrationSlack show={showSlackModal} />
      <ModalIntegrationWebhook show={showWebhookModal} />
      <FloatingContactForm />
    </PageWithLoading>
  );
}
