import React from 'react'
import classNames from 'classnames'
import './Preloader.scss'
import {Spinner} from "./Spinner";
export function Preloader(props) {
  const { message, onClick, className, backgroundColor, over, transparent, lower } = props

  const classNameComputed = classNames('preloader-wrapper', className, {
    'preloader-wrapper_over': over,
    'preloader-wrapper_lower': lower,
    'preloader-wrapper_transparent': transparent,
  })

  return (
    <div
      tabIndex={-1}
      role="button"
      onKeyDown={onClick}
      className={classNameComputed}
      onClick={onClick}
      style={{ backgroundColor }}
    >
      <div className="preloader-wrapper__body">
        <Spinner />
        {message && <div className="preloader-wrapper__message">{message}</div>}
      </div>
    </div>
  )
}
