import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {
  Container,
  Body,
  Item,
  Key,
  Value,
  PerformanceChart
} from '../../shared/styled/styled';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';
import { DateRangePicker } from 'shared/components';
import { setPeriodPerformanceAction } from 'redux/actions/siteActions';
import { getPerformanceData } from 'redux/actions/jobsActions';
import { getUiDatePerfChart } from 'shared/lib/dates';
import { options } from './performance-chart-options';
import moment from 'moment';
import { dateRangePickerOptionsPerformance } from 'shared/components/date-range-picker/lib';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler);

export function SiteOverallPerformanceFeature({ jobId }) {
  const checks = useSelector(selectors.performanceData);
  const period = useSelector(selectors.sitePeriodPerformance);
  const widthContainer = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const average = checks.length
    ? parseFloat(
      checks.reduce((acc, n) => acc + n?.avg_response_time_ms, 0) / checks.length
    ).toFixed(0)
    : '-';
  const minimum = checks.length
    ? parseFloat(Math.min(...checks.map((n) => n?.avg_response_time_ms))).toFixed(0)
    : '-';
  const maximum = checks.length
    ? parseFloat(Math.max(...checks.map((n) => n?.avg_response_time_ms))).toFixed(0)
    : '-';

  const timeFrames = (d) => {
    switch (d) {
      case 1:
        return 'day';
      case 7:
        return 'week';
      case 30:
        return 'month';
      default:
        return 'year';
    }
  };

  const duration = moment.duration(moment(period?.end).diff(period?.start)).days();
  const timeFrame = timeFrames(duration);

  useEffect(() => {
    setIsLoading(true);
    getPerformanceData({ job_id: jobId, timeFrame }).then(() => {
      setIsLoading(false);
    });
  }, [period]);

  const data2 = {
    labels: checks.map((n) => getUiDatePerfChart(n?.time_period)),
    data: checks.map((n) => ({
      y: n?.avg_response_time_ms,
      status: n?.success_rate === 100 ? 'success' : 'failed',
      moment: getUiDatePerfChart(n?.time_period),
      percent: n?.success_rate
    })),
    status: checks.map((n) => (n?.success_rate === 100 ? 'success' : 'failed'))
  };

  const data = {
    labels: data2.labels,
    datasets: [
      {
        data: data2.data,
        borderWidth: 2,
        borderColor: '#1875F0',
        backgroundColor: (context) => {
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            // eslint-disable-next-line
            _,
            chartArea: { bottom, top }
          } = context.chart;
          const gradient = ctx.createLinearGradient(0, top, 0, bottom);
          gradient.addColorStop(0, 'rgba(24,117,240, 0.3)');
          gradient.addColorStop(1, 'rgba(24,117,240, 0.01)');
          return gradient;
        },
        pointBackgroundColor: (context) => {
          let index = context.dataIndex;
          let value = context.dataset?.data[index]?.status;
          return value === 'failed' ? 'red' : 'green';
        },
        fill: true
      }
    ]
  };

  return (
    <Container>
      <div className={'site-perf-header'}>
        <div className={'site-perf-header-title'}>Overall Site Performance</div>
        <div className={'site-perf-date-range-picker'}>
          <DateRangePicker
            customOptions={[
              {
                label: 'All Time',
                value: { start: 'year', end: 'year' }
              }
            ]}
            options={dateRangePickerOptionsPerformance}
            loading={isLoading}
            defaultValue={period}
            onChange={(p) => {
              setPeriodPerformanceAction(p);
            }}
          />
        </div>
      </div>

      <Body ref={widthContainer}>
        <PerformanceChart>
          <Line
            ref={widthContainer}
            options={options}
            data={data}
          />
        </PerformanceChart>
      </Body>

      <div className={'site-perf-footer'}>
        <Item>
          <Key>Minimum:</Key>
          <Value>{minimum} ms</Value>
        </Item>

        <Item>
          <Key>Average:</Key>
          <Value>{average} ms</Value>
        </Item>

        <Item>
          <Key>Maximum:</Key>
          <Value>{maximum} ms</Value>
        </Item>
      </div>
    </Container>
  );
}
