import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  border: solid 1px #06abc1;
  border-radius: 2px;
  width: 100%;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  z-index: 100;
`;

export const Select = styled.div`
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #031f3d;
  padding: 12px;
`;
export const DropdownItem = styled.div`
  padding: 6px;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
  &:hover {
    background-color: #06abc1;
  }
`;

export const Value = styled.div`
  display: flex;
  font-weight: 700;
  flex: 1;
`;

export const OpenIcon = styled.div`
  display: flex;
`;

export const CalendarIcon = styled.div`
  display: flex;
  margin-right: 12px;
`;

export const ExternalDatePicker = styled.div`
  height: 0;
  padding: 0;
  width: 0;
  left: 0;
  top: 0;
  overflow: hidden;
  .ant-picker.ant-picker-range.custom-picker .ant-picker-input {
    /* visibility: hidden; */
  }
`;


export const ExternalDatePickerFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #031f3d;
  padding: 6px 0;
`;