import { useSelector } from 'react-redux';
import selectors from '../../redux/selectors/selectors';
import styled from 'styled-components';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Icon } from '../../shared/components';
import ConfirmModal from '../../shared/components/ConfirmModal';
import { deleteCronMonitor, putCronMonitor } from '../../redux/actions/cronJobsActions';

const Container = styled.div`
  position: relative;
  display: flex;
  border: solid 1px #06abc1;
  width: 100%;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  z-index: 100;
`;

const Selector = styled.div`
  display: flex;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 24px;
  cursor: pointer;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #001c4f;
  padding: 12px;
`;

const DropdownItem = styled.div`
  padding: 6px;
  cursor: pointer;
  &:hover {
    background-color: #06abc1;
  }
`;

const Value = styled.div`
  font-weight: 700;
  width: 100%;
  color: #fff;
  text-align: center;
`;

export function CronActionButtonFeature() {
  const monitor = useSelector(selectors.cronMonitorDetails);

  const [show, setShow] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showPauseConfirm, setShowPauseConfirm] = useState(false);
  const isPaused = monitor?.state === 'paused';

  const navigate = useNavigate();

  const openModalEdit = () => {
    navigate('edit');
    setShow(false);
  };

  const openModalDelete = () => {
    setShowDeleteConfirm(true);
    setShow(false);
  };

  const openModalPause = () => {
    setShowPauseConfirm(true);
    setShow(false);
  };

  const getPausedTitle = () => {
    return isPaused ? 'Unpause' : 'Pause';
  };

  const toggleDropdown = () => setShow(!show);

  return (
    <>
      <Container>
        <Selector onClick={toggleDropdown}>
          <Value>Action</Value>
          <Arrow>
            <Icon
              name={'chevron-down'}
              size={16}
              color={'#fff'}
            />
          </Arrow>
        </Selector>
        {show && (
          <Dropdown>
            <DropdownItem onClick={openModalEdit}>Edit Cron</DropdownItem>
            <DropdownItem onClick={openModalPause}>{getPausedTitle()} Cron</DropdownItem>
            <DropdownItem onClick={openModalDelete}>Delete Cron</DropdownItem>
          </Dropdown>
        )}
      </Container>

      {showDeleteConfirm && (
        <ConfirmModal
          title="Delete Cron"
          show={showDeleteConfirm}
          text={
            <div>
              <div style={{ textAlign: 'center' }}>
                Are you sure you want to delete: {monitor?.friendly_name}?
              </div>
              <br />
              <div style={{ textAlign: 'center' }}>Note: This action cannot be undone</div>
            </div>
          }
          onOk={() => {
            deleteCronMonitor(monitor.id, () => {
              setShowDeleteConfirm(false);
              navigate(`/dashboard`);
            });
          }}
          okText="Yes"
          onCancel={() => {
            setShowDeleteConfirm(false);
          }}
          cancelText="No"
        />
      )}

      {showPauseConfirm && (
        <ConfirmModal
          title={`${getPausedTitle()} Cron`}
          show={showPauseConfirm}
          text={
            <div>
              <div style={{ textAlign: 'center' }}>
                Are you sure you want to {getPausedTitle()}: {monitor?.friendly_name}?
              </div>
            </div>
          }
          onOk={() => {
            putCronMonitor(monitor.id, {
              state: isPaused ? 'active' : 'paused',
              group_notification_ids: monitor.group_notification_ids,
            })
              .then(() => {
                setShowPauseConfirm(false);
              })
              .finally(() => {
                setShowPauseConfirm(false);
              });
          }}
          okText="Yes"
          onCancel={() => {
            setShowPauseConfirm(false);
          }}
          cancelText="No"
        />
      )}
    </>
  );
}
