function secondsToTime({ seconds, withoutSeconds, short }) {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  const dDisplay = formatDays(d, short);
  const hDisplay = formatHours(h, short);
  const mDisplay = formatMinutes(m, short);
  const sDisplay = formatSeconds(s, short)
  return `${dDisplay}${dhDelimiter(d, h)} ${hDisplay}${hmDelimiter(h, m)} ${mDisplay}${msDelimiter(m, s, withoutSeconds)} ${!withoutSeconds ? sDisplay : ''}`;
}

const dhDelimiter = (d, h) => d > 0 && h > 0 ? ', ' : '';
const hmDelimiter = (h, m) => h > 0 && m > 0 ? ', ' : '';
const msDelimiter = (m, s, withoutSeconds) => !withoutSeconds ? m > 0 && s > 0 ? ', ' : '' : '';



const formatDays = (d, short) => {
  switch (d) {
    case 0:
      return '';
    case 1:
      return `${d}${short ? 'd' : ' day'}`;
    default:
      return `${d}${short ? 'd' : ' days'}`;
  }
};

const formatHours = (h, short) => {
  switch (h) {
    case 0:
      return '';
    case 1:
      return `${h}${short ? 'hr' : ' hour'}`;
    default:
      return `${h}${short ? 'hrs' : ' hours'}`;
  }
};

const formatMinutes = (m, short) => {
  switch (m) {
    case 0:
      return '';
    case 1:
      return `${m}${short ? 'min' : ' minute'}`;
    default:
      return `${m}${short ? 'min' : ' minutes'}`;
  }
};

const formatSeconds = (s, short) => {
  switch (s) {
    case 0:
      return '';
    case 1:
      return `${s}${short ? 'sec' : ' second'}`;
    default:
      return `${s}${short ? 'sec' : ' seconds'}`;
  }
};

export default secondsToTime;
