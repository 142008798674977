import { formatDistance } from 'date-fns';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getRecentMonitorChecksByJobId } from 'redux/actions/jobsActions';
import selectors from 'redux/selectors/selectors';
import { Tooltip } from 'antd';

export const CheckBarGraphFeature = ({ jobId, length }) => {
  useEffect(() => {
    getRecentMonitorChecksByJobId({ jobId });
  }, [jobId]);

  const checks = useSelector(selectors.monitorChecksByJobIdDesc(jobId)) || [];
  const allChecks = checks.slice(0, length)?.reverse();
  const nonBlanks = allChecks.filter((n) => !n?.blank);

  const min = Math.min(...nonBlanks.map((n) => n.response_time_ms));
  const max = Math.max(...nonBlanks.map((n) => n.response_time_ms));
  const minTime = nonBlanks[0]?.created_at ? formatDistance(new Date(nonBlanks[0]?.created_at), new Date(), { addSuffix: true }) : '';
  const maxTime = nonBlanks[nonBlanks.length - 1]?.created_at ? formatDistance(new Date(nonBlanks[nonBlanks.length - 1]?.created_at), new Date(), { addSuffix: true }) : '';
  const diff = max - min;

  const bars = allChecks.map((n) => {
    const ms = n?.response_time_ms || 0;
    const relative = ms - min;
    const percent = Math.round(Math.min(90, Math.max(10, (relative / diff) * 100)));
    const timeAgo = n?.created_at ? formatDistance(new Date(n?.created_at), new Date(), { addSuffix: true }) : '';
    return {
      ...n,
      ms,
      timeAgo,
      percent
    };
  });

  return (
    <div className={'check-bar-graph'}>
      <div className={'x-axis'}>
        <div className={'max'}>{minTime}</div>
        <div className={'min'}>{maxTime}</div>
      </div>
      {bars.map((check, idx) => (
        <Tooltip
          key={`${check?.id}-${idx}`}
          title={`${check?.ms}ms, ${check?.status === 'failed' ? 'failed ' : ''}${check?.timeAgo}`}
          mouseEnterDelay={0.1}
          placement={'top'}
        >
          <div key={idx} className={`bar status-${check?.status || ''}`}>
            <div className={'backing'}></div>
            {!check?.blank && (
              <div
                className={'front'}
                style={{ height: `${check?.percent}%` }}></div>
            )}
          </div>
        </Tooltip>
      ))}
    </div>
  );
};
