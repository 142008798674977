import React from 'react';
import { Route } from 'react-router';
import AuthenticatedLayout from './authenticated-layout';
import { ReduxRouteWrapper } from './redux-route';
import { Logo } from 'shared/components/logo/logo';
import { Header } from 'features/header/header';
import { DashboardPage } from 'pages/dashboard/dashboard';
import { SiteDetailPage } from 'pages/site/site-detail';
import { NotificationsPage } from 'pages/notifications/notifications';
import { UsersPage } from 'pages/users/users';
import { AccountPage } from 'pages/account/account';
import { MainMenu } from 'features/main-menu/main-menu';
import { trialAllowedRoutes } from './trial-filter';
import { IntegrationsPage } from '../../pages/integrations/integrations';
import { CronDetailPage } from '../../pages/cron/cron-detail';

export const routes = [
  {
    id: 'dashboard',
    routeId: 'dashboard',
    path: '/dashboard',
    element: DashboardPage,
  },
  {
    id: 'dashboard-action',
    routeId: 'dashboard-action',
    path: '/dashboard/:actionId',
    element: DashboardPage,
  },
  {
    id: 'dashboard-action',
    routeId: 'dashboard-action',
    path: '/dashboard/:actionId/:arg1',
    element: DashboardPage,
  },
  {
    id: 'site-view',
    routeId: 'site-view',
    path: '/site/:actionId/',
    element: SiteDetailPage,
  },
  {
    id: 'dashboard-arg',
    routeId: 'site-view-action',
    path: '/site/:actionId/:arg1',
    element: SiteDetailPage,
  },
  {
    id: 'dashboard-arg-2',
    routeId: 'site-view-args',
    path: '/site/:actionId/:arg1/:arg2',
    element: SiteDetailPage,
  },
  {
    id: 'cron-job-view',
    routeId: 'cron-job-view',
    path: '/cron/:actionId/',
    element: CronDetailPage,
  },
  {
    id: 'cron-job-view-action',
    routeId: 'cron-job-view-action',
    path: '/cron/:actionId/:arg1',
    element: CronDetailPage,
  },

  {
    id: 'notifications',
    routeId: 'notifications',
    path: '/notifications',
    element: NotificationsPage,
  },
  {
    id: 'notifications-modal',
    routeId: 'notifications-modal',
    path: '/notifications/:actionId',
    element: NotificationsPage,
  },
  {
    id: 'notifications-group-modal-edit',
    routeId: 'notifications-group-modal-edit',
    path: '/notifications/:actionId/:arg1',
    element: NotificationsPage,
  },
  {
    id: 'notifications-modal-edit',
    routeId: 'notifications-modal-edit',
    path: '/notifications/:actionId/:arg1/:arg2',
    element: NotificationsPage,
  },
  {
    id: 'users',
    routeId: 'users',
    path: '/users/',
    element: UsersPage,
  },
  {
    id: 'users-modal',
    routeId: 'users-modal',
    path: '/users/:actionId',
    element: UsersPage,
  },
  {
    id: 'users-modal-edit',
    routeId: 'users-modal-edit',
    path: '/users/:actionId/:arg1',
    element: UsersPage,
  },
  {
    id: 'account',
    routeId: 'account',
    path: '/account',
    element: AccountPage,
  },
  {
    id: 'integrations',
    routeId: 'integrations',
    path: '/integrations',
    element: IntegrationsPage,
  },
  {
    id: 'integrations-modal',
    routeId: 'integrations-modal',
    path: '/integrations/:actionId',
    element: IntegrationsPage,
  },
  {
    id: 'integrations-callback',
    routeId: 'integrations-callback',
    path: '/integrations/:actionId/:callback',
    element: IntegrationsPage,
  },
];

export const memberRoutes = (isExpiredTrial) => {
  const trialFilter = trialAllowedRoutes(isExpiredTrial);
  return routes.filter(trialFilter).map((route, idx) => (
    <Route
      key={`route-${idx}`}
      path={route.path}
      element={
        <AuthenticatedLayout
          header={<Header />}
          menu={
            <>
              <Logo
                width={220}
                onClick={() => {}}
              />

              <MainMenu isExpiredTrial={isExpiredTrial} />
            </>
          }>
          <ReduxRouteWrapper
            routeId={route.id}
            element={route.element}
            fixedWidth={route.fixedWidth}
          />
        </AuthenticatedLayout>
      }
    />
  ));
};
