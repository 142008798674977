import { Button } from 'antd';
import { useState } from 'react';
import { setNewPassword } from 'redux/actions/userActions';

export function ResetPasswordButtonFeature({ form, code, email, onReset, onError, onSuccess }) {
  const [submitting, setSubmitting] = useState(undefined);

  const validatePasswordsIdentical = () => {
    return form.state.password === form.state.passwordConfirm;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!validatePasswordsIdentical()) {
      form.invalidate('passwordConfirm', {
        message: 'Passwords do not match.',
      });
      return;
    }
    form.validate();
    if (!form.isValid) return;

    setSubmitting(true);
    setNewPassword({ password: form.state.password, code: code, email: email }, (data, err) => {
      setSubmitting(false);
      if (err) {
        onError(err.message);
        return;
      }

      onReset();
      onSuccess();
    });
  };

  return (
    <Button
    block
      loading={submitting}
      className={'primary'}
      onClick={handleFormSubmit}
      disabled={submitting}>
      Reset
    </Button>
  );
}
