import styled from 'styled-components';
import { Divider } from 'antd';
import { BlockIncidentsFeature } from 'features/block-incidents/block-incidents';
import { BlockSSLCheckFeature } from 'features/block-ssl-check/block-ssl-check';
import { BlockUptimeFeature } from 'features/block-uptime/block-uptime';
import { SiteActionButtonFeature } from 'features/site-view/site-action-button';
import { SiteSidebarFeature } from 'features/site-view/site-detail-sidebar';
import { SiteOverallPerformanceFeature } from 'features/site-view/site-overall-performance';
import { SiteIncidentsTableFeature } from 'features/site-view/site-incidents-table';
import MonitorEventDetailsModal from 'features/site-view/MonitorEventDetailsModal';
import { useSelector } from 'react-redux';
import selectors from 'redux/selectors/selectors';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export function SiteBodyWidget({ jobId, eventId }) {
  const monitor = useSelector(selectors.httpMonitorDetails);
  return (
    <>
      <div className={'sites-container-upper'}>
        <Content>
          <div className={'site-blocks'}>
            <BlockUptimeFeature jobId={jobId} />
            {monitor.tls_job_id && (
              <>
                <Divider type="vertical" />
                <BlockSSLCheckFeature tlsJobId={monitor.tls_job_id} />
              </>
            )}
            <Divider type="vertical" />
            <BlockIncidentsFeature jobId={jobId} />
          </div>
          <Divider />
          <SiteOverallPerformanceFeature jobId={jobId} />
        </Content>

        <div className={'side-bar'}>
          <SiteActionButtonFeature jobId={jobId} />
          <Divider />
          <SiteSidebarFeature jobId={jobId} />
        </div>
      </div>
      <Divider />
      <div style={{ display: 'flex' }}>
        <SiteIncidentsTableFeature jobId={jobId} />
        <MonitorEventDetailsModal
          jobId={jobId}
          show={eventId}
          eventId={eventId}
        />
      </div>
    </>
  );
}
