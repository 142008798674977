import { action } from 'redux/lib/api';
import { getAccountInfo } from './eCommerceActions';


export const setAppInitializing = (initializing) => {
  action('SET_APP_INITIALIZING', initializing);
};


export const initApp = () => {
  setAppInitializing(true);
  return getAccountInfo().then(() => {
    setAppInitializing(false);
  });
}