import Modal from '../../shared/components/Modal';
import ModalBody from '../../shared/components/ModalBody';
import ModalFooter from '../../shared/components/ModalFooter';
import { Button, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useState } from 'react';
import { getIntegrations } from '../../redux/actions/integrationsActions';
import { post } from '../../redux/lib/api';

const slackAuthUrl = 'https://slack.com/oauth/v2/authorize';
const clientId = '4737375846128.6945747372512';

export function ModalIntegrationSlack({ show }) {
  const navigate = useNavigate();
  const { actionId, callback } = useParams();
  const [addingIntegration, setAddingIntegration] = useState(false);
  const [friendlyName, setFriendlyName] = useState('');

  const redirectUrl = `${window.location.origin}/integrations/slack/callback`;

  const cancelButton = () => {
    navigate('/integrations');
  };

  const startIntegration = () => {
    window
      .open(
        `${slackAuthUrl}?client_id=${clientId}&scope=app_mentions:read,chat:write,chat:write.public&redirect_uri=${redirectUrl}`,
        '_self'
      );
  };

  const finishIntegration = () => {
    setAddingIntegration(true);

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (!code) {
      notification.error({
        message: 'Error',
        description: 'Slack integration failed'
      });
      setAddingIntegration(false);
      navigate('/integrations');
      return;
    }
    post('integration/slack', {
      data: {
        code,
        friendly_name: friendlyName,
        redirect_uri: redirectUrl
      }
    }).then((response) => {
        if (response?.status === 201) {
          notification.success({
            message: 'Success',
            description: 'Slack integration added'
          });
        }
        getIntegrations().then(() => {
          setAddingIntegration(false);
          navigate('/integrations');
        });
      }
    );
  };

  const setupSlackModalContent = () => {
    return (
      <>
        <ModalBody>
          <p>You are about to be redirected to Slack to authorize the integration.</p>
          <p>By adding this Slack integration, you can get instant alerts delivered straight to your Slack channels.</p>
        </ModalBody>
        <ModalFooter>
          <Button
            className="primary"
            onClick={startIntegration}>
            Add
          </Button>
          <Button
            className="cancel"
            onClick={cancelButton}>
            Cancel
          </Button>
        </ModalFooter>
      </>
    );
  };

  const addSlackModalContent = () => {
    return (
      <>
        <ModalBody>
          <label
            style={{
              display: 'block',
              fontWeight: 'bold',
              marginBottom: '12px'
            }}
          >Integration Name</label>
          <input
            type="text"
            placeholder="Integration Name"
            style={{
              width: '100%',
              padding: '12px',
              margin: '12px 0',
              border: '1px solid #e8e8e8'
            }}
            autoFocus={true}
            value={friendlyName}
            onChange={(e) => setFriendlyName(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            key={addingIntegration ? 'loading' : 'add'}
            disabled={!friendlyName}
            loading={addingIntegration}
            className="primary"
            onClick={finishIntegration}>
            Add
          </Button>
          <Button
            className="cancel"
            onClick={cancelButton}>
            Cancel
          </Button>
        </ModalFooter>
      </>
    );
  };

  return (
    <Modal
      show={show}
      onHide={cancelButton}
      title="Add Slack Integration"
    >
      {actionId && !callback ? setupSlackModalContent() : addSlackModalContent()}
    </Modal>
  );
}
