import Modal from '../../shared/components/Modal';
import ModalBody from '../../shared/components/ModalBody';
import ModalFooter from '../../shared/components/ModalFooter';
import { Button, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useState } from 'react';
import { getIntegrations } from '../../redux/actions/integrationsActions';
import { post } from '../../redux/lib/api';

export function ModalIntegrationWebhook({ show }) {
  const navigate = useNavigate();
  const { actionId, _arg2 } = useParams();
  const [addingIntegration, setAddingIntegration] = useState(false);
  const [friendlyName, setFriendlyName] = useState('');
  const [webhookUrl, setWebhookUrl] = useState('');

  const cancelButton = () => {
    navigate('/integrations');
  };

  const finishIntegration = () => {
    setAddingIntegration(true);

    post('integration/webhook', {
      data: {
        friendly_name: friendlyName,
        url: webhookUrl,
      },
    }).then((response) => {
      if (response?.status === 201) {
        notification.success({
          message: 'Success',
          description: 'Webhook integration added',
        });
      }
      getIntegrations().then(() => {
        setAddingIntegration(false);
        navigate('/integrations');
      });
    });
  };

  return (
    <Modal
      show={show}
      onHide={cancelButton}
      title="Add Webhook Integration">
      {actionId && (
        <>
          <ModalBody>
            <div>
              <label
                style={{
                  display: 'block',
                  fontWeight: 'bold',
                  marginBottom: '12px',
                }}>
                Integration Name
              </label>
              <input
                type="text"
                placeholder="Integration Name"
                style={{
                  width: '100%',
                  padding: '12px',
                  margin: '12px 0',
                  border: '1px solid #e8e8e8',
                }}
                autoFocus={true}
                value={friendlyName}
                onChange={(e) => setFriendlyName(e.target.value)}
              />
              <label
                style={{
                  display: 'block',
                  fontWeight: 'bold',
                  marginBottom: '12px',
                }}>
                URL
              </label>
              <input
                type="text"
                placeholder="https://your-webhook-url.com"
                style={{
                  width: '100%',
                  padding: '12px',
                  margin: '12px 0',
                  border: '1px solid #e8e8e8',
                }}
                autoFocus={true}
                value={webhookUrl}
                onChange={(e) => setWebhookUrl(e.target.value)}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              key={addingIntegration ? 'loading' : 'add'}
              disabled={!friendlyName || !webhookUrl}
              loading={addingIntegration}
              className="primary"
              onClick={finishIntegration}>
              Add
            </Button>
            <Button
              className="cancel"
              onClick={cancelButton}>
              Cancel
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
}
