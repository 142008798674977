import moment from 'moment';
import { getLast24Hours, getLast30Days, getLast7Days, getLast90Days } from 'shared/lib/dates';

export const is7DaysRange = (first) => isRanegInRange(first, getLast7Days());
export const is30DaysRange = (first) => isRanegInRange(first, getLast30Days());
export const is90DaysRange = (first) => isRanegInRange(first, getLast90Days());

export const isRanegInRange = (first, second) => {
  if (!first || !second) return false;
  const isStartEquals = moment(first.start)
    .startOf('day')
    .isSame(moment(second.start).startOf('day'), 'day');
  const isEndEquals = moment(first.end).isSame(moment(second.end), 'day');
  return isStartEquals && isEndEquals;
};

export const dateRangePickerOptionsDefault = [
  {
    label: 'Last 7 days',
    value: getLast7Days(),
  },
  {
    label: 'Last 30 days',
    value: getLast30Days(),
  },
  {
    label: 'Last 90 days',
    value: getLast90Days(),
  },
  {
    label: 'Custom Period',
    value: null,
  },
];

export const dateRangePickerOptionsPerformance = [
  {
    label: '24 hours',
    value: getLast24Hours(),
  },
  {
    label: 'Last 7 days',
    value: getLast7Days(),
  },
  {
    label: 'Last 30 days',
    value: getLast30Days(),
  },
];

export const getISOStringRange = (range) => {
  const result = {
    start: moment.utc(range.start).startOf('day').toISOString(),
    end: moment.utc(range.end).startOf('day').toISOString(),
  };
  return result;
};
