import React from 'react';
import BoundFormGroup from 'shared/components/boundForm/BoundFormGroup';

export function SignUpFormFeature({ form, onKeyPress }) {
  const fields = [
    [{ id: 'name', placeholder: 'Full Name', format: 'name', autoFocus: true, required: true, onKeyPress: onKeyPress }],
    [{ id: 'email', placeholder: 'Email', format: 'email', required: true, onKeyPress: onKeyPress }],
    [{
      id: 'password',
      placeholder: 'Password',
      format: 'password',
      type: 'password',
      required: true,
      onKeyPress: onKeyPress
    }]
  ];
  return (
    <BoundFormGroup
      formId={form.id}
      fields={fields}
    />
  );
}
