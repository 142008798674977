import { action, get } from '../lib/api';

export const getMonitorTotals = () => {
  return get('monitors/totals', {
    action: 'GET_MONITOR_TOTALS'
  });
};

export const listMonitors = ({
                               limit = 10,
                               offset = 0,
                               searchText = '',
                               statusFilter = '',
                               stateFilter = ''
                             }) => {
  return get('monitors/list', {
    action: 'LIST_MONITORS',
    query: {
      limit,
      offset,
      text: searchText,
      status: statusFilter,
      state: stateFilter
    }
  }).then(() => {
    action('SET_MONITOR_FILTERS', { searchText, statusFilter, stateFilter });
  });
};

export const listMoreMonitors = ({
                                   limit = 10,
                                   offset = 0,
                                   searchText = '',
                                   statusFilter = '',
                                   stateFilter = ''
                                 }) => {
  return get('monitors/list', {
    action: 'LIST_MORE_MONITORS',
    query: {
      limit,
      offset,
      text: searchText,
      status: statusFilter,
      state: stateFilter
    }
  });
};
