import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import selectors from '../../redux/selectors/selectors';
import { getCronJobRecents } from '../../redux/actions/cronJobsActions';
import { Table, Tooltip } from 'antd';
import { Body, Container, Head, Title } from '../../shared/styled/styled';
import styled from 'styled-components';
import { getUiRelativeTime } from '../../shared/lib/dates';
import { Icon } from '../../shared/components';
import { CronRecentHeadersFeature } from './cron-recent-headers';

const RecentTable = styled.div`
  width: 100%;
  padding: 36px;
`;

const HeadersContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 500px;
  align-items: center; /* Ensure items are centered vertically */
  gap: 8px; /* Add some space between the preview and the icon */
`;

const HeadersPreview = styled.div`
  flex-shrink: 1; /* Allow the preview to shrink if necessary */
  background-color: #011741;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 8px;
  font-family: monospace;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  font-size: 12px;
  max-width: 400px; /* Ensure the preview does not exceed the container's max width */

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export function CronRecentsTableFeature({ jobId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const [showHeaderModal, setShowHeaderModal] = useState(false);
  const [currentHeaders, setCurrentHeaders] = useState(null);
  const recents = useSelector(selectors.cronMonitorRecents);

  useEffect(() => {
    setIsLoading(true);
    getCronJobRecents(jobId).then(() => {
      setIsLoading(false);
    });
  }, []);

  const handleCopy = (headers) => {
    navigator.clipboard.writeText(JSON.stringify(headers)).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const columns = [
    {
      title: 'Received At',
      render: (item) => {
        return <div>{getUiRelativeTime(item.created_at)}</div>;
      },
      width: 250
    },
    {
      title: 'Received By',
      dataIndex: 'receiving_address',
      width: 250
    },
    {
      title: 'Received Headers',
      render: (item) => {
        const headers = item.receiving_headers;
        const headersPreview = JSON.stringify(headers);
        return (
          <HeadersContainer>
            <HeadersPreview>{headersPreview}</HeadersPreview>
            <Tooltip title={copied ? 'Copied!' : 'Copy'}>
              <Icon
                style={{ cursor: 'pointer' }}
                size={25}
                name={'copy'}
                onClick={() => handleCopy(headers)}
              />
            </Tooltip>
            <Tooltip title="View">
              <Icon
                style={{ cursor: 'pointer' }}
                size={25}
                name={'eye'}
                onClick={() => {
                  setCurrentHeaders(headers);
                  setShowHeaderModal(true);
                }}
              />
            </Tooltip>
            <CronRecentHeadersFeature
              headers={currentHeaders}
              show={showHeaderModal}
              setShow={setShowHeaderModal}
            />
          </HeadersContainer>
        );
      }
    }
  ];

  return (
    <>
      <Container>
        <Head>
          <Title>Recent Received Pings</Title>
        </Head>
        <Body>
          <RecentTable>
            <Table
              loading={isLoading}
              rowKey={(record) => record.id}
              dataSource={recents}
              columns={columns}
              pagination={{
                hideOnSinglePage: true,
                defaultPageSize: 5,
                showSizeChanger: false,
                position: ['bottomRight']
              }}
              scroll={{ x: '100%' }}
            />
          </RecentTable>
        </Body>
      </Container>
    </>
  );
}
