import React from 'react';
import styled, { keyframes } from 'styled-components';

const pulsing = keyframes`
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5, 1.5);
    opacity: 0.5;
  }
  70% {
    transform: scale(1.7, 1.7);
    opacity: 0.25;
  }
  100% {
    opacity: 0;
  }
`;

const SpinnerIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: #06ABC1;
  border-radius: 50%;

  &:after {
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    transform: scale(1, 1);
    border-radius: 50%;
    background-color: #06ABC1;
    opacity: 1;
    animation: ${pulsing} 1500ms infinite;
  }
`;

export function Spinner() {
  return <SpinnerIcon />;
}
